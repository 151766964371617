.mainHeading {
  color: #0c2659;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.04;
  text-transform: capitalize;
}
.iconsDiv {
  display: flex;
  justify-content: right;
  padding-right: 12px !important;
}
.companyHeader {
  margin-top: 13px !important;
}
.companyNameHeading {
  font-size: 18px;
  font-weight: 500;
  color: #2f2f2f;
}
.stateHeading {
  font-size: 12px;
  font-weight: 500;
  color: #2f2f2f;
  margin: 7.9px 6.4px 15px 0.3px;
}
.CSCHeading {
  font-size: 12px;
  font-weight: 400;
  color: #2f2f2f;
  margin: 7.9px 6.4px 15px -3.7px;
}
