.middleContainer {
  width: 50% !important;
  // display: flex;
  .headingText {
    width: 100%;
    color: #2f2f2f;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    margin-top: 7px;
  }
  .buttonContainer {
    margin-top: 30px;
  }
}
.noassignment {
  // text-align: center;
  font-size: 12px;
  font-weight: 500 !important;
  /* padding: 20px; */
  color: #a3a3a3 !important;
}
