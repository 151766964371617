.iconContainer {
  background-color: #f4f4f4;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    background-color: #dddddd !important;
  }
}

.editIcon {
  cursor: pointer;
  width: 12.5px;
  height: 12.5px;
}
.copyIcon {
  cursor: pointer;
  width: 13.5px;
  height: 13.5px;
}
.mailIcon {
  cursor: pointer;
  width: 12.5px;
  height: 12.5px;
}
.openMailLink {
  height: 27px;
}
