.avtarContainer {
  background-color: #0c2659;
  color: #fff;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  margin: 0 12px 0 0;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 18px;
  padding-top: 1px;
  height: 42px;
  min-width: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
}
