.summaryHead {
  color: #0c2659;
  font-size: 14px;
  font-weight: bold;
}
.summaryDetail {
  color: #2f2f2f;
  font-size: 12px;
  margin: 0;
  line-height: 1.625;
  font-weight: 400;
  margin-bottom: -6px;
}
.assignmentDivider {
  margin: 30px 0px 30px 0px;
  background-color: #707070;
  opacity: 0.1;
}
