.overview {
  width: calc(100% - 120px);
  padding: 20px 120px;
}
.centerOverview {
  width: 100%;
  padding: 20px 120px;
}

.progressRow {
  margin-top: 11px !important;
  padding-left: 8px !important;
  padding-right: 3px !important;
}
.BottomRow {
  margin-top: 11px !important;
  display: flex;
}

.leftBox {
  margin-right: 4px;
}
.rightBox {
  margin-left: 9px;
}

.tablehead {
  width: 100%;
}
.tableBox {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding-bottom: 10px;
}
.tableButtonCard {
  padding: 26px 26px 14px 26px;
  color: #000000;
  font-size: 12px;
  font-weight: bold;
}

.assignmentCount {
  color: #011cf5 !important;
}
.commentBox {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 26px;
}

.chatHeading {
  padding: 0px 0px 22px 0px;

  color: #000000;
  font-size: 12px;
  font-weight: bold;
}

.hasStyles {
  .searchfilter {
    form {
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 5px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      background-color: #fff;
      input {
        width: 90%;
        border: none;
        height: 34px;
        outline: none;
        appearance: none;
        color: #2f2f2f;
        font-size: 14px;
        font-weight: 500;
        border-right: 1px solid #dedede;

        ::placeholder {
          color: #a3a3a3;
        }

        :focus-visible {
          outline: none;
          appearance: none;
        }
      }

      svg {
        color: #a3a3a3;
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }

    .filters {
      p {
        color: #011cf5;
        font-size: 14px;
        font-weight: 500;
        margin: 0 22px 0 12px;
        text-transform: capitalize;
      }

      svg {
        color: #011cf5 !important;
        width: 21px;
        height: 21px;
        margin-right: 0;
      }

      .searchbtn {
        background: transparent linear-gradient(90deg, #011cf5 0%, #0c2659 100%)
          0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000040;
        border-radius: 5px;
        border: none;
        font-size: 14px;
        color: #fff;
        padding: 5px 25px;
        font-weight: 300;
      }
    }
  }

  .bgimg {
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
    background-color: #fff;
    .box {
      padding: 10px;
      border-radius: 5px;
      background-color: #f4f4f4;
      p {
        color: #000000;
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 10px;
        line-height: 16px;
      }
      h2 {
        color: #2f2f2f;
        font-weight: 600;
        font-size: 32px;
      }
    }
    .converted {
      // background-color: #E6E8D5ß;
      h2 {
        color: #011cf5;
      }
      p {
        color: #011cf5;
      }
    }
  }
  .box {
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 26px;
  }
}
