@import "styles/variables.scss";

.totalavg {
  background-color: #e9eff6;
  padding: 10px;
}

.totaltable th p {
  font-size: 7px !important;
  color: $secondaryColor !important;
  margin: 0;
}
.compentancyRow {
  height: 80px;
  vertical-align: middle;
}
.errorText {
  color: #bf1736;
  font-size: 8px;
  font-weight: 500;
  white-space: nowrap;
}
.dropDownSelect {
  font-family: $fontFamily !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  border-radius: 5px;
  margin-left: -6px !important;

  &:hover {
    background-color: #e7e9fe !important;
  }
}
.inputError {
  border: 1px solid red;
  border-radius: 3px;
}
// .editScore {
//   color: $primaryColor;
//   font-size: 12px;
//   font-weight: 700;
//   padding: 10px 10px 10px 10px;
//   display: block;
//   border: 2px solid transparent;
// }

.editScore {
  // border: 2px solid white;
  border: 1px solid #959393;
  width: 80px !important;
  color: $primaryColor !important;
  font-size: 12px !important;
  padding: 4px !important;
  display: block;
  white-space: initial;
}
.totaltable td.score {
  color: $primaryColor;
  font-size: 12px;
  font-weight: 700;
}

td.redcolor {
  color: #bf1736;
  font-size: 10px;
  font-weight: 500;
  cursor: pointer;
}
.redText {
  margin-top: 10px !important;
}
.redTexthidden {
  visibility: hidden;
  margin-top: 10px !important;
}

.totaltable td h2 {
  color: $primaryColor;
  font-size: 12px;
  font-weight: 500;
  padding: 4px;
}

.tablehead {
  th {
    letter-spacing: 0px;
    color: $secondaryColor;
    font-weight: 500;
    font-size: 12px;
  }

  td {
    border: none;
    color: $secondaryColor;
    font-weight: 400;
    font-size: 12px;

    img {
      width: 100%;
      max-width: 22px;
      text-align: left;
    }
  }

  thead {
    background: #f4f4f4 0% 0% no-repeat padding-box;
  }

  tbody {
    border: none !important;
  }

  tr {
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
  }
}

.totaltable th {
  color: $secondaryColor;
  font-size: 10px;
  font-weight: 500;
}

.overview .totaltable td p {
  font-size: 10px;
  color: $secondaryColor;
  font-weight: 500;
  margin: 0;
}
.totaltable thead {
  background-color: #f4f4f4;
}
.totaltable tbody {
  border: none !important;
}
.totaltable td {
  border-bottom: 1px solid #dedede;
}

.paraStyles {
  border: 1px solid #959393;
  // margin-top: 16px !important;
  width: 211px !important;
  color: #2e2e2e !important;
  font-size: 12px !important;
  padding: 4px !important;
}

.paraWidth {
  border: 2px solid white;
  width: 212px !important;
}
.tableHeading {
  display: flex;
  width: 100%;
  background-color: lightgrey !important;
  padding: 11px 10px 11px 10px;
  border-bottom: 1px solid #dedede;
  justify-content: space-between;
}
.tableData {
  display: flex;
  width: 100%;
  // padding: 12px 10px 12px 10px;
  align-items: center;
  border-bottom: 1px solid #dedede;
}

.redcolor {
  padding-left: 5px;
  color: #bf1736;
  font-size: 10px;
  font-weight: 500;
  cursor: pointer;
}

.tableHeading1Styles {
  width: 24%;
  h4 {
    color: $secondaryColor;
    font-size: 10px;
    font-weight: 500;
    margin: 0;
  }
}
.tableHeading2Styles {
  width: 26%;
  h4 {
    color: $secondaryColor;
    font-size: 10px;
    font-weight: 500;
    margin: 0;
  }
}
.tableHeading3Styles {
  width: 16.6%;
  h4 {
    color: $secondaryColor;
    font-size: 10px;
    font-weight: 500;
    margin: 0;
  }
}
.tableHeading4Styles {
  width: 21.6%;
  h4 {
    color: $secondaryColor;
    font-size: 10px;
    font-weight: 500;
    margin: 0;
  }
}

.tableHeading5Styles {
  width: 10.6%;
  h4 {
    color: $secondaryColor;
    font-size: 10px;
    font-weight: 500;
    margin: 0;
  }
}

.hoverEffect {
  &:hover {
    border-radius: 3px;
    border: 2px solid #011fc5;
    outline: none;
  }
  &:focus-visible {
    border-radius: 3px;
    border: 2px solid #011fc5;
    outline: none;
  }
  &:focus-within {
    border-radius: 3px;
    border: 2px solid #011fc5;
    outline: none;
  }
  &:visited {
    border-radius: 3px;
    border: 2px solid #011fc5;
    outline: none;
  }
}

.tableContainer {
  white-space: nowrap;
  overflow: scroll;
  text-overflow: ellipsis;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tableContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tableContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.tableHeader {
  color: #2f2f2f;
  font-size: 10px;
  font-weight: 500 !important;
  margin: 0;
  background-color: lightgrey !important;
}
.table th {
  font-weight: 500 !important;
}
.tableStyles {
  margin-bottom: 0px !important;
}

.textFontSize {
  padding: 10px;
  font-weight: 500 !important;
}
