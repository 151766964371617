@media only screen and (max-width: 1150px) {
  .mobileScreen {
    display: block;
    position: relative;
    z-index: 2500;
  }
  .browserscreen {
    display: none;
  }
}
@media only screen and (min-width: 1150px) {
  .mobileScreen {
    display: none;
  }
  .browserscreen {
    display: block;
  }
}
