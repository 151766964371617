.toggle {
  background-color: transparent !important;
  border: none !important;
}

.radio {
  padding: 0px 6px 0px 0px !important;
}
.select {
  padding: 3px 0px 3px 10px;

  &:hover {
    border-radius: 5px;
    background-color: #dedede;
  }
}
