.overlap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.modal {
  padding: 15px;
  bottom: 10;
  border-radius: 5px;
  display: flex;
  justify-content: left;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #ddd;
  background-color: #fff;
  .calender {
    text-align: center;
  }
  .user {
    text-align: left;
  }
  h2 {
    color: #2f2f2f;
    font-size: 10px;
    font-weight: bold;
    padding: 10px 15px 0;
    text-align: center;
    margin: 0;
  }
  p {
    text-align: initial;
    font-size: 10px;
    padding-left: 5px;
  }
}
.limit h2 {
  font-size: 12px !important;
}
.days select {
  width: 100%;
  border-radius: 6px;
  background-color: #f4f4f4;
  height: 34px;
  border: none;
  font-size: 12px;
  font-weight: 500;
}
.notes {
  p {
    text-align: left;
    color: #2f2f2f;
    font-size: 10px;
    margin-bottom: 4px;
  }
}

.btncancalyes {
  display: flex;
  justify-content: space-between;
  .no {
    border-radius: 5px;
    background-color: #f4f4f4;
    font-size: 12px;
    border: none;
    margin-top: 16px;
    width: 46%;
    height: 26px;
    font-weight: 500;
    border: 1px solid transparent;

    &:hover {
      background-color: #ffff !important;
      border: 1px solid black !important;
      color: black !important;
    }
  }

  .yes {
    border-radius: 5px;
    font-size: 12px;
    border: none;
    margin-top: 16px;
    width: 46%;
    height: 26px;
    font-weight: 500;
    border: 1px solid transparent;
    background-color: #bf1736;
    color: #fff;
    &:hover {
      background-color: #ffff !important;
      border: 1px solid #bf1736 !important;
      color: #bf1736 !important;
    }
  }

  .blue {
    background-color: #011cf5;
  }
  .disable {
    background-color: gray;
    color: darkgray;
    border-radius: 5px;
    font-size: 12px;
    border: none;
    margin-top: 16px;
    width: 46%;
    height: 26px;
    font-weight: 500;
    border: 1px solid transparent;
  }
}

.modal-backdrop {
  background-color: green;
}

.tempmodal {
  position: fixed;
  top: 85px;
  right: 52px;
  width: 22.4%;
  padding: 15px;
  bottom: 10;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #ddd;
  background-color: #fff;
  .calender {
    text-align: center;
  }
  .user {
    text-align: center;
  }
  h2 {
    color: #2f2f2f;
    font-size: 10px;
    font-weight: bold;
    padding: 10px 15px 0;
    text-align: center;
    margin: 0;
  }
}

.socialIcon {
  cursor: pointer;
}

.calenderuser {
  padding: 15px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #ddd;
  background-color: #fff;
  z-index: 1;

  .calender {
    text-align: center;
  }

  .user {
    text-align: center;
  }

  h2 {
    color: #2f2f2f;
    font-size: 10px;
    font-weight: bold;
    padding: 10px 15px 0;
    text-align: center;
    margin: 0;
  }
  .commentsearch {
    width: 100%;

    .commenttext {
      h5 {
        background-color: #0c2659;
        margin: 0;
        width: 27px;
        height: 27px;
        text-align: center;
        color: #fff;
        border-radius: 50%;
        font-size: 14px;
        font-weight: 700;
        line-height: 27px;
        margin-right: 12px;
      }
    }

    .commentinput {
      width: 100%;
      input[type="search"] {
        border-radius: 5px;
        background-color: #f4f4f4;
        border: none;
        padding: 10px;
        width: 100%;
      }
    }
  }
}

.imageDivStyles {
  position: absolute;
  top: 2px;
  left: 13px;
}
.timerImageStyle {
  width: 18px;
  height: 18px;
}
.selectStyles {
  padding-left: 37px;

  &::placeholder {
    color: #a3a3a3;
  }
}
.rightdetails {
  width: 30%;
  position: relative;
}

////////////////////////@at-root
