@import "../../../styles/variables.scss";
.hideSideBar {
  display: none !important;
}

.plusIcon {
  position: absolute;
  background: #f4f4f4 0% 0% no-repeat padding-box;
  top: 0px;
  width: 11px;
  height: 11px;
  line-height: 9px;
  border-radius: 50px;
  font-size: 12px;
  color: $primaryColor;
  font-weight: 500;
  right: 14px;
  padding-top: 2px;
  padding-left: 1px;
}

.sidebar {
  background: white;
  left: 30px;
  margin-top: 26px;
  position: sticky;
  top: 30px;
  padding: 24px 8px;
  border-radius: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  height: 100%;
  text-align: center;
  min-width: 77px;
  max-width: 77px;
}
.quickAccessBar {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    list-style-type: none;
    padding: 0 0;
    position: relative;
    a {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      font-size: 8px;
      color: black !important;
      font-size: 9px;
      font-weight: bold;
      cursor: pointer;
      svg {
        width: 23px;
        height: 24px;
      }
    }
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  > * {
    + {
      * {
        margin-top: 26px;
      }
    }
  }
  .active {
    list-style-type: none;
    padding: 0 0;
    position: relative;
    a {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      color: $primaryColor !important;
      font-size: 9px;
      font-weight: bold;
      cursor: pointer;
      svg {
        width: 23px;
        height: 24px;
      }
    }
  }
}
