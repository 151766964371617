@import "../../../../styles/variables.scss";

.fullwidth {
  width: 100%;
}

.overviewHeading {
  padding: 19px 20px 19px 20px;
  border-bottom: 1px solid rgba(222, 222, 222, 0.5215686275);
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  h2 {
    color: #1c1c1d;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }
}

.dataNotFound {
  text-align: center;
  font-size: 12px;
  font-weight: 500 !important;
  padding: 20px;
  color: #a3a3a3 !important;
}

.edithead {
  display: flex;
  align-items: center;
  h3 {
    letter-spacing: 0px;
    font-size: 14px;
    margin: 0;
    padding: 0 20px;
    margin-top: 30px;
    margin-bottom: 10px;
    color: #0c2659;
    font-size: 14px;
    font-weight: bold;
  }
}
.tableContainer {
  padding-left: 20px;
  padding-right: 20px;
}
