@import "../../../../styles/variables.scss";
.assingnments {
  width: 38%;
  border-left: 1px solid #dedede57;
  border-right: 1px solid #dedede57;
  padding-left: 40px;
  margin-right: 20px;
  padding-right: 10px;
}

.assingnmentTitle {
  color: #2f2f2f;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  line-height: 2;
}

.ongoingAssingnment {
  text-transform: capitalize;
  color: $secondaryColor;
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  line-height: 2;
  width: fit-content;
  margin: 5px 2px 0px 0px;
  padding: 0px 8.5px 0px 6.5px;
  border-radius: 10px;
  background-color: #f4f4f4;
}
