.tableStyle {
  margin-bottom: 0px !important;
}
.tableRowContaners {
  display: flex;
  margin-left: 26px;
  margin-right: 26px;
  font-size: 12px;
  font-weight: bold;
  color: #000000;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #cecece54;
}
.tableDataPosition {
  width: 26.333333%;
  word-break: break-all;
  overflow: hidden;
  text-overflow: unset;
}
.tableDataStage {
  width: 26.333333%;
  word-break: break-all;
  overflow: hidden;
  text-overflow: unset;
}
.tableDateClient {
  width: 30.333333%;
  word-break: break-all;
  overflow: hidden;
  text-overflow: unset;
}
.middleDiv {
  width: 9%;
}
.headMiddleDiv {
  width: 8%;
}

.headingPosition {
  width: 25.333333%;
  padding-left: 26px !important;
}
.headingStage {
  width: 23.333333%;
  padding-left: 6px !important;
}

.headingClient {
  width: 30.333333%;
  padding-right: 26px !important;
  padding-left: 2px !important;
}

.headingFontStyle {
  font-size: 11px !important;
  font-weight: 500 !important;
}

.noAssignmentTableRow {
  display: flex;
  margin-left: 26px;
  margin-right: 26px;
  font-size: 12px;
  font-weight: bold;
  color: #a3a3a3 !important ;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #cecece54;
  justify-content: center;
}
