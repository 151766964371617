@import "../../../styles/variables.scss";

.offlimitToggle {
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #ddd;
  background-color: #fff;
  z-index: 9999;
}

.calender {
  text-align: center;
}

.status {
  text-align: center;
  cursor: pointer;
}

.text {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 10px;
  font-weight: bold;
  color: $secondaryColor;
}

.statustext {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: bold;
  color: #0c2659;
}

.active {
  color: #bf1736;
}

.activeModal {
  z-index: 99;
}

.popoverContainer {
  padding: 20px;
}

.dateField {
  border: 1px solid #dddddd;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 74%;
  height: 34px;
  margin: 15px 0px 15px 0px;

  align-items: center;
}
.timeField {
  border: 1px solid #dddddd;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 36%;
  height: 34px;
  margin: 15px 0px 15px 0px;
  align-items: center;
}

.dateContainer {
  width: 70%;
  display: flex;
  align-items: center;
  height: 39px;
}

.date {
  padding: 10px;
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  color: #2f2f2f;
}

.timeContainer {
  display: flex;
  align-items: center;
  width: 35%;
  border-left: 1px solid #dddddd;
  height: 39px;
}

.time {
  padding: 10px;
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  color: #2f2f2f;
}

.joiningDate {
  border-radius: 5px;
  width: 100%;
  height: 39px;
  margin: 15px 0px 15px 0px;
  color: #2f2f2f;
  font-size: 12px;
  font-weight: normal;
  background-color: #f4f4f4;
  padding: 10px;
  border: none;

  &:focus {
    border: none !important;
    outline: none;
  }
}

.dateInput {
  color: blue !important;
}

.inputField {
  // border: 1px solid black;
}

.borderRemove {
  border: 0px;
}

.googleAuth {
  display: flex;
  width: 100%;
  justify-content: center;
}
.googleAuthContainer {
  .authHeading {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 10px;
    font-weight: 500;
    color: #a3a3a3 !important;
  }
}
