.iconContainer {
  background-color: #f4f4f4;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  position: absolute;
  top: 58px;
  left: 50px;
}

.editIcon {
  cursor: pointer;
  width: 15px;
  height: 15px;
}
.label {
  width: 100%;
  color: #a3a3a3;
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 4px;
  margin-top: 10px;
}

.imageUploaderContainer {
  height: 75px;
  width: 75px;
  position: relative;
  margin-top: 15px;
  margin-bottom: 25px;
  cursor: pointer;
}
.cardDivider {
  // margin-top: 11px;
  color: #dedede;
  margin-bottom: 5px;
}
.userImage {
  width: 75px;
  height: 75px;
  object-fit: contain;
  border: 3px solid #f4f4f4;
  min-height: 100%;
  font-size: 12px;
  color: #a3a3a3;
  display: inline;
}

.labelStyles {
  display: inherit;
  width: 71px;
}

.labelStyles.dragActive {
  background-color: #ffffff;
}

.dragFileElement {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
