@import "../../styles/variables.scss";

.searchfilter {
  margin: 0px auto 0px;

  form {
    border: 1px solid white;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    background-color: #fff;

    &:hover {
      border: 1px solid #011cf5;
    }
  }

  .filters {
    p {
      color: $primaryColor;
      font-size: 14px;
      font-weight: 500;
      margin: 0 22px 0 12px;
      text-transform: capitalize;
    }

    .searchbtn {
      background: transparent
        linear-gradient(90deg, $primaryColor 0%, #0c2659 100%) 0% 0% no-repeat
        padding-box;
      opacity: 30%;

      box-shadow: 0px 0px 10px #00000040;
      border-radius: 5px;
      border: none;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      padding: 5px 25px;
    }
  }
}

.multiSearch {
  // width: 100%;
  // max-width: 85%;
  margin: 0px auto 0px;

  form {
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    border-top-left-radius: 0px !important;
    // box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;

    // svg {
    //   color: #a3a3a3;
    //   width: 15px;
    //   height: 15px;
    //   margin-right: 5px;
    // }
  }

  .filters {
    p {
      color: $primaryColor;
      font-size: 14px;
      font-weight: 500;
      margin: 0 22px 0 12px;
      text-transform: capitalize;
    }

    .searchbtn {
      background: transparent
        linear-gradient(90deg, $primaryColor 0%, #0c2659 100%) 0% 0% no-repeat
        padding-box;
      opacity: 30%;
      box-shadow: 0px 0px 10px #00000040;
      border-radius: 5px;
      border: none;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      padding: 5px 25px;
    }
  }

  // border-bottom: 1px solid transparent;
  // border-left: 1px solid transparent;
  // border-right: 1px solid transparent;
  // &:hover {
  //   border-bottom: 1px solid #011cf5 !important;
  //   border-left: 1px solid #011cf5 !important;
  //   border-right: 1px solid #011cf5 !important;
  //   border-radius: 6px;
  // }
}
.searchIcon {
  height: 16px;
  width: 16px;
  // cursor: pointer;
  margin-right: 4px;
}

.filterRow {
  display: flex;
  cursor: pointer;
  opacity: 40%;
  &:hover {
    opacity: 100% !important;
  }
}

.fullWidth {
  max-width: unset;
}

.searchinput {
  width: 88%;
  border: none;
  height: 34px;
  outline: none;
  appearance: none;
  color: $secondaryColor;
  font-size: 12px;
  font-weight: 500;

  &::placeholder {
    color: #a3a3a3;
  }

  :focus-visible {
    outline: none;
    appearance: none;
  }
}

.removedivider {
  border-right: 0 !important;
}
.border {
  border: 1px solid #dedede;
  border-radius: 5px;
}
.fullLengthBar {
  max-width: 100%;
}

.focusbtn {
  background: #0c2659 !important;
  opacity: 100% !important;
}

.searchContainer {
  padding-left: 21px;
  position: relative;
  padding-right: 5px;
}

.focusSearchBar {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1) !important;
}

.searchPageBar {
  box-shadow: 0 22px 20px -10px rgba(0, 0, 0, 0.1) !important;
}
.internalSearchStyle {
  right: 112px !important;
}

.crossIcon {
  position: absolute;
  right: 234px;
  top: 6px;
  cursor: pointer;
}

.searchPageStyle {
  margin-right: 12px;
  font-size: 18px;
  margin-top: 7px;
}
.filterFocus {
  display: flex;
  cursor: pointer;
  opacity: none !important;
}

.verticleLine {
  border-left: 1px solid #dedede;
  position: absolute;
  right: 41px;
  top: 6px;
  height: 21px;
}

@media screen and (min-width: 1150px) and (max-width: 1400px) {
  .searchinput {
    width: 83% !important;
  }
}
@media screen and (min-width: 1150px) and (max-width: 1200px) {
  .searchinput {
    font-size: 9px !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .searchinput {
    font-size: 11px !important;
  }
}
