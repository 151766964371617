@import "../../styles/variables.scss";

.overview {
  width: calc(100% - 80px);
  padding: 26px 50px 30px 50px;
}
.centerOverview {
  width: 100%;
  padding: 26px 50px 30px 50px;
}

.hasStyles {
  // search css end here
  .searchfilter {
    width: 100%;
    max-width: 85%;
    margin: 0px auto 0px;

    form {
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 5px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      background-color: #fff;
      border: none;
      input {
        width: 90%;
        border: none;
        height: 34px;
        outline: none;
        appearance: none;
        color: $secondaryColor;
        font-size: 14px;
        font-weight: 500;
        border-right: 1px solid #dedede;

        &::placeholder {
          color: #a3a3a3;
        }

        :focus-visible {
          outline: none;
          appearance: none;
        }
      }

      svg {
        color: #a3a3a3;
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }

    .filters {
      p {
        color: $primaryColor;
        font-size: 14px;
        font-weight: 500;
        margin: 0 22px 0 12px;
        text-transform: capitalize;
      }

      .searchbtn {
        background: transparent
          linear-gradient(90deg, $primaryColor 0%, #0c2659 100%) 0% 0% no-repeat
          padding-box;
        box-shadow: 0px 0px 10px #00000040;
        border-radius: 5px;
        border: none;
        font-size: 14px;
        color: #fff;
        padding: 5px 25px;
      }
    }
  }

  // search css end here
  .showingresult {
    p {
      font-weight: 500;
      color: $secondaryColor;
      font-size: 12px;
      margin: 0;
      width: 120px;
    }

    .btntransh {
      display: flex;
      border: 1px solid #a3a3a3;
      border-radius: 5px;
      color: #a3a3a3;
      font-size: 10px;
      padding: 5px 15px;
      font-weight: 500;
      &:hover {
        border: 1px solid #011cf5;
        color: #011cf5 !important;
      }
    }

    .showingright img {
      margin-left: 3px;
      width: 18px;
      height: 18px;
    }
  }
  .btnactive {
    border: 1px solid $primaryColor !important;
    color: $primaryColor !important;
    display: flex;
    height: 25px;
    // padding: 3px 10px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.userdetails {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 15px;
  border: 1px solid transparent;
  position: relative;

  h2 {
    color: $primaryColor;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }

  p {
    letter-spacing: 0px;
    color: $secondaryColor;
    font-size: 12px;
    margin: 0;
  }

  h3 {
    color: $secondaryColor;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }

  button {
    background: #ebf6e9 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #2f4e29;
    font-size: 12px;
    padding: 3px 15px;
    font-weight: 500;
  }
  svg {
    width: 17px;
    height: 13px;
  }
  .username {
    .usertoolbtn {
      background-color: transparent;
      border: none;
      padding: 0;
    }
    svg {
      color: #fff;
      width: 31px;
      height: 100%;
      border-radius: 5px;
      background-color: #bebebe;
      position: absolute;
      left: 0;
      top: 0;
      padding: 5px;
    }
  }
}
.diffclass {
  .username {
    h2 {
      color: #bf1736;
    }
    svg {
      border-right: 3px solid #bf1736;
      border-radius: 5px 0px 0px 5px !important;
    }
  }
}

.userdetails:hover {
  box-shadow: 0px 0px 20px #0000001a;
  border: 1px solid $primaryColor;
}

button:focus {
  box-shadow: none !important;
}

.socialIcon {
  height: 16px;
  width: 16px;
}
// .trasnparentSocialIcon {
//   height: 9px;
//   width: 16px;
//   color: transparent;
// }

.searchIcon {
  height: 16px;
  width: 16px;
  cursor: pointer;
  margin-right: 4px;
}

.filterIcon {
  width: 21px;
  height: 21px;
}

.textCenter {
  text-align: center;
}

.searchContainer {
  overflow: scroll;
  position: absolute;
  z-index: 1;
  // height: 140px;
  position: absolute;
  z-index: 1;
  background-color: #ffff;
  margin-left: 92px;
  margin-top: 4px;
  border-radius: 5px;
  width: 324px;
  max-height: 185px;
  box-shadow: 0 10px 30px 0 rgb(0 0 0 / 16%);
  // border: solid 0.5px #dedede;
}

.commenttext {
  display: flex;

  width: 28px;
  margin-right: 9px;
  h5 {
    background-color: #0c2659;
    margin: 0;
    width: 27px;
    height: 26px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 700;
    line-height: 27px;
    // margin-right: 12px;
  }
}
.adminInitail {
  text-transform: uppercase;
}

.adminThumImage {
  object-fit: cover;
  border-radius: 50%;
  width: 27px;
  height: 27px;
}
.nameStyles {
  text-transform: capitalize;
  font-size: 12px !important;
  line-height: 14px;
}

.emailStyle {
  font-size: 10px !important;
  color: #a3a3a3 !important;
}
.selectContainer {
  height: 30vh;
  overflow: scroll;
}

.searchDivider {
  margin: 0px 0px !important;
  color: #dedede !important;
}

.searchContainer {
  overflow: scroll;
  position: absolute;
  z-index: 1;
  // height: 140px;
  position: absolute;
  z-index: 1;
  background-color: #ffff;
  margin-left: 49px;
  margin-top: 4px;
  border-radius: 5px;
  width: 324px;
  max-height: 185px;
  box-shadow: 0 10px 30px 0 rgb(0 0 0 / 16%);
  // border: solid 0.5px #dedede;
}
.searchRow {
  display: flex;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #d9dffc;
  }
}
.commenttext {
  display: flex;

  width: 28px;
  margin-right: 9px;
  h5 {
    background-color: #0c2659;
    margin: 0;
    width: 27px;
    height: 26px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 700;
    line-height: 27px;
    // margin-right: 12px;
  }
}
.adminInitail {
  text-transform: uppercase;
}

.adminThumImage {
  object-fit: cover;
  border-radius: 50%;
  width: 27px;
  height: 27px;
}
.nameStyles {
  text-transform: capitalize;
  font-size: 12px !important;
  line-height: 14px;
}
.emailStyle {
  font-size: 10px !important;
  color: #a3a3a3 !important;
}
.searchDivider {
  margin: 0px 0px !important;
  color: #dedede !important;
}
