.tableFilters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}
.filterdiv {
  width: 70%;
}
.changeStatusLabel {
  font-weight: bold;
  font-size: 10px;
  white-space: nowrap;
  margin-right: 15px;
}
.changeSelect {
  // border: 2px solid #2f2f2f;
  background-color: #f4f4f4;
  border-radius: 5px;
  color: #2f2f2f !important;
  font-size: 10px;
  height: 23px;
  width: 25%;
  font-weight: 500;
  font-family: unset !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  border: 2px solid white;

  &::before {
    border-bottom: none !important;
    padding: 0px !important;
  }

  &::after {
    border-bottom: none !important;
  }
}
.selectActive {
  border: 2px solid #2f2f2f !important;
  background-color: white !important;
}
.trasnStyles {
  padding: 5px 10px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  font-family: unset !important;
  background-color: transparent !important;
}
.selectItem {
  padding: 5px 10px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  font-family: unset !important;
}
.totalCount {
  font-size: 11px;
  font-weight: 500;
  color: #2f2f2f;
  white-space: nowrap;
  margin-left: 20px;
}
.bold {
  font-weight: bold;
  font-size: 11px;
}
.sortingContainer {
  padding: 1px 5px 1px 5px;

  &:hover {
    border-radius: 5px;
  }
}
