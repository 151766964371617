@import "../../../../../../styles/variables.scss";

.calender {
  left: 68%;
  width: 100%;
  padding: 15px 7px 15px 7px;
  border-radius: 5px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #ddd;
  background-color: #fff;
  // position: absolute;
  text-align: center;
  cursor: pointer;
  .text {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 10px;
    font-weight: bold;
    color: $secondaryColor;
  }
}

.activetext {
  color: #011cf5 !important;
}
.hoverEffect {
  color: #011cf5 !important;
}

// @media screen and (min-width: 1150px) and (max-width: 1370px) {
//   .calender {
//     left: 0 !important;
//     width: 100%;
//     padding: 15px 7px 15px 7px;
//     border-radius: 5px;
//     box-shadow: 0 0 40px 0 rgb(0 0 0 / 10%);
//     border: solid 1px #ddd;
//     background-color: #fff;
//     position: absolute;
//     text-align: center;
//     cursor: pointer;
//     top: 28% !important;
//   }
// }
