@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.talentdetails {
  @include middleContainer;
}

.ristrictContainer {
  @include ristricMiddleContainer;
  width: 57.1% !important;
}

.innerContainer {
  .detailsimg {
    h6 {
      background-color: #0c2659;
      color: #fff;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 50%;
      margin: 0 15px 0 0;
    }
  }

  .detailscontent {
    .detailsheading {
      h2 {
        color: #0c2659;
        font-size: 18px;
        font-weight: bold;
        margin: 0;
      }

      p {
        color: $secondaryColor;
        font-size: 12px;
        margin: 0;
        display: flex;

        span {
          font-weight: 600;
        }
      }
    }

    h3 {
      color: #0c2659;
      font-size: 14px;
      font-weight: bold;
      margin: 30px 0 10px;
    }

    h4 {
      color: $secondaryColor;
      font-size: 12px;
      font-weight: 700;
    }

    p {
      color: $secondaryColor;
      font-size: 12px;
      margin: 0;
    }

    .boxx {
      border-radius: 5px;
      background-color: #f4f4f4;
      padding-left: 10px;
      padding-top: 15px;
      padding-bottom: 15px;
      opacity: 1 !important;
      margin-right: 12px;
      margin-bottom: 15px;
      margin-top: 10px;
      flex: 1;

      h5 {
        color: $secondaryColor;
        font-size: 12px;
        margin: 0;
        font-weight: 500;
      }

      p {
        color: #a3a3a3;
        font-weight: 500;
      }

      h1 {
        line-height: 1;
        color: $secondaryColor;
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 0;
        padding-top: 5px;
      }
    }
  }
}
.topheading {
  h2 {
    color: #1c1c1d;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }
}
.overviewHeading {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
}
.newAddButton {
  color: $secondaryColor;
  border: 1px solid $secondaryColor;
  border-radius: 5px;
  font-size: 12px;
  background: transparent;
  font-weight: 500;
  padding: 5px 15px;
  align-items: center;
  display: flex;
  justify-content: center;

  img {
    //   width: 100%;
    max-width: 18px;
    margin-right: 5px;
  }
}
.detailscontent {
  .detailsheading {
    h2 {
      color: #0c2659;
      font-size: 18px;
      font-weight: bold;
      margin: 0;
    }

    p {
      color: $secondaryColor;
      font-size: 12px;
      margin: 0;
      display: flex;

      span {
        font-weight: 600;
      }
    }
  }

  h3 {
    color: #0c2659;
    font-size: 14px;
    font-weight: bold;
    margin: 24px 0 10px;
  }

  h4 {
    color: $secondaryColor;
    font-size: 12px;
    font-weight: 700;
  }

  p {
    color: $secondaryColor;
    font-size: 12px;
    margin: 0;
  }

  .boxx {
    border-radius: 5px;
    background-color: #f4f4f4;
    padding-left: 10px;
    padding-top: 15px;
    padding-bottom: 15px;

    margin-right: 12px;
    margin-bottom: 15px;
    margin-top: 10px;
    flex: 1;

    h5 {
      color: $secondaryColor;
      font-size: 12px;
      margin: 0;
      font-weight: 700;
    }

    p {
      color: #a3a3a3;
      font-weight: 500;
    }

    h1 {
      color: $secondaryColor;
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 0;
      padding-top: 5px;
    }
  }
}
.dashedLine {
  border-top: 1px dashed #707070;
  letter-spacing: 4px;
  background-color: white;
}

.overviewHeading {
  padding: 20px;
  border-bottom: 1px solid #dedede85;
}
.newdetailscontent {
  padding: 20px;
}
.newdetailborder {
  border-bottom: 1px dotted #dedede;
  padding-bottom: 20px;
}
.detailscontent {
  padding: 20px;
}

.editIconSpan {
  background-color: #f4f4f4;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 10px;

  img {
    width: 17px;
    height: 17px;
  }
  &:hover {
    background-color: #dddddd;
  }
}

.socialIcon {
  cursor: pointer;
}

.amountPostFix {
  font-size: 24px;
}

.dataNotFound {
  text-align: center;
  font-size: 12px;
  // text-transform: capitalize;
  font-weight: 500 !important;
  padding: 20px;
  color: #a3a3a3;
}

.boxContainer {
  padding-top: 5px;
}
@media screen and (min-width: 1150px) and (max-width: 1263px) {
  .detailscontent {
    .boxx {
      h1 {
        font-size: 20px !important;
      }
    }
  }
  .detailscontent {
    .boxx {
      h5 {
        font-size: 10px !important;
      }
    }
  }
  .amountPostFix {
    font-size: 20px !important;
  }
}
