@import "../../../../../styles/variables.scss";
.hideContainer {
  display: none;
}
.offlimitToggle {
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #ddd;
  background-color: #fff;
  z-index: 9999;
}

.calender {
  text-align: center;
  cursor: pointer;
}

.status {
  text-align: center;
  cursor: pointer;
}

.text {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 10px;
  font-weight: bold;
  color: $secondaryColor;
}

.statustext {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: bold;
  color: #0c2659;
}

.active {
  color: #bf1736;
}

.activeModal {
  z-index: 99;
}

.popoverContainer {
  // width: 349px;
  padding: 20px;
}

.popoverHead {
  font-size: 12px;
  font-weight: normal;
  color: #2f2f2f;
}

.subHEad {
  font-size: 12px;
  font-weight: 700;
  color: #2f2f2f;
}

.dateTime {
  border: 1px solid #dddddd;
  border-radius: 5px;
  width: 100%;
  height: 39px;
  margin: 15px 0px 15px 0px;

  align-items: center;
  display: flex;
}
.calenderIcon {
  width: 25px;
  padding: 0px 0px 0px 10px;
}
.dateContainer {
  width: 70%;
  display: flex;
  align-items: center;
  height: 39px;
}
.date {
  padding: 10px;
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  color: #2f2f2f;
}

.timeContainer {
  display: flex;
  align-items: center;
  width: 35%;
  border-left: 1px solid #dddddd;
  height: 39px;
}
.time {
  padding: 10px;
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  color: #2f2f2f;
}

.joiningDate {
  border-radius: 5px;
  width: 100%;
  height: 39px;
  margin: 15px 0px 15px 0px;
  color: #2f2f2f;
  font-size: 12px;
  font-weight: normal;
  background-color: #f4f4f4;
  padding: 10px;
}

.joiningDateFor {
  border-radius: 5px;
  width: 100%;
  height: 75px;
  margin: 15px 0px 15px 0px;
  color: #2f2f2f;
  font-size: 12px;
  font-weight: normal;
  background-color: #f4f4f4;
  padding: 10px;
}
.select {
  margin-left: 10px;
  font-family: unset !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  &::before {
    border-bottom: none !important;
  }
  &::after {
    border-bottom: none !important;
  }
}
.timeSelect {
  margin-top: 16px;
}
.durationSelect {
  margin-top: 12px;
}
