$primaryColor: #011cf5;
$secondaryColor: #2f2f2f;

$green: #b1ffba;

$fontFamily : 'Public Sans';

:export {
  primaryColor: $primaryColor;
  secondaryColor: $secondaryColor;
  fontFamily : $fontFamily;
}
