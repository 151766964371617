@import "styles/variables.scss";

.container {
  width: 34% !important;
}
.redAlert {
  // display: flex;
  width: 100%;
  border-radius: 5px;
  background-color: rgba(191, 23, 54, 0.0588235294);
  border-right: 1px solid white !important;
  border-top: 1px solid white !important;
  border-bottom: 1px solid white !important;
  border-left: 8px solid #bf1736 !important;
  padding: 10px 14px;
}
.alertHeading {
  color: #bf1736 !important;
  font-size: 12px;
  font-weight: bold;
}
.alertDescription {
  margin-top: 2px;
  padding-left: 22px;
  font-size: 12px;
  padding-right: 10px;
  color: #bf1736 !important;
}
.cancelButton {
  border-radius: 5px !important;
  background-color: #f4f4f4 !important;
  font-size: 12px !important;
  border: 1px solid transparent !important;
  width: 30% !important;
  padding: 8px 15px !important;
  text-transform: capitalize !important;
  font-weight: 500 !important;
  color: black !important;
  font-family: $fontFamily, sans-serif !important;

  &:hover {
    background-color: #fff !important;
    border: 1px solid black !important;
    color: black !important;
  }
}
.cloneButton {
  border-radius: 5px !important;
  border: 1px solid transparent !important;
  background-color: #bf1736 !important;
  color: #fff !important;
  width: 30% !important;
  font-size: 12px !important;
  padding: 8px 15px !important;
  text-transform: capitalize !important;
  font-family: $fontFamily, sans-serif !important;
  &:hover {
    background-color: #fff !important;
    border: 1px solid #bf1736 !important;
    color: #bf1736 !important;
  }
}
.alertHeading {
  color: #bf1736 !important;
  font-size: 12px;
  font-weight: bold;
}
