.calender {
  text-align: center;
  cursor: pointer;
}

.text {
  color: #2f2f2f;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0;
}
.activetext {
  color: #011cf5 !important;
}
.disabletext {
  color: lightgrey !important;
}

.hoverEffect {
  color: #011cf5;
}
