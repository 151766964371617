@import "../../../styles/variables.scss";

.dragattach {
  background: #0023410a 0% 0% no-repeat padding-box;
  border: 1px dashed $primaryColor;
  border-radius: 5px;
  text-align: center;
  padding: 80px 0;
  cursor: pointer;
  margin: 30px 20px 0px 20px;

  h5 {
    color: #2f2f2f;
    font-size: 12px;
    margin: 5px 0;
    font-weight: 500;

    span {
      color: $primaryColor;
    }
  }

  p {
    color: #a3a3a3;
    font-size: 10px;
    margin: 0;
  }
  span:hover {
    cursor: pointer;
  }
}

.fileName {
  font-size: 12px;
}

.active {
  border: 1px dashed #e11600;
}

.smalldragattach {
  background: #0023410a 0% 0% no-repeat padding-box;
  border: 1px dashed $primaryColor;
  border-radius: 5px;
  text-align: center;
  padding: 20px 0;
  cursor: pointer;

  h5 {
    color: #2f2f2f;
    font-size: 12px;
    margin: 5px 0;
    font-weight: 500;

    span {
      color: $primaryColor;
    }
  }

  p {
    color: #a3a3a3;
    font-size: 10px;
    margin: 0;
  }
  span:hover {
    cursor: pointer;
  }
}

.error {
  border: 1px solid #bf1736;
}

.errorText {
  text-align: right;

  color: #bf1736;
  font-size: 8px;
  font-weight: 500;
  white-space: nowrap;
}

.paraStyle {
  line-height: 10px;
}

.smallOuterCircle {
  text-align: center;
  display: inline-flex;
  background-color: #f4f4f4;
  border-radius: 9999px;
  object-fit: cover;
  width: 32px;
  height: 32px;
  border: 1px solid #dfe3e7;
  align-items: center;
  justify-content: center;
  img {
    height: 16px;
    width: 16px;
  }
}
.bigOuterCircle {
  text-align: center;
  display: inline-flex;
  background-color: #f4f4f4;
  border-radius: 50%;
  object-fit: cover;
  width: 43px;
  height: 37px;
  border: 1px solid #dfe3e7;
  align-items: center;
  justify-content: center;
}

.tableContainer {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.dropfileName {
  text-align: initial;
  padding-left: 15px;
  padding-right: 15px;
  width: 60%;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fileSize {
  font-size: 12px;
  padding-left: 15px;
  padding-right: 15px;
}
