@import "../../../../styles/mixins.scss";

.searchfilter {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;

  form {
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;

    input {
      width: 90%;
      border: none;
      height: 34px;
      outline: none;
      appearance: none;
      color: #2f2f2f;
      font-size: 14px;
      font-weight: 500;
      border-right: 1px solid #dedede;

      ::placeholder {
        color: #a3a3a3;
      }

      :focus-visible {
        outline: none;
        appearance: none;
      }
    }

    svg {
      color: #a3a3a3;
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }

  .filters {
    p {
      color: #011cf5;
      font-size: 14px;
      font-weight: 500;
      margin: 0 22px 0 12px;
      text-transform: capitalize;
    }

    svg {
      color: #011cf5 !important;
      width: 21px;
      height: 21px;
      margin-right: 0;
    }

    .searchbtn {
      background: transparent linear-gradient(90deg, #011cf5 0%, #0c2659 100%)
        0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px #00000040;
      border-radius: 5px;
      border: none;
      font-size: 14px;
      color: #fff;
      padding: 5px 25px;
      font-weight: 300;
    }
  }
}
.searchbuss {
  color: #0c2659;
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 0px;
}

// h2.searchbuss span {
//   font-weight: 400;
// }

.activelogs {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      padding-bottom: 25px;
      position: relative;
      &:after {
        position: absolute;
        left: 12px;
        bottom: -12px;
        background-color: #dedede;
        content: "";
        width: 1px;
        height: 50px;
      }
      &:last-child {
        &:after {
          width: 0;
        }
      }

      .activelogsright {
        border-radius: 5px;
        background-color: #f4f4f4;
        padding: 10px 15px;
        margin-left: 15px;
        width: 70%;
        p {
          color: #2f2f2f;
          font-size: 10px;
          margin: 0;
          span {
            font-weight: 700;
          }
          .time {
            width: 8px;
            height: 8px;
          }
        }
        h2 {
          color: #2f2f2f;
          font-size: 12px;
          font-weight: 500;
          margin: 2px 0;
          span {
            font-weight: 700;
          }
        }
      }
      .activelogsleft {
        display: flex;
        align-items: center;
        justify-content: center;
        .activelogsimg {
          // padding: 9px 8px 8px 9px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 15px;
            height: 15px;
          }
        }
        .activelogsimgcolor {
          background-color: #dedede;
        }
      }
    }
  }
}

.tabMainContent {
  @include middleContainer;
  padding: 23px 38px 20px 38px;
}

.nodataFound {
  text-align: center;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 500 !important;
  padding: 20px;
  color: #a3a3a3 !important;
}

.divider
{
  margin-top: 10px;
  color: #dedede;
  margin-bottom: 15px;
}