@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
.tabContainer {
  @include middleContainer;
}

.container {
  padding: 15px 22px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #ddd;
  background-color: #ffffff;
  margin-top: 20px;

  h2 {
    color: $secondaryColor;
    font-size: 10px;
    font-weight: bold;
    padding: 10px 15px 0;
    text-align: center;
    margin: 0;
  }
  .commentsearch {
    width: 100%;
    // justify-content: space-between;
    display: flex;

    .commenttext {
      width: 28px;
      padding-top: 6px;

      h5 {
        background-color: #0c2659;
        margin: 0;
        width: 27px;
        height: 27px;
        text-align: center;
        color: #fff;
        border-radius: 50%;
        font-size: 12px;
        font-weight: 700;
        line-height: 27px;
        // margin-right: 12px;
      }
    }

    .commentBox {
      position: relative;
      width: 100%;
    }
  }
}
.Upcontainer {
  padding: 15px 22px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #ddd;
  background-color: #ffffff;
  margin-top: 0px !important;

  h2 {
    color: $secondaryColor;
    font-size: 10px;
    font-weight: bold;
    padding: 10px 15px 0;
    text-align: center;
    margin: 0;
  }
  .commentsearch {
    width: 100%;
    justify-content: space-between;
    display: flex;

    .commenttext {
      width: 28px;
      padding-top: 6px;

      h5 {
        background-color: #0c2659;
        margin: 0;
        width: 27px;
        height: 27px;
        text-align: center;
        color: #fff;
        border-radius: 50%;
        font-size: 12px;
        font-weight: 700;
        line-height: 27px;
        // margin-right: 12px;
      }
    }

    .commentBox {
      position: relative;
      width: 100%;
    }
  }
}
.hideContainer {
  padding: 15px 22px;
  //align-items: center;
  //justify-content: center;
  border-radius: 5px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #ddd;
  background-color: #ffffff;

  overflow: hidden;
  max-height: 700px;
  overflow-y: scroll;

  h2 {
    color: $secondaryColor;
    font-size: 10px;
    font-weight: bold;
    padding: 10px 15px 0;
    text-align: center;
    margin: 0;
  }
  .ristrictSearch {
    width: 100%;
    justify-content: space-between;
    display: flex;

    .ristrictComment {
      width: 28px;
      padding-top: 6px;

      h5 {
        text-transform: uppercase;
        background-color: #0c2659;
        margin: 0;
        width: 27px;
        height: 27px;
        text-align: center;
        color: #fff;
        border-radius: 50%;
        font-size: 12px;
        font-weight: 700;
        line-height: 27px;
        // margin-right: 12px;
      }
    }

    .commentBox {
      position: relative;
      width: 100%;
    }
  }
}

.seearchrive {
  h4 {
    color: $secondaryColor;
    font-size: 12px;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
  }
}

.archiveHover {
  &:hover {
    color: #011cf5 !important;
  }
}

.divider {
  color: #707070;
  opacity: 0.1;
}
.activeDivider {
  color: #011cf5;
  opacity: 100%;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.commentImage {
  position: absolute;
  top: 12px;
  right: 10px;
  background-color: $secondaryColor;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 10px;

  img {
    width: 22px;
    height: 10px;
  }
}
.replyBox {
  border-left: 1px solid #e6e1e1;
}
.rightdetails {
  width: 30%;
  position: relative;
}

.comments {
  width: 100%;
}

.tabView {
  margin-top: 0;
  box-shadow: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border: none;
  padding: 20px 20px 20px 20px;
}

.tabHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 20px 26px 20px;

  border-bottom: 1px solid #dedede85;

  h2 {
    margin-top: 8px !important;
    color: #1c1c1d;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }
}
.commentFooter {
  text-align: center;
  font-size: 12px;
  font-weight: 500 !important;
  /* padding: 20px; */
  color: #a3a3a3 !important;
}
.adminThumImage {
  object-fit: cover;
  border-radius: 50%;
  width: 27px;
  height: 27px;
}

.adminInitail {
  text-transform: capitalize;
}

.noHistroyFooter {
  padding-top: 10px;
  text-align: center;
  font-size: 12px;
  font-weight: 500 !important;
  /* padding: 20px; */
  color: #a3a3a3 !important;
}

.userContainer {
  display: none;
}

@media screen and (min-width: 1150px) and (max-width: 1300px) {
  .hideContainer {
    .ristrictSearch {
      display: flex;
      flex-direction: column;
    }
  }
  .container {
    .commentsearch {
      display: contents !important;
    }
  }

  .userContainer {
    display: block;
    padding-left: 10px;
    align-items: center;
    font-size: 12px;
    padding-left: 10px;
    display: flex;
    font-weight: 700;
    text-transform: capitalize;
  }
  .container {
    .commentsearch {
      .commenttext {
        display: flex;
        width: 100%;
      }
    }
  }
  .hideContainer {
    .ristrictSearch {
      .ristrictComment {
        display: flex;
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}
