@import "styles/variables.scss";

.stack {
  justify-content: end;
}
.cancelButton {
  border-radius: 5px !important;
  background-color: #f4f4f4 !important;
  font-size: 12px !important;
  border: 1px solid transparent !important;
  width: 30% !important;
  padding: 8px 15px !important;
  text-transform: capitalize !important;
  font-weight: 500 !important;
  color: black !important;
  font-family: $fontFamily, sans-serif !important;

  &:hover {
    background-color: #fff !important;
    color: black !important;
    border: 1px solid black !important;
  }
}
.enableConfirmationButton {
  border: 1px solid transparent !important;
  border-radius: 5px !important;
  border: none !important;
  background-color: #bf1736 !important;
  color: #fff !important;
  width: 30% !important;
  font-size: 12px !important;
  padding: 8px 15px !important;
  text-transform: capitalize !important;
  font-family: $fontFamily, sans-serif !important;
  &:hover {
    background-color: #fff !important;
    color: #bf1736 !important;
    border: 1px solid #bf1736 !important;
  }
}

.disableConfirmatinButton {
  border-radius: 5px !important;
  border: none !important;
  background-color: gray !important;
  color: darkgrey !important;
  width: 30% !important;
  font-size: 12px !important;
  padding: 8px 15px !important;
  text-transform: capitalize !important;
  font-family: $fontFamily, sans-serif !important;
}
.transferDelete {
  width: 53% !important;
}
