@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

$color_1: #fff;
$color_2: #fff;

.navbarnav {
  margin-left: 80px;
  margin-top: 5px;

  .navBarIcon {
    height: 14px;
    width: 20px;
    margin-right: 3px;
  }

  .navlink {
    height: 31px;
    color: $color_2 !important;
    font-size: 13.3px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 3px 14px 3px 11px !important;
    border-radius: 6px;
    min-width: 85px;
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    &.active {
      background: rgba(255, 255, 255, 0.1);
    }
    svg {
      width: 18px;
      height: 18px;
      margin-right: 7px;
    }
  }

  li {
    display: flex;
    align-items: center;

    padding: 5px 5px;
  }
}

.navbarnav .active,
.navbarnav .navlink:hover {
  color: #fff !important;
}
.linkContainer {
  display: flex;
  align-items: center;
  height: 60px;
}
