@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.talentdetails {
  @include middleContainer;
  //@include ristricMiddleContainer;
}
.ristrictStyles {
  @include ristricMiddleContainer;
}
.details {
  display: flex;
  text-transform: capitalize;
}
.pageContainer {
  width: 100%;
  margin-top: 21px;
  padding-left: 57px;
  padding-right: 119px;
}
.summaryDivider {
  margin: 30px 0px 30px 0px;
  background-color: #707070;
  opacity: 0.1;
}

.createByStyles {
  font-size: 11px;
  line-height: 1rem;
  color: #a3a3a3;
  text-transform: none;
}
.containerInner {
  padding: 32px 30px 0px 28px;
}

.detailscontent {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@media screen and (min-width: 1150px) and (max-width: 1400px) {
  .contactContainer {
    width: 100%;
    flex-direction: row;
    margin-top: 21px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1401px) {
  .contactContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 21px;
    margin-bottom: 10px;
  }
}
