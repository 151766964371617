@import "styles/variables.scss";

.paginationRow {
  display: flex;
  justify-content: space-between;
}

.rowsPerPage {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.tableRowPerPage {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 8px;
}

.limitSection {
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.limitSelect {
  margin-left: 10px;
  border: none;
  background: transparent;
  font-size: 12px;
  font-weight: 500;

  &:focus-visible {
    outline: none;
  }
}

.select {
  margin-left: 10px;
  font-family: unset !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  &::before {
    border-bottom: none !important;
  }
  &::after {
    border-bottom: none !important;
  }
  &:hover {
    background-color: #dddddd !important;

    border-radius: 3px !important;
  }
}

.selectItems {
  font-family: $fontFamily !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}
