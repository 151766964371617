@import "styles/variables.scss";

.titleCss {
  // margin-top: 22px;
}
.transferSelect {
  border: none;
  border-radius: 5px;
  color: #2f2f2f;
  font-size: 10px;
  height: 23px;
  background-color: #f4f4f4;
  width: 17%;
  font-weight: 500;
  font-family: unset !important;
  font-size: 12px !important;
  font-weight: 500 !important;

  &::before {
    border-bottom: none !important;
    padding: 0px !important;
  }

  &::after {
    border-bottom: none !important;
  }
  border-radius: 5px;
  width: 100%;
  height: 46px;
  margin: 8px 0px 15px 0px;
  color: #2f2f2f;
  font-size: 12px;
  font-weight: normal;
  background-color: #f4f4f4;
  padding: 10px;
  border: none;
  font-family: $fontFamily, sans-serif !important;

  &:focus {
    border: none !important;
    outline: none;
  }
}

.searchbale {
  &:hover {
    cursor: default;
    background-color: white !important;
  }
}
.commenttext {
  width: 28px;
  margin-right: 9px;
  display: flex;
  align-items: center;
  h5 {
    background-color: #0c2659;
    margin: 0;
    width: 27px;
    height: 26px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 700;
    line-height: 27px;
    // margin-right: 12px;
  }
}
.adminThumImage {
  object-fit: cover;

  border-radius: 50%;
  width: 27px;
  height: 27px;
}
.adminInitail {
  text-transform: uppercase;
}
.disableOptions {
  color: gray !important;
}

.nameStyles {
  text-transform: capitalize;
  font-size: 12px !important;
  display: flex;
  align-items: center;
  padding-top: 4px;
}

.emailStyle {
  font-size: 10px !important;
  color: gray !important;
}
