.tabViewContainer {
  position: relative;
  display: flex;
  align-items: flex-end;
  min-width: 95%;
  // max-width: 83%;
}
.container {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  // max-width: 236px;
}
.noPanelConatainer {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 237px;
}

.ristrictConatiner {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 90%;
  max-width: 242px;
}

////////// Comment Input ////////
///
.tabMentions {
  border: none;
}

.tabMentions--multiLine {
  display: inline-block;
  width: 90%;
  border: 1px solid transparent;
  margin-left: 12px;
  &:hover {
    // border: 1px solid #011cf5;
    border-radius: 5px;
  }
}

.tabMentions--multiLine .tabMentions__control {
  // background-color: #f4f4f4;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
  // width: 541px;
}
.tabMentions--multiLine .tabMentions__highlighter {
  padding: 8px 7px;
  border: 2px inset transparent !important;
  padding-right: 22%;
}
.tabMentions--multiLine .tabMentions__input {
  padding-right: 22% !important;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  // width: 460px !important;
  border: none;
  border-radius: 5px;
  background-color: #f4f4f4 !important;

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: #a3a3a3;
    font-size: 12px;
    font-weight: normal;
  }
}

.tabMentions__suggestions__list {
  font-size: 12px;
  font-weight: 500;
  // color: #011cf5;
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
    rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
  border-radius: 5px;
}

.tabMentions__suggestions__item--focused {
  background-color: #011cf5;

  .mentions__suggestions__menu__item {
    color: #fff !important;
  }
}

.tabMentions__suggestions__menu__item {
  height: 100%;
  width: 100%;
  color: black !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.mentions {
  border: none;
}

.mentions--multiLine {
  // max-width: 100%;
  // min-width: 95%;
  width: 100%;
  display: inline-block;
  // width: 100%;
  // max-width: 241px;
  min-width: 100px;
  margin-left: 12px;
  border: 1px solid transparent;
  position: relative;
  // padding-right: 50px;
  &:hover {
    // border: 1px solid #011cf5;
    border-radius: 5px;
  }
}

.mentions--multiLine .mentions__control {
  background-color: #fff;
  font-size: 12px;
  font-weight: 500;
  // padding-right: 10px;
  background-color: #f4f4f4 !important;
  border-radius: 5px;
  &:focus-visible {
    outline: 1px solid #011cf5 !important;
  }
}
.mentions--multiLine .mentions__highlighter {
  padding: 8px 7px;
  border: 2px inset transparent !important;
  // padding-right: 71px;
  // width: 180px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.mentions--multiLine .mentions__input {
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  width: 98% !important;
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #f4f4f4 !important;
  // width: 173px !important;

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: #a3a3a3;
    font-size: 12px;
    font-weight: normal;
  }
}

.mentions__suggestions__list {
  background-color: white;
  font-size: 12px;
  font-weight: 500;
  color: #011cf5;
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
    rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
  border-radius: px;
  max-height: 300px;
  overflow-y: auto;
  border-radius: 5px !important;
}

.mentions__suggestions__item--focused {
  background-color: #e7e9fe;
  .mentions__suggestions__menu__item {
    color: black !important;
  }
}

.mentions__suggestions__menu__item {
  height: 100%;
  width: 235px;
  color: black !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  border-radius: 5px !important;
  overflow: hidden;
  white-space: pre-line !important;
}

.mentions__mention {
  background-color: #e9eff6;
  color: #011cf5;
  z-index: 1;
  position: relative;
  border-radius: 10% !important;
  padding: 0px 1px 0px 0px;
}

.sendButton {
  margin-left: 10px;
}

.sentButton {
  // position: absolute;
  left: 245px;
  background-color: #0c2659;
  border-radius: 10px;
  border: none;
  color: #f4f4f4;
  padding: 2px 8px;
}
.disableButton {
  // position: absolute;
  left: 245px;
  background-color: gray;
  border-radius: 10px;
  border: none;
  color: #f4f4f4;
  padding: 2px 8px;
  margin-left: 5px;
}

.commenttext {
  width: 28px;
  margin-right: 10px;
  h5 {
    background-color: #0c2659;
    margin: 0;
    width: 27px;
    height: 27px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 700;
    line-height: 27px;
    // margin-right: 12px;
  }
}
.adminInitail {
  text-transform: uppercase;
}

.adminThumImage {
  object-fit: cover;
  border-radius: 50%;
  width: 27px;
  height: 27px;
}
.nameStyles {
  text-transform: capitalize;
  text-overflow: ellipsis;
  width: 182px;
  // font-size: 50px;
  white-space: nowrap;
  overflow: hidden;
}

.emailStyle {
  font-size: 10px !important;
  color: #a3a3a3 !important;
}

.divider {
  margin-top: 5px !important;
  margin: 0px !important;
  color: #dedede !important;
}
.mentions__suggestions {
  left: 0;
  margin-top: 35px !important;
  border-radius: 5% !important;
  border-radius: 5px !important;
}

.tabChat {
  // position: absolute;
  // left: 192px;
  background-color: #011cf5;
  border-radius: 16px;
  border: none;
  color: #f4f4f4;
  padding: 2px 8px 2px 8px;
  bottom: 6px;
  margin-left: 5px;
  font-size: 14px;

  &:hover {
    background-color: #0c2659;
  }
}
.ristrictBtn {
  // position: absolute;
  // left: 192px;
  background-color: #011cf5;
  border-radius: 16px;
  border: none;
  color: #f4f4f4;
  padding: 2px 8px 2px 8px;
  bottom: 6px;
  margin-left: 5px;
  font-size: 14px;

  &:hover {
    background-color: #0c2659;
  }
}
.pageChat {
  background-color: #011cf5;
  border-radius: 16px;
  font-size: 14px;
  border: none;
  color: #f4f4f4;
  padding: 2px 8px 2px 8px;
  margin-left: 8px;

  &:hover {
    background-color: #0c2659;
  }
}

.disableButton {
  background-color: #dddddd !important;
}

.dotStyle {
  width: 3px;
  height: 3px;
  border-radius: 4px;
  background-color: #a3a3a3;
  margin: 6px 0px 0px 5px;
}

.yearStyle {
  margin-left: 2px;
  font-size: 10px !important;
  color: #a3a3a3 !important;
}
.iPageStyles {
  // position: absolute;
  // left: 470px;
  // right: 110px;
  cursor: pointer;
  bottom: 11px;
  height: 15px;
}

.iTabStyles {
  bottom: 12px;
  // position: absolute;
  // left: 174px;
  cursor: pointer;
  height: 15px;
}
.tipContent {
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  line-height: 1.5;
}

.textContainer {
  padding: 1px 10px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.emailContainer {
  overflow: hidden;
}
.buttonContainer {
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  bottom: 7px;
  right: 60px;
}
.tabButtonContainer {
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  bottom: 8px;
  right: 10px !important;
}
.centerButton {
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  bottom: 4px;
  right: 20px !important;
}
.ristrictBtnContainer {
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  bottom: 8px;
  right: 10px !important;
}

.sendIcon {
  display: none;
}
.resitrictIcon {
  display: none;
}
@media only screen and (min-width: 1000px) and (max-width: 1100px) {
  .iPageStyles {
    right: 90px;
  }
  .buttonContainer {
    right: 37px;
  }
  .tabButtonContainer {
    right: 10px;
  }
  .ristrictBtnContainer {
    right: 10px;
  }
  .mentions--multiLine .mentions__input {
    padding-right: 80%;
  }
  .mentions--multiLine .mentions__highlighter {
    padding-right: 80%;
  }
}
@media only screen and (min-width: 1100px) and (max-width: 1200px) {
  .iPageStyles {
    right: 95px;
  }
  .buttonContainer {
    right: 43px;
  }
  .tabMentions--multiLine .tabMentions__highlighter {
    padding-right: 26% !important;
  }
  .mentions--multiLine .mentions__input {
    padding-right: 45%;
  }
  .mentions--multiLine .mentions__highlighter {
    padding-right: 45%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .iPageStyles {
    right: 105px;
  }
  .buttonContainer {
    right: 49px;
  }
  .tabMentions--multiLine .tabMentions__input {
    padding-right: 20% !important;
  }
  .tabMentions--multiLine .tabMentions__highlighter {
    padding-right: 20% !important;
  }
  // .mentions--multiLine .mentions__input {
  //   padding-right: 40%;
  // }
  .mentions--multiLine .mentions__highlighter {
    padding-right: 40%;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1400px) {
  .iPageStyles {
    right: 115px;
  }
  .buttonContainer {
    right: 55px;
  }
  .tabMentions--multiLine .tabMentions__input {
    padding-right: 18% !important;
  }
  .tabMentions--multiLine .tabMentions__highlighter {
    padding-right: 18% !important;
  }
  .mentions--multiLine .mentions__input {
    padding-right: 40%;
  }
  .mentions--multiLine .mentions__highlighter {
    padding-right: 40%;
  }
}
@media only screen and (min-width: 1000px) {
  .tabButtonContainer {
    right: 12px;
  }
  .ristrictBtnContainer {
    right: 12px;
  }
  .tabMentions--multiLine .tabMentions__highlighter {
    padding-right: 18% !important;
  }
}

@media only screen and (min-width: 1400px) {
  .tabMentions--multiLine .tabMentions__input {
    padding-right: 18% !important;
  }
  .mentions--multiLine .mentions__input {
    padding-right: 36%;
  }
  .mentions--multiLine .mentions__highlighter {
    padding-right: 36%;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1050px) {
  .tabMentions--multiLine .tabMentions__input {
    padding-right: 26% !important;
  }
  .tabMentions--multiLine .tabMentions__highlighter {
    padding-right: 26% !important;
  }
}

@media screen and (min-width: 1150px) and (max-width: 1300px) {
  .ristrictConatiner {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;
    width: 213px;
  }
  .container {
    margin-top: 10px;
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 204px;
  }
  .mentions--multiLine {
    max-width: 100%;
    min-width: 100%;
    display: inline-block;
    width: 100%;
    margin-left: 0px !important;
    border: 1px solid transparent;
    position: relative;
    // padding-right: 50px;
    &:hover {
      // border: 1px solid #011cf5;
      border-radius: 5px;
    }
  }

  .mentions--multiLine .mentions__input {
    padding-right: 20% !important;
  }
}
// SEND_ICON

/* For hide and display the send arrow button */

@media screen and (min-width: 1150px) and (max-width: 1250px) {
  .tabButtonContainer {
    bottom: 4px;
  }
  .ristrictBtnContainer {
    bottom: 4px;
  }
  .sendIcon {
    cursor: pointer;
    display: block !important;
    margin-bottom: 5px;
    margin-left: 4px;
  }

  .tabChat {
    display: none !important;
  }
}
@media screen and (min-width: 1251px) {
  .tabChat {
    display: block;
  }
  .resitrictIcon {
    display: none;
  }
  .sendIcon {
    display: none;
  }
}

////////// for ristrcict c////////
@media screen and (min-width: 1150px) and (max-width: 1200px) {
  .ristrictConatiner {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;
    max-width: 154px;
  }
  .noPanelConatainer {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;
    max-width: 182px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1250px) {
  .ristrictConatiner {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;
    width: 201px;
  }
}

@media screen and (min-width: 1150px) and (max-width: 1300px) {
  .resitrictIcon {
    cursor: pointer;
    display: block !important;
    margin-bottom: 5px;
    margin-left: 4px;
  }
  .ristrictBtn {
    display: none !important;
  }
  .ristrictBtnContainer {
    bottom: 4px;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
  .ristrictConatiner {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 90%;
    max-width: 184px;
  }
  .noPanelConatainer {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;
    max-width: 213px;
  }
  .container {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 90%;
    max-width: 206px;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1400px) {
  .ristrictConatiner {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 90%;
    max-width: 236px;
  }
  .noPanelConatainer {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;
    max-width: 241px;
  }
}
@media screen and (min-width: 1401px) {
  .ristrictConatiner {
    position: relative;
    display: flex;
    align-items: flex-end;
    // width: 90%;
    max-width: 100%;
    // max-width: 242px;
  }
  .noPanelConatainer {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;
    max-width: 100%;
    // max-width: 241px;
  }
}
