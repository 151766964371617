@import "../../../styles/variables.scss";

.capsule {
  display: flex;
  border: 1px solid #a3a3a3;
  border-radius: 5px;
  color: #a3a3a3;
  font-size: 10px;
  padding: 3px 4px 3px 20px;
  font-weight: 500;
  &:hover {
    border: 1px solid #011cf5;
    color: #011cf5 !important;
  }
  &:focus-visible {
    outline: none !important;
  }
  background: transparent;
}

.btnactive {
  display: flex;
  border: 1px solid $primaryColor !important;
  color: $primaryColor !important;
  padding: 3px 10px 3px 14px;

  svg {
    width: 15px;
    height: 15px;
  }
}

.socialIcon {
  margin-top: -1px;
  height: 16px;
  width: 16px;
}
