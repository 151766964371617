.header {
  padding: 20px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  color: #2f2f2f;
  line-height: 1;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.markAll {
  font-size: 8px;
  font-weight: 600;
  line-height: 1;
  color: #2f2f2f;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: #011cf5;
  }
}

.toggleRow {
  min-height: 30px !important;
  padding: 0px 39px 0px 26px;
}

.noMore {
  font-size: 12px;
  font-weight: 600;
  color: #a3a3a3;
  padding: 12px 25px;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.tabIcon {
  height: 13px;
  width: 22px;
}

.count {
  background-color: #011cf5;
  color: white;
  height: 16px;
  min-width: 16px;
  border-radius: 43%;
  font-size: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  padding: 0 3px;
}

.topTabIcon {
  height: 14px;
  width: 15px;
  margin-right: 4px;
}

.check {
  background-color: red;
  height: 55vh !important;
}
.checkOne {
  height: 55vh !important;
}

.tabHeadingButton {
  display: flex;
  font-weight: 500;
  &:hover {
    color: #011cf5;
  }
}
