.edithead {
  h1 {
    font-size: 26px;
    font-weight: 1000;
    color: #000e75;
  }

  h5 {
    font-size: 13px;
    font-weight: 800;
    color: #000e75;
  }

  h3 {
    letter-spacing: 0px;
    color: #2f2f2f;
    font-size: 12px;
    font-weight: 500;
  }
  .heading {
    letter-spacing: 0px;
    color: #2f2f2f;
    font-size: 14px;
    font-weight: 500;
  }
}

.searchbtn {
  background: transparent linear-gradient(90deg, #011cf5 0%, #0c2659 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000040;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  color: #fff;
  padding: 5px 25px;
  font-weight: 300;
  height: 32px;
}

.navlink.active {
  color: #011cf5 !important;
}

.topheading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 27px 20px 0 20px;
}
.pointHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 9px 20px;
}
.btnContainer {
  margin-top: 20px;
  // margin-bottom: 8px;
}

.contactHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0 20px;
}

.represntHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 8px 20px;
}

.cardDivider {
  margin-top: 11px;
  color: #dedede;
  margin-bottom: 13px;
}

.middleCard {
  padding: 0px 20px 20px 20px;
}
.spaceContainer {
  margin-top: 12px;
}

// .headingText {
//   display: contents;
// }
.label {
  width: 100%;
  color: #a3a3a3;
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 17px;
  margin-top: 10px;
}

.removeStyles {
  // background-color: #bf1736;
}

.checkBoxLabel {
  label {
    color: #a3a3a3 !important;
    font-size: 10px !important;
  }
}
