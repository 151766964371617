.filter {
  margin-top: 8px !important;
  margin-bottom: 25px !important;
  padding-left: 0px !important;
  padding-right: 14px !important;
}

.scrollDiv {
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollDiv::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollDiv {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
