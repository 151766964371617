.detailsicons {
  span {
    background-color: #f4f4f4;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
    &:hover {
      background-color: #dddddd;
    }

    img {
      width: 12.5px;
      height: 12.5px;
    }
  }
}
