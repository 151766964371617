@import "../../../styles/variables.scss";
.label {
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
  margin-left: 25px;
  cursor: pointer;
}

.input {
  width: 35px;
  height: 30px;
  border-radius: 10px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  position: relative;
  top: -3px;
  overflow: hidden;
}

.container {
  //   margin-top: 12px;
  display: flex;
  justify-content: center;
  //   margin-bottom: 12px;
}

.round {
  position: relative;
  height: 15px;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 25%;
  cursor: pointer;
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  width: 15px;

  &:hover {
    background-color: #e7e9fe;
  }
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 5px;
  left: 2px;
  opacity: 0;
  position: absolute;
  top: 3px;
  transform: rotate(-45deg);
  width: 10px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: $primaryColor;
  border-color: $primaryColor;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.tableCheckBox {
  // margin-top: 6px;
  display: flex;
  justify-content: center;
  //   margin-bottom: 12px;
}
