.progressBox {
  padding-left: 4px !important;
  padding-right: 9px !important;
}
.box {
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 26px;
}
.progressChart {
  width: 110px;
  height: 110px;
}
.chartText {
  font-size: 12px;
  font-weight: 500;
  padding-left: 18px;
  padding-top: 23px;
}
