.tabcontent {
  min-height: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 100%;
  // padding: 20px;
  padding-bottom: 15%;
}
// .cardDivider {
//   margin-top: 11px;
//   color: #dedede;
//   margin-bottom: 8px;
// }
.topHeader {
  padding: 25px 20px 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.middleCard {
  padding: 26px 20px;
  display: flex;
  // justify-content: end;
}

.headingText {
  letter-spacing: 0px;
  color: #2f2f2f;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.searchInputDataSets {
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #f4f4f4 !important;
  padding-left: 32px;
  &:focus {
    box-shadow: none !important;
    outline: none;
  }

  &::placeholder {
    color: #a3a3a3;
    opacity: 1;
  }
}
.searchIcon {
  position: absolute;
  left: 10px;
  height: 15px;
  width: 15px;
  top: 11px;
}
.searchInputContainer {
  width: 36%;
  position: relative;
  border-radius: 5px;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid #011cf5;
  }
}

.topheading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 27px 20px 0 20px;
}
// .h3heading {
//   letter-spacing: 0px;
//   color: #2f2f2f;
//   font-size: 14px;
//   font-weight: 500;
//   margin: 0;
// }
.topCardDivider {
  margin-top: 19px;
  color: #dedede;
  margin-bottom: 8px;
}
.paginationContiner {
  padding: 10px 20px 10px 20px;
}
.addTalentUser {
  margin-right: 6px;
  width: 13px;
  height: 13px;
  margin-bottom: 2px;
}

.topheading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 20px 1px 20px;
}
.h3heading {
  letter-spacing: 0px;
  color: #2f2f2f;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.cardDivider {
  margin-top: 11px;
  color: #dedede;
  margin-bottom: 8px;
}
