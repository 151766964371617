@import "../../../styles/variables.scss";

.formname {
  h3 {
    color: $secondaryColor;
    font-size: 12px;
    font-weight: 500;
    margin-top: 15px;
  }
}

.tablehead {
  th {
    letter-spacing: 0px;
    color: $secondaryColor;
    font-weight: 500;
    font-size: 12px;
  }

  td {
    border: none;
    color: $secondaryColor;
    font-weight: 400;
    font-size: 12px;

    img {
      width: 100%;
      max-width: 22px;
      text-align: left;
    }
  }

  thead {
    background: #f4f4f4 0% 0% no-repeat padding-box;
  }

  tbody {
    border: none !important;
  }

  tr {
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
  }
}

.numberDiv {
  width: 70px;
  text-align: right;
}
.tdwidth {
  width: 25%;
  // text-align: right;
}
