.overview {
    width: calc(100% - 50px);
    padding: 25px 55px 40px 50px;
  
    .heading {
      h2 {
        color: #0c2659;
        font-size: 24px;
        font-weight: bold;
      }
    }
  
    .bgprofileleft {
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 5px;
      padding: 20px;
      width: 196px;
      position: sticky;
      top: 0;
      height: 100%;
  
      .navlink.active,
      .show > .navlink {
        background: transparent;
        color: #011cf5 !important;
        text-align: left;
        position: relative;
  
        &::after {
          position: absolute;
          content: "";
          width: 2px;
          height: 24px;
          background: #011cf5 !important;
          left: -20px;
          top: 5px;
        }
      }
  
      .navlink {
        padding: 8px 0;
        text-align: left;
        color: #2f2f2f !important;
        font-size: 12px;
        font-weight: 500;
        background-color: transparent !important;
      }
    }
  
    .tabcontent {
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 5px;
      width: 55%;
    
  
      .searchbtn {
        background: transparent linear-gradient(90deg, #011cf5 0%, #0c2659 100%)
          0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000040;
        border-radius: 5px;
        border: none;
        font-size: 14px;
        color: #fff;
        padding: 5px 25px;
        font-weight: 300;
        height: 32px;
      }
  
      .searchbtnposition {
        color: #2f2f2f;
        border: 1px solid #2f2f2f;
        border-radius: 5px;
        font-size: 12px;
        background: transparent;
        font-weight: 500;
        padding: 5px 15px;
        align-items: center;
        display: flex;
        justify-content: center;
  
        img {
          width: 100%;
          max-width: 18px;
          margin-right: 5px;
        }
      }
  
      .edithead {
        h3 {
          letter-spacing: 0px;
          color: #2f2f2f;
          font-size: 14px;
          font-weight: 500;
          margin: 0;
        }
      }
  
      .formname {
        .formgroup {
          label {
            width: 100%;
            color: #a3a3a3;
            font-size: 10px;
            font-weight: 500;
            margin-bottom: 4px;
            margin-top: 10px;
          }
  
          input {
            border: 1px solid #dddddd;
            border-radius: 5px;
            width: 100%;
            height: 35px;
            padding: 15px;
            color: #2f2f2f;
            font-size: 12px;
            font-weight: 500;
          }
  
          :focus {
            outline: none;
          }
  
          .midname {
            label {
              width: 60% !important;
            }
  
            p {
              color: #bf1736;
              font-size: 8px;
              font-weight: 500;
              margin: 0;
            }
          }
  
          .workingcheck {
            input {
              width: 35px;
              height: 30px;
              border-radius: 10px;
            }
  
            h4 {
              color: #2f2f2f;
              font-size: 10px;
              margin: 0 0 0 10px;
            }
          }
  
          select {
            color: #2f2f2f;
            font-size: 12px;
            height: 35px;
          }
        }
  
        h3 {
          color: #2f2f2f;
          font-size: 12px;
          font-weight: 500;
          margin-top: 15px;
        }
      }
  
      P {
        color: #a3a3a3;
        font-size: 12px;
      }
  
      .dragattach {
        background: #0023410a 0% 0% no-repeat padding-box;
        border: 1px dashed #011cf5;
        border-radius: 5px;
        text-align: center;
        padding: 80px 0;
  
        h5 {
          color: #2f2f2f;
          font-size: 12px;
          margin: 5px 0;
          font-weight: 500;
  
          span {
            color: #011cf5;
          }
        }
  
        p {
          color: #a3a3a3;
          font-size: 10px;
          margin: 0;
        }
      }
  
      .tablehead {
        th {
          letter-spacing: 0px;
          color: #2f2f2f;
          font-weight: 500;
          font-size: 12px;
        }
  
        td {
          border: none;
          color: #2f2f2f;
          font-weight: 400;
          font-size: 12px;
  
          img {
            width: 100%;
            max-width: 22px;
            text-align: left;
          }
        }
  
        thead {
          background: #f4f4f4 0% 0% no-repeat padding-box;
        }
  
        tbody {
          border: none !important;
        }
  
        tr {
          border-top: 1px solid #dedede;
          border-bottom: 1px solid #dedede;
        }
      }
    }
  }
  
  .navlink.active {
    color: #011cf5 !important;
  }
  
  .mobileRow {
    display: flex;
    width: 100%;
  }
  
  // .mobileInput {
  //   width: 90%;
  // }
  
  .mobilebuttons {
    width: 10%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  