@import "../../../../styles/variables.scss";
.overview {
  width: calc(100% - 50px);
  padding: 30px 55px 40px 50px;
}

.tabcontent {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 55.2%;

  .edithead {
    h3 {
      letter-spacing: 0px;
      color: $secondaryColor;
      font-size: 14px;
      font-weight: 500;
      margin: 0;
    }
  }

  .formname {
    h3 {
      color: $secondaryColor;
      font-size: 12px;
      font-weight: 500;
      margin-top: 30px;
    }
  }
}

.listLabel {
  color: $secondaryColor;
  font-weight: 500;
  margin-top: 18px;
  font-size: 12px;
}

.lightLabel {
  color: #a3a3a3;
  font-size: 10px;
}

.helperText {
  color: #a3a3a3 !important;
  font-size: 10px !important;
  margin-top: 10px !important;
}

.pdfIcon {
  height: 20px;
  margin-left: 10px;
}
.topheading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 5px 19px 20px;
  border-bottom: 1px solid rgba(222, 222, 222, 0.5215686275);
}
.middleCard {
  padding: 10px 20px 0px 20px;
}
.bottomFotter {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 20px 20px 0px 20px;
  flex-direction: column;
}
.cardDivider {
  margin-top: 11px;
  color: #dedede;
  margin-bottom: 0px;
}

.overviewHeading {
  border-bottom: 1px solid #dedede85;
}

.bottomCard {
  padding: 0px 21px 20px 21px;
}

.headingText {
  color: #1c1c1d;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.documentName {
  display: flex;
  justify-content: start;
  align-items: start;
}

.headingContainer {
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tableHeading {
  color: #0c2659 !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.noassignment {
  font-size: 12px;
  font-weight: 500 !important;
  color: #a3a3a3 !important;
  margin-top: 22px;
  margin-bottom: 22px;
}
