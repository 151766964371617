*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

.tw-container {
  width: 100%;
}

@media (min-width: 640px) {
  .tw-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .tw-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .tw-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .tw-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .tw-container {
    max-width: 1536px;
  }
}

.tw-fixed {
  position: fixed;
}

.tw-absolute {
  position: absolute;
}

.tw-relative {
  position: relative;
}

.tw-sticky {
  position: -webkit-sticky;
  position: sticky;
}

.tw-inset-0 {
  inset: 0px;
}

.tw-bottom-0 {
  bottom: 0px;
}

.tw-left-0 {
  left: 0px;
}

.tw-left-\[18\.7\%\] {
  left: 18.7%;
}

.tw-left-\[23\%\] {
  left: 23%;
}

.tw-right-\[21\%\] {
  right: 21%;
}

.tw-right-\[28\%\] {
  right: 28%;
}

.tw-right-\[3\%\] {
  right: 3%;
}

.tw-right-\[31\.4\%\] {
  right: 31.4%;
}

.tw-right-\[33\%\] {
  right: 33%;
}

.tw-right-\[5\%\] {
  right: 5%;
}

.tw-right-\[8\%\] {
  right: 8%;
}

.tw-right-full {
  right: 100%;
}

.tw-top-0 {
  top: 0px;
}

.tw-top-\[10\%\] {
  top: 10%;
}

.tw-top-\[12\%\] {
  top: 12%;
}

.tw-top-\[14\%\] {
  top: 14%;
}

.tw-top-\[15\%\] {
  top: 15%;
}

.tw-top-\[20\.6\%\] {
  top: 20.6%;
}

.tw-top-\[28\%\] {
  top: 28%;
}

.tw-top-\[45\%\] {
  top: 45%;
}

.tw-top-\[47\%\] {
  top: 47%;
}

.tw-top-\[49\.5\%\] {
  top: 49.5%;
}

.tw-top-\[6\.3\%\] {
  top: 6.3%;
}

.tw-top-\[7\.2\%\] {
  top: 7.2%;
}

.tw-z-50 {
  z-index: 50;
}

.tw-z-\[1000\] {
  z-index: 1000;
}

.\!tw-m-0 {
  margin: 0px !important;
}

.tw-m-0 {
  margin: 0px;
}

.\!tw-mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.\!tw-my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.tw-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.tw-my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.tw-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tw-my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.-tw-mt-0 {
  margin-top: -0px;
}

.-tw-mt-0\.5 {
  margin-top: -0.125rem;
}

.-tw-mt-\[3px\] {
  margin-top: -3px;
}

.tw-mb-0 {
  margin-bottom: 0px;
}

.tw-mb-1 {
  margin-bottom: 0.25rem;
}

.tw-mb-2 {
  margin-bottom: 0.5rem;
}

.tw-mb-8 {
  margin-bottom: 2rem;
}

.tw-mr-1 {
  margin-right: 0.25rem;
}

.tw-mr-6 {
  margin-right: 1.5rem;
}

.tw-mr-\[10px\] {
  margin-right: 10px;
}

.tw-mr-\[8px\] {
  margin-right: 8px;
}

.tw-mt-2 {
  margin-top: 0.5rem;
}

.tw-mt-3 {
  margin-top: 0.75rem;
}

.tw-mt-4 {
  margin-top: 1rem;
}

.tw-block {
  display: block;
}

.tw-inline {
  display: inline;
}

.tw-flex {
  display: flex;
}

.tw-inline-flex {
  display: inline-flex;
}

.tw-grid {
  display: grid;
}

.tw-hidden {
  display: none;
}

.tw-h-0 {
  height: 0px;
}

.tw-h-16 {
  height: 4rem;
}

.tw-h-3 {
  height: 0.75rem;
}

.tw-h-32 {
  height: 8rem;
}

.tw-h-4 {
  height: 1rem;
}

.tw-h-5 {
  height: 1.25rem;
}

.tw-h-8 {
  height: 2rem;
}

.tw-h-\[10px\] {
  height: 10px;
}

.tw-h-\[13px\] {
  height: 13px;
}

.tw-h-\[300px\] {
  height: 300px;
}

.tw-h-\[480px\] {
  height: 480px;
}

.tw-h-\[70\%\] {
  height: 70%;
}

.tw-h-\[76px\] {
  height: 76px;
}

.tw-h-\[94px\] {
  height: 94px;
}

.tw-h-full {
  height: 100%;
}

.tw-h-max {
  height: -webkit-max-content;
  height: max-content;
}

.tw-h-screen {
  height: 100vh;
}

.tw-max-h-9 {
  max-height: 2.25rem;
}

.tw-max-h-\[400px\] {
  max-height: 400px;
}

.tw-min-h-\[400px\] {
  min-height: 400px;
}

.\!tw-w-1\/4 {
  width: 25% !important;
}

.\!tw-w-\[15\%\] {
  width: 15% !important;
}

.\!tw-w-\[25\%\] {
  width: 25% !important;
}

.\!tw-w-\[30\%\] {
  width: 30% !important;
}

.tw-w-1\/2 {
  width: 50%;
}

.tw-w-1\/4 {
  width: 25%;
}

.tw-w-11\/12 {
  width: 91.666667%;
}

.tw-w-16 {
  width: 4rem;
}

.tw-w-2\/12 {
  width: 16.666667%;
}

.tw-w-3 {
  width: 0.75rem;
}

.tw-w-32 {
  width: 8rem;
}

.tw-w-4 {
  width: 1rem;
}

.tw-w-5 {
  width: 1.25rem;
}

.tw-w-5\/12 {
  width: 41.666667%;
}

.tw-w-8 {
  width: 2rem;
}

.tw-w-8\/12 {
  width: 66.666667%;
}

.tw-w-\[10px\] {
  width: 10px;
}

.tw-w-\[15\%\] {
  width: 15%;
}

.tw-w-\[25\%\] {
  width: 25%;
}

.tw-w-\[30\%\] {
  width: 30%;
}

.tw-w-\[400px\] {
  width: 400px;
}

.tw-w-\[525px\] {
  width: 525px;
}

.tw-w-full {
  width: 100%;
}

.tw-w-max {
  width: -webkit-max-content;
  width: max-content;
}

.tw-w-\[5\%\] {
  width: 5%;
}

.\!tw-w-\[5\%\] {
  width: 5% !important;
}

.tw-min-w-\[170px\] {
  min-width: 170px;
}

.tw-flex-none {
  flex: none;
}

.tw-table-fixed {
  table-layout: fixed;
}

.tw-rotate-\[270deg\] {
  --tw-rotate: 270deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-rotate-\[90deg\] {
  --tw-rotate: 90deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@-webkit-keyframes tw-spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes tw-spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.tw-animate-spin {
  -webkit-animation: tw-spin 1s linear infinite;
          animation: tw-spin 1s linear infinite;
}

.tw-cursor-not-allowed {
  cursor: not-allowed;
}

.tw-cursor-pointer {
  cursor: pointer;
}

.tw-appearance-none {
  -webkit-appearance: none;
          appearance: none;
}

.tw-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.tw-flex-col {
  flex-direction: column;
}

.tw-items-start {
  align-items: flex-start;
}

.tw-items-center {
  align-items: center;
}

.tw-justify-end {
  justify-content: flex-end;
}

.tw-justify-center {
  justify-content: center;
}

.tw-justify-between {
  justify-content: space-between;
}

.tw-gap-16 {
  gap: 4rem;
}

.tw-gap-4 {
  gap: 1rem;
}

.tw-space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.tw-overflow-hidden {
  overflow: hidden;
}

.tw-overflow-y-scroll {
  overflow-y: scroll;
}

.tw-rounded-full {
  border-radius: 9999px;
}

.tw-rounded-md {
  border-radius: 0.375rem;
}

.tw-border {
  border-width: 1px;
}

.tw-border-b {
  border-bottom-width: 1px;
}

.tw-border-l-8 {
  border-left-width: 8px;
}

.tw-border-t {
  border-top-width: 1px;
}

.tw-border-\[\#\#dedede\] {
  border-color: ##dedede;
}

.tw-border-\[\#bf1736\] {
  --tw-border-opacity: 1;
  border-color: rgb(191 23 54 / var(--tw-border-opacity));
}

.tw-border-\[\#ddd\] {
  --tw-border-opacity: 1;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));
}

.tw-border-\[\#dedede\] {
  --tw-border-opacity: 1;
  border-color: rgb(222 222 222 / var(--tw-border-opacity));
}

.tw-border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.tw-border-current {
  border-color: currentColor;
}

.tw-border-slate-100 {
  --tw-border-opacity: 1;
  border-color: rgb(241 245 249 / var(--tw-border-opacity));
}

.tw-border-transparent {
  border-color: transparent;
}

.tw-bg-\[\#011cf5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(1 28 245 / var(--tw-bg-opacity));
}

.tw-bg-\[\#011fc5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(1 31 197 / var(--tw-bg-opacity));
}

.tw-bg-\[\#0c2659\] {
  --tw-bg-opacity: 1;
  background-color: rgb(12 38 89 / var(--tw-bg-opacity));
}

.tw-bg-\[\#bf1736\] {
  --tw-bg-opacity: 1;
  background-color: rgb(191 23 54 / var(--tw-bg-opacity));
}

.tw-bg-\[\#e4e4e4\] {
  --tw-bg-opacity: 1;
  background-color: rgb(228 228 228 / var(--tw-bg-opacity));
}

.tw-bg-\[\#f4f4f4\] {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 244 / var(--tw-bg-opacity));
}

.tw-bg-\[\#fafafa\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.tw-bg-black\/50 {
  background-color: rgb(0 0 0 / 0.5);
}

.tw-bg-black\/80 {
  background-color: rgb(0 0 0 / 0.8);
}

.tw-bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.tw-bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.tw-bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.tw-bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.tw-bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

.tw-bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(165 180 252 / var(--tw-bg-opacity));
}

.tw-bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}

.tw-bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.tw-bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.tw-bg-transparent {
  background-color: transparent;
}

.tw-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.tw-bg-cover {
  background-size: cover;
}

.tw-bg-center {
  background-position: center;
}

.tw-bg-no-repeat {
  background-repeat: no-repeat;
}

.tw-p-0 {
  padding: 0px;
}

.tw-p-1 {
  padding: 0.25rem;
}

.tw-p-10 {
  padding: 2.5rem;
}

.tw-p-12 {
  padding: 3rem;
}

.tw-p-16 {
  padding: 4rem;
}

.tw-p-2 {
  padding: 0.5rem;
}

.tw-p-20 {
  padding: 5rem;
}

.tw-p-4 {
  padding: 1rem;
}

.tw-p-8 {
  padding: 2rem;
}

.tw-px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.tw-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.tw-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.tw-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.tw-px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.tw-py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.tw-py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.tw-py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.tw-py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.tw-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.tw-py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.tw-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.tw-pb-0 {
  padding-bottom: 0px;
}

.tw-pb-0\.5 {
  padding-bottom: 0.125rem;
}

.tw-pb-16 {
  padding-bottom: 4rem;
}

.tw-pb-3 {
  padding-bottom: 0.75rem;
}

.tw-pr-4 {
  padding-right: 1rem;
}

.tw-pt-1 {
  padding-top: 0.25rem;
}

.tw-pt-8 {
  padding-top: 2rem;
}

.tw-text-center {
  text-align: center;
}

.tw-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.tw-text-\[10px\] {
  font-size: 10px;
}

.tw-text-\[11px\] {
  font-size: 11px;
}

.tw-text-\[12px\] {
  font-size: 12px;
}

.tw-text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.tw-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.tw-text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.tw-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.tw-text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.tw-font-black {
  font-weight: 900;
}

.tw-font-bold {
  font-weight: 700;
}

.tw-font-medium {
  font-weight: 500;
}

.tw-font-normal {
  font-weight: 400;
}

.tw-uppercase {
  text-transform: uppercase;
}

.tw-capitalize {
  text-transform: capitalize;
}

.tw-italic {
  font-style: italic;
}

.tw-tracking-tight {
  letter-spacing: -0.025em;
}

.tw-tracking-wide {
  letter-spacing: 0.025em;
}

.\!tw-text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

.\!tw-text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.tw-text-\[\#011cf5\] {
  --tw-text-opacity: 1;
  color: rgb(1 28 245 / var(--tw-text-opacity));
}

.tw-text-\[\#011cf5\]\/50 {
  color: rgb(1 28 245 / 0.5);
}

.tw-text-\[\#011fc5\] {
  --tw-text-opacity: 1;
  color: rgb(1 31 197 / var(--tw-text-opacity));
}

.tw-text-\[\#212529\] {
  --tw-text-opacity: 1;
  color: rgb(33 37 41 / var(--tw-text-opacity));
}

.tw-text-\[\#2e2e2e\] {
  --tw-text-opacity: 1;
  color: rgb(46 46 46 / var(--tw-text-opacity));
}

.tw-text-\[\#2f2f2f\] {
  --tw-text-opacity: 1;
  color: rgb(47 47 47 / var(--tw-text-opacity));
}

.tw-text-\[\#6b7280\] {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.tw-text-\[\#a3a3a3\] {
  --tw-text-opacity: 1;
  color: rgb(163 163 163 / var(--tw-text-opacity));
}

.tw-text-\[\#bf1736\] {
  --tw-text-opacity: 1;
  color: rgb(191 23 54 / var(--tw-text-opacity));
}

.tw-text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.tw-text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.tw-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.tw-text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.tw-text-slate-200 {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity));
}

.tw-text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.tw-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.tw-accent-\[\#011cf5\] {
  accent-color: #011cf5;
}

.tw-shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.flux {
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

button {
  font-family: inherit;
  /* 1 */
  -webkit-font-feature-settings: inherit;
  font-feature-settings: inherit;
  /* 1 */
  font-variation-settings: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  font-weight: inherit;
  /* 1 */
  line-height: inherit;
  /* 1 */
  color: inherit;
  /* 1 */
  margin: 0;
  /* 2 */
  padding: 0;
  /* 3 */
  text-transform: none;
}

/* button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;

  background-color: transparent;

  background-image: none;

} */

button,
[role="button"] {
  cursor: pointer;
}

*,
::before,
::after {
  box-sizing: border-box;
  /* 1 */
  border-width: 0;
  /* 2 */
  border-style: solid;
  /* 2 */
  border-color: #e5e7eb;
  /* 2 */
}

/* Hide scrollbar for Chrome, Safari and Opera */

.no-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.no-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.checked\:tw-bg-black:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.hover\:tw-border-\[\#011f5c\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(1 31 92 / var(--tw-border-opacity));
}

.hover\:tw-border-\[\#011fc5\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(1 31 197 / var(--tw-border-opacity));
}

.hover\:tw-border-\[\#bf1736\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(191 23 54 / var(--tw-border-opacity));
}

.hover\:tw-border-black:hover {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.hover\:tw-border-current:hover {
  border-color: currentColor;
}

.hover\:tw-bg-\[\#306bff\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(48 107 255 / var(--tw-bg-opacity));
}

.hover\:tw-bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.hover\:tw-bg-slate-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.hover\:tw-bg-transparent:hover {
  background-color: transparent;
}

.hover\:tw-bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:\!tw-text-black:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

.hover\:\!tw-text-white:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.hover\:tw-text-\[\#011f5c\]:hover {
  --tw-text-opacity: 1;
  color: rgb(1 31 92 / var(--tw-text-opacity));
}

.hover\:tw-text-\[\#011fc5\]:hover {
  --tw-text-opacity: 1;
  color: rgb(1 31 197 / var(--tw-text-opacity));
}

.hover\:tw-text-\[\#bf1736\]:hover {
  --tw-text-opacity: 1;
  color: rgb(191 23 54 / var(--tw-text-opacity));
}

.hover\:tw-text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.hover\:tw-text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.hover\:tw-text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:tw-underline:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.hover\:\!tw-opacity-50:hover {
  opacity: 0.5 !important;
}

.hover\:\!tw-opacity-60:hover {
  opacity: 0.6 !important;
}

.focus\:tw-border-\[\#011cf5\]:focus {
  --tw-border-opacity: 1;
  border-color: rgb(1 28 245 / var(--tw-border-opacity));
}

.disabled\:tw-cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:tw-bg-slate-100:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.disabled\:tw-bg-slate-300:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}

.disabled\:tw-text-slate-400:disabled {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.disabled\:tw-text-slate-600:disabled {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
}

.disabled\:tw-opacity-25:disabled {
  opacity: 0.25;
}

.disabled\:tw-opacity-30:disabled {
  opacity: 0.3;
}

.disabled\:tw-opacity-40:disabled {
  opacity: 0.4;
}

.disabled\:hover\:tw-bg-\[\#011fc5\]:hover:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(1 31 197 / var(--tw-bg-opacity));
}

.disabled\:hover\:tw-text-white:hover:disabled {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.tw-group:hover .group-hover\:tw-block {
  display: block;
}

.has-\[\:focus\]\:\!tw-text-black:has(:focus) {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

@media (min-width: 1024px) {
  .lg\:tw-bg-\[\#011fc5\] {
    --tw-bg-opacity: 1;
    background-color: rgb(1 31 197 / var(--tw-bg-opacity));
  }

  .lg\:tw-bg-\[\#f4f4f4\] {
    --tw-bg-opacity: 1;
    background-color: rgb(244 244 244 / var(--tw-bg-opacity));
  }

  .lg\:tw-text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }

  .lg\:tw-text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .lg\:hover\:tw-border-current:hover {
    border-color: currentColor;
  }

  .lg\:hover\:tw-bg-\[\#011fc5\]:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(1 31 197 / var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-transparent:hover {
    background-color: transparent;
  }

  .lg\:hover\:tw-text-\[\#011fc5\]:hover {
    --tw-text-opacity: 1;
    color: rgb(1 31 197 / var(--tw-text-opacity));
  }

  .lg\:hover\:tw-text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}
