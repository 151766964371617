.clientCity {
  color: #2f2f2f;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.clientState {
  color: #2f2f2f;
  font-size: 12px;
  font-weight: normal;
  margin: 0;
  line-height: 1.4;
}
