@import "../../../../styles/variables.scss";

.edithead {
  display: flex;
  align-items: center;

  h3 {
    letter-spacing: 0px;
    font-weight: 500;
    margin: 0;
    padding: 0 20px;
    margin: 30px 0px 20px 0px;
    color: #0c2659;
    font-size: 14px;
    font-weight: bold;
  }
}

.fullwidth {
  width: 100%;
}

.overviewHeading {
  padding: 20px 20px 18px 20px;
  border-bottom: 1px solid rgba(222, 222, 222, 0.5215686275);
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  h2 {
    color: #1c1c1d;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }
}

.dataNotFound {
  text-align: center;
  font-size: 12px;
  // text-transform: capitalize;
  font-weight: 500 !important;
  padding: 20px;
  color: #a3a3a3 !important;
}

.tableContainer {
  padding-left: 20px;
  padding-right: 20px;
}
