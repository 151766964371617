@import "../../../../styles/mixins.scss";

// .talentdetails {
//   @include middleContainer;
// }

.talentdetails {
  @include middleContainer;
  //@include ristricMiddleContainer;
}
.ristrictStyles {
  @include ristricMiddleContainer;
}
