.navitem {
  margin-top: 6px;
  position: relative;
  color: #fff;
  font-size: 12px;
  padding: 0 5px;

  span {
    color: #fff;
    font-weight: bold;
  }
}

.followUpText {
  margin-top: 8px;
}
