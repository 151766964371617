.SearchAbleInput {
  border-radius: 5px;
  width: 100%;
  padding-left: 10px;
  border: 1px solid white;
  height: 40px;
  position: relative;
  padding-top: 2px;
  padding-right: 34px;
  padding-left: 34px;
  background-color: #f4f4f4;
  border: 1px solid #d3d3d3;
  font-size: 10px;

  &:focus {
    background-color: white;
    border: none;
    box-shadow: 2px 2px 11px #d3d3d3;
  }
  &:focus-visible {
    outline: none;
  }
}
.searchIcon {
  width: 25px;
  height: 15px;
  position: absolute;
  cursor: text;
  left: 26px;
}

.resetIcon {
  right: 24px;
  cursor: pointer;
  position: absolute;
}
