.footerContainer {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
.createByStyles {
  font-size: 10px;
  line-height: 16px;
  color: #a3a3a3;
  text-transform: none;
  font-weight: 400;
}
