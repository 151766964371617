@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

//@at-root/////////////////////////////////
.searchfilter {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;

  form {
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    // box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;

    // input {
    //   width: 85%;
    //   border: none;
    //   height: 34px;
    //   outline: none;
    //   appearance: none;
    //   color: #2f2f2f;
    //   font-size: 12px;
    //   font-weight: 500;
    //   border-right: 1px solid #dedede;

    //   ::placeholder {
    //     color: #a3a3a3;
    //   }

    //   :focus-visible {
    //     outline: none;
    //     appearance: none;
    //   }
    // }

    // svg {
    //   color: #a3a3a3;
    //   width: 15px;
    //   height: 15px;
    //   margin-right: 5px;
    // }
  }
}

.divider {
  margin-top: 10px;
  margin-bottom: 12px;
  border-color: #707070;
  opacity: 0.1;
}

.title {
  color: #0c2659;
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 0px;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.fw400 {
  font-weight: 400;
}

.socialIcon {
  height: 18px;
  width: 18px;
  margin-right: 10px;
}

.filters {
  p {
    color: #011cf5;
    font-size: 14px;
    font-weight: 500;
    margin: 0 22px 0 12px;
    text-transform: capitalize;
  }

  svg {
    // color: #011cf5 !important;
    width: 21px;
    height: 21px;
    margin-right: 1px;
  }

  .searchbtn {
    background: transparent linear-gradient(90deg, #011cf5 0%, #0c2659 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000040;
    border-radius: 5px;
    border: none;
    font-size: 14px;
    color: #fff;
    padding: 5px 25px;
    font-weight: 300;
  }
}

.tabMainContent {
  @include middleContainer;
  padding: 23px 36px 43.2px 36px;
}

.switchrightlabel {
  margin-left: 35px;
  margin-right: 10px;
  font-weight: 600;
}

.showingdata {
  margin-bottom: 0px;
  margin-top: 0px;
  align-items: center;
  display: flex;
  font-size: 10px;
  font-weight: 500;
}

.showingright {
  img {
    width: 18px;
    height: 18px;
  }

  p {
    font-size: 10px;
    font-weight: 500;
    align-items: center;
    display: flex;
    margin-bottom: 0;
    color: black;
  }
}

.selectboxesinputtop {
  width: 100%;
  max-width: 100%;
  margin: 0px auto 0px;
  border-top: 0px solid #ccc;
  padding-top: 0px;

  label {
    width: 37%;
    color: #a3a3a3;
    font-size: 10px;
    font-weight: 600;
    display: flex;
    align-items: left;
    // margin-right: 10px !important;
  }
}

.selectboxesinputmiddle {
  width: 100%;
  max-width: 100%;
  margin: 0px auto 0px;
  border-top: 0px solid #ccc;
  padding-top: 0px;

  label {
    align-items: center;
    width: 60%;
    color: black;
    font-size: 10px;
    font-weight: 400;
    display: flex;
  }
}

.diableItem {
  color: #a3a3a3 !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  font-family: unset !important;
}

.statusmargin {
  margin-left: 6px;
}

.selectdiv {
  width: 56%;
}

.filters {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.filterSelect {
  border: none;
  border-radius: 5px;
  color: #2f2f2f;
  font-size: 10px;
  height: 23px;
  background-color: #f4f4f4;
  width: 90px;
  font-weight: 500;
  font-family: unset !important;
  font-size: 12px !important;
  font-weight: 500 !important;

  &::before {
    border-bottom: none !important;
    padding: 0px !important;
  }

  &::after {
    border-bottom: none !important;
  }
}

.talentName {
  font-weight: bold;
  font-size: 10px;
}

.tdCss {
  padding-top: 10px !important;
  padding-left: 20px !important;
}

.tabelData {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}
.selectData {
  display: flex;
}

.tableContainer {
  display: flex;
  overflow-y: scroll;
  white-space: nowrap;
}
.nodataStyle {
  text-align: center;
  font-size: 12px;
  font-weight: 500 !important;
  /* padding: 20px; */
  color: #a3a3a3 !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tableContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tableContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.tablehead {
  width: 100%;

  margin-top: 7px;

  thead {
    background-color: #f4f4f4;
  }

  tbody {
    border: none !important;

    tr {
      border-bottom-width: 1px;
      border-color: #dedede;
    }

    td {
      border: none;
      vertical-align: bottom;
      // margin-top: 7px;

      h2 {
        color: #2f2f2f;
        font-size: 10px;
        font-weight: 700;
        margin-bottom: 0;
      }

      p {
        width: 114px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 10px;
        margin-bottom: 0;
        font-weight: normal;
        color: #2f2f2f !important;
      }

      .icon {
        width: 15px;
        height: 15px;
        margin-top: -5px;
        cursor: pointer;
      }

      .slider {
        background-color: #f4f4f4;
      }

      .slider:before {
        background-color: #ddd;
      }
    }
  }
}
