@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

body {
  background-color: #dedede;
}

.mainbox {
  background-color: #dedede;
  margin: auto;
  height: 100vh;
  position: relative;
}

.err {
  color: #ffffff;
  font-family: "Nunito Sans", sans-serif;
  font-size: 11rem;
  position: absolute;
  left: 37%;
  top: 8%;
}

.far {
  position: absolute;
  font-size: 8.5rem;
  left: 45%;
  top: 11%;
  color: #ffffff;
  animation: rotation 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation: rotation 2s linear infinite;
}

.err2 {
  color: #ffffff;
  font-family: "Nunito Sans", sans-serif;
  font-size: 11rem;
  position: absolute;
  right: 37%;
  top: 8%;
}

.msg {
  text-align: center;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.6rem;
  position: absolute;
  left: 30%;
  top: 45%;
  width: 40%;
}

.aTag {
  text-decoration: none;
  color: #011cf5 !important;
}

.aTag:hover {
  color: #011cf5 !important;
  text-decoration: none !important;
}
.para {
  font-size: 17px !important;
}
