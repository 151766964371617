.sideMenu {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 13px 15px 14px 14px;

  width: 186px;
  position: inherit;
  top: 0;
  height: 100%;
  margin-right: 21px;

  .navlink.active,
  .show > .navlink {
    background: transparent;

    color: #011cf5 !important;
    text-align: left;

    &::after {
      position: absolute;
      content: "";
      width: 2.2px;
      height: 22px;
      background: #011cf5 !important;
      left: -15px;
      top: 5px;
    }
  }
  .disable {
    padding: 8px 0px 8px 10px;
    text-align: left;
    color: #a3a3a3 !important;
    font-size: 12px;
    font-weight: 600;
    background-color: transparent !important;
    position: relative;
    background: none;
    border: 0;
    border-radius: 0.25rem;
    cursor: text !important;

    // &:hover {
    //   background-color: #f4f4f4 !important;
    // }
  }

  .navlink {
    padding: 8px 0px 8px 10px;
    text-align: left;
    color: #2f2f2f;
    font-size: 12px;
    font-weight: 600;
    background-color: transparent !important;
    position: relative;
    background: none;
    border: 0;
    border-radius: 0.25rem;
    cursor: pointer;

    &:hover {
      //background-color: #f4f4f4 !important;
      color: #011cf5 !important;
    }
  }
}

////////* CSS for ristrict menu*//////
.ristricSideMenu {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 13px 15px 14px 14px;
  width: 186px;
  position: inherit;
  top: 0;
  height: 100%;
  margin-right: 20px;

  .navlink.active,
  .show > .navlink {
    background: transparent;

    color: #011cf5 !important;
    text-align: left;

    &::after {
      position: absolute;
      content: "";
      width: 2.2px;
      height: 22px;
      background: #011cf5 !important;
      left: -15px;
      top: 5px;
    }
  }
  .disable {
    padding: 8px 0px 8px 10px;
    text-align: left;
    color: #a3a3a3 !important;
    font-size: 12px;
    font-weight: 600;
    background-color: transparent !important;
    position: relative;
    background: none;
    border: 0;
    border-radius: 0.25rem;
    cursor: text !important;

    // &:hover {
    //   background-color: #f4f4f4 !important;
    // }
  }

  .navlink {
    padding: 8px 0px 8px 10px;
    text-align: left;
    color: #2f2f2f;
    font-size: 12px;
    font-weight: 600;
    background-color: transparent !important;
    position: relative;
    background: none;
    border: 0;
    border-radius: 0.25rem;
    cursor: pointer;

    &:hover {
      //background-color: #f4f4f4 !important;
      color: #011cf5 !important;
    }
  }
}
