.tableHeading {
  width: 100%;
  display: flex;
  padding: 20px 20px 0px 20px;
}
.tableHeadingFont {
  font-size: 12px;
  font-weight: 500;
}
.tableHeadingDataName {
  display: flex;
  width: 30%;
  cursor: pointer;
}
.arrowStyle {
  height: 16px !important;
}

.tableHeadingSpace {
  width: 10%;
}
.tableHeadingDataSlug {
  width: 20%;
}
.tableHeadingDataCreated {
  width: 20%;
}
.tableHeadingDataStatus {
  width: 25%;
}
.tableHeadingDataActions {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 10%;
}
.tableDataOuterContainer {
  padding-left: 20px;
  padding-right: 20px;
}
.tableDataContainer {
  display: flex;
  // padding:0px 20px;
}
.tableDataNameSize {
  display: flex;
  width: 30%;
  align-items: center;
}
.tableDataSpace {
  width: 10%;
}
.tableDataSlugSize {
  width: 20%;
}
.tableDataCreatedSize {
  width: 20%;
}
.tableDataStatusSize {
  display: flex;
  align-items: center;
  text-align: center;
  width: 25%;
}
.tableDataActionsSize {
  position: relative;
  width: 10% !important;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 33px;
  width: 33px;
}
.greenDot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #b1ffba;
  // margin: 5px 0px 0px 10px;
  margin-right: 10px;
}
.blackDot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: black;
  // margin: 5px 0px 0px 10px;
  margin-right: 10px;
}
.imageRound {
  background-color: transparent;
  border-radius: 50%;
  height: 33px;
  width: 33px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #a3a3a3;
  }
}
.actionDots {
  height: 18px;
  cursor: pointer;
}
.menuItemStyle {
  padding: 8px 14px !important;
  font-size: 13px !important;
  font-family: unset !important;
}
.iconstyle {
  height: 15px;
  width: 15px;
  margin-right: 10px;
}

.cardDivider {
  margin-top: 11px;
  color: #dedede;
  margin-bottom: 8px;
}
.tableDivider {
  margin-top: 11px;
  color: #dedede;
  margin-bottom: 11px;
}
.TableDataFontStyle {
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 5px 0px 5px 0px;
}

.commenttext {
  width: 28px;
  margin-right: 10px;
  h5 {
    background-color: #0c2659;
    margin: 0;
    width: 27px;
    height: 27px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 700;
    line-height: 27px;
    // margin-right: 12px;
  }
}
.adminInitail {
  text-transform: uppercase;
}

.adminThumImage {
  object-fit: cover;
  border-radius: 50%;
  width: 27px;
  height: 27px;
}

.nameStyles {
  text-transform: capitalize;
}

.emailStyle {
  font-size: 10px !important;
  color: #a3a3a3 !important;
}

.disableText {
  color: gray !important;
}
