.dotss {
  background-color: #ffffff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  img {
    width: 69%;
    max-width: 20px;
    cursor: pointer;
  }
}

.activeDotsBack {
  background-color: #f4f4f4 !important;
}
.menuItemStyle {
  padding: 14px 14px !important;
  font-size: 13px !important;
}
.iconstyle {
  height: 17px;
  width: 17px;
  margin-right: 10px;
}
