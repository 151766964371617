.heading {
  h2 {
    color: #0c2659;
    font-size: 24px;
    font-weight: bold;
  }
}

P {
  color: #a3a3a3;
  font-size: 12px;
}

.navlink.active {
  color: #011cf5 !important;
}

.savebtn {
  background: transparent linear-gradient(90deg, #011cf5 0%, #0c2659 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000040;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  color: #fff;
  padding: 5px 25px;
  font-weight: 300;
  height: 32px;
}

.edithead {
  h1 {
    font-size: 24px;
    font-weight: bold;
    color: #0c2659;
  }

  h5 {
    font-size: 12px;
    font-weight: normal;
    color: #2f2f2f;
    // margin-bottom: 30px;
  }

  h3 {
    letter-spacing: 0px;
    color: #2f2f2f;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 18px;
  }
}

.topheading {
  padding: 20px 20px 0px 20px;
}

.billingHead {
  padding: 20px 20px 0 20px;
}

.cardDivider {
  margin-top: 11px;
  color: #dedede;
  margin-bottom: 8px;
}

.middleCard {
  padding: 0px 20px 20px 20px;
}

.divider {
  margin-top: 35px;
}

.dropzone {
  margin-top: 20px;
  // color: #a3a3a3;
  font-size: 10px;
  font-weight: 500;
  // line-height: 3;
}
