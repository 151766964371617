.middleCard {
  padding: 26px 20px;
  display: flex;
  // justify-content: end;
}
.searchInputContainer {
  width: 36%;
  position: relative;
  border-radius: 5px;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid #011cf5;
  }
}
.searchInputDataSets {
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #f4f4f4 !important;
  padding-left: 32px;
  &:focus {
    box-shadow: none !important;
    outline: none;
  }

  &::placeholder {
    color: #a3a3a3;
    opacity: 1;
  }
}
.searchIcon {
  position: absolute;
  left: 10px;
  height: 15px;
  width: 15px;
  top: 11px;
}
