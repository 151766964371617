.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
  :hover {
    background-color: #d9dffc;
  }
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background-color: #f4f4f4;
}

.slider:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background-color: #ddd;
}

input:checked + .slider {
  background-color: #011cf5 !important;

  // background-color: rgb(1, 28, 245, 0.3) !important;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ccc;
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input:checked + .round:before {
  // background-color: #011cf5 !important;
  background-color: white !important;
}
