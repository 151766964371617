.leftcontact {
  width: 47%;
  p {
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    color: #2f2f2f;
    line-height: 1.325;
  }
  h3 {
    font-size: 15px;
    font-weight: 700;
    color: #2f2f2f;
    line-height: 1.4;
  }
}
.emaildiv {
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: #011cf5;
  line-height: 1.5;
  text-transform: none !important;
  text-decoration: none !important;
  overflow: hidden;
  text-overflow: ellipsis;
  :hover {
    color: #011cf5 !important;
  }
}
.phonediv {
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: #011cf5;
  // line-height: 1.2;
  :hover {
    color: #011cf5 !important;
  }
}
