.textBlock {
  padding: 30px 0px 30px 0px;
}
.view_css {
  color: #0c2659;
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  cursor: pointer;
}

.horizontalLine {
  border-top: 1px dashed #707070;
  letter-spacing: 4px;
  background-color: white;
  margin: 0px;
}
