.rightsummary {
  width: 53%;
  margin-right: 6px;
  p {
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 500;
    color: #2f2f2f;
    // line-height: 1.2;
  }
  h3 {
    font-size: 15px;
    font-weight: bold;
    color: #0c2659;
    line-height: 1.4;
  }
}
