.overview {
  width: calc(100% - 50px);
  padding: 25px 55px 40px 50px;
}
.centerOverview {
  width: 100%;
  padding: 25px 50px 40px 50px;
}

.hasStyles {
  // search css end here
  .searchfilter {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;

    form {
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 5px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      background-color: #fff;

      input {
        width: 90%;
        border: none;
        height: 34px;
        outline: none;
        appearance: none;
        color: #2f2f2f;
        font-size: 14px;
        font-weight: 500;
        border-right: 1px solid #dedede;

        ::placeholder {
          color: #a3a3a3;
        }

        :focus-visible {
          outline: none;
          appearance: none;
        }
      }

      svg {
        color: #a3a3a3;
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }

    .filters {
      p {
        color: #011cf5;
        font-size: 14px;
        font-weight: 500;
        margin: 0 22px 0 12px;
        text-transform: capitalize;
      }

      svg {
        color: #011cf5 !important;
        width: 21px;
        height: 21px;
        margin-right: 0;
      }

      .searchbtn {
        background: transparent linear-gradient(90deg, #011cf5 0%, #0c2659 100%)
          0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000040;
        border-radius: 5px;
        border: none;
        font-size: 14px;
        color: #fff;
        padding: 5px 25px;
        font-weight: 300;
      }
    }
  }

  // search css end here
  .showingresult {
    p {
      color: #2f2f2f;
      font-size: 12px;
      margin: 0;
    }

    .btntransh {
      border: 1px solid #a3a3a3;
      border-radius: 5px;
      color: #a3a3a3;
      font-size: 10px;
      padding: 3px 15px;
      font-weight: 500;
    }

    .btnactive {
      border: 1px solid #011cf5;
      color: #011cf5;
      display: flex;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  .userdetails {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 15px;
    border: 1px solid transparent;

    h2 {
      color: #011cf5;
      font-size: 14px;
      font-weight: bold;
      margin: 0;
    }

    p {
      letter-spacing: 0px;
      color: #2f2f2f;
      font-size: 12px;
      margin: 0;
    }

    h3 {
      color: #2f2f2f;
      font-size: 14px;
      font-weight: bold;
      margin: 0;
    }

    button {
      background: #ebf6e9 0% 0% no-repeat padding-box;
      border-radius: 5px;
      color: #2f4e29;
      font-size: 12px;
      padding: 3px 15px;
      font-weight: 500;
    }

    svg {
      width: 17px;
      height: 13px;
    }

    .username {
      svg {
        color: #a3a3a3;
        width: 21px;
        height: 17px;
      }
    }
  }

  .diffclass {
    border-left: 3px solid #bf1736;
    border-radius: 5px 0px 0px 5px;

    h2 {
      color: #bf1736;
    }
  }

  .selectboxes {
    width: 50%;
    // margin-right: 15px;

    .select {
      width: 100%;

      select {
        border: 1px solid #2f2f2f;
        border-radius: 5px;
        color: #2f2f2f;
        font-size: 10px;
        height: 23px;
        background-color: transparent;
        width: 78%;
        font-weight: 500;
        padding: 0px 10px;
      }

      :focus {
        outline: none;
        box-shadow: none;
      }
    }

    :first-child {
      padding-left: 0;
    }

    :last-child {
      padding-right: 0;
    }
  }
}

.selectboxesinput {
  width: 100%;
  max-width: 100%;
  margin: 0px auto 0px;
  border-top: 0px solid #ccc;
  padding-top: 0px;

  label {
    width: 60%;
    color: #a3a3a3;
    font-size: 10px;
    font-weight: 400;
    display: flex;
    align-items: left;
    // margin-right: 10px !important;
  }

  .selectinput {
    label {
      width: 100%;
      color: #a3a3a3;
      font-size: 10px;
      font-weight: 400;
      align-items: center;

      input {
        width: 25px;
        height: 25px;
        border-radius: 10px;
        background: #dddddd 0% 0% no-repeat padding-box;
        border: none;
      }
    }
  }

  .selectborder {
    border-right: 1px solid #dedede;
  }
}

.userdetails:hover {
  box-shadow: 0px 0px 20px #0000001a;
  border: 1px solid #011cf5;
}

button:focus {
  box-shadow: none !important;
}

.active select {
  background-color: #011cf5 !important;
  color: #ffffff !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 19px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background-color: #f4f4f4;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background-color: #ddd;
}

input:checked + .slider {
  background-color: #021cf5 !important;
}

input:focus + .slider {
  box-shadow: 0 0 1px #021cf5;
}

input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

h2.searchbuss {
  color: #0c2659;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 0px;
}

h2.searchbuss span {
  font-weight: 400;
}

.selectboxesinput {
  .selectbottomnew {
    width: 60%;

    .selectbottom {
      width: 100%;

      .select {
        width: 44%;
      }

      label {
        color: #2f2f2f;
        font-weight: bold;

        svg {
          width: 28px;
          height: 28px;
          margin-left: 14px;
          background-color: #f4f4f4;
          padding: 5px;
          border-radius: 50%;

          path {
            color: #011cf5;
          }
        }
      }
    }

    h4 {
      font-size: 10px;
      font-weight: 500;
    }
  }
}

.showingright {
  img {
    width: 18px;
    height: 18px;
  }

  p {
    font-size: 10px;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.tablehead {
  margin-top: 15px;

  thead {
    background-color: #f4f4f4;

    th {
      color: #2f2f2f;
      font-size: 10px;
      font-weight: 500;
    }

    .currenttable {
      width: 25%;
      margin-right: 20px;
    }
  }

  tbody {
    border: none !important;

    td {
      border-color: #dedede;
      padding-top: 10px;
      padding-bottom: 10px;

      h2 {
        color: #2f2f2f;
        font-size: 10px;
        font-weight: 700;
        margin-bottom: 0;
      }

      p {
        font-size: 10px;
        margin-bottom: 0;
      }

      select {
        background-color: #f4f4f4;
        padding: 3px 3px 3px 8px;
        font-size: 10px;
        font-weight: bold;
      }

      .select {
        margin-right: 20px;
      }

      svg {
        width: 18px;
        height: 18px;
        margin-top: -5px;

        path {
          fill: #011cf5;
        }
      }

      .slider {
        background-color: #f4f4f4;
      }

      .slider:before {
        background-color: #ddd;
      }

      .selectcolor {
        select {
          background-color: #b1ffba;
        }
      }
    }
  }
}

.selected {
  background-color: #8989890f;
}

.table > :not(:first-child) {
  border-top: 0px solid currentColor !important;
}

.widthselect .select {
  width: 150px;
}

.selectboxesnewss {
  width: 100% !important;

  .select {
    padding-right: 0 !important;
  }
}

.activelogs {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      padding-bottom: 25px;
      position: relative;
      &:after {
        position: absolute;
        left: 12px;
        bottom: -12px;
        background-color: #dedede;
        content: "";
        width: 1px;
        height: 50px;
      }
      &:last-child {
        &:after {
          width: 0;
        }
      }

      .activelogsright {
        border-radius: 5px;
        background-color: #f4f4f4;
        padding: 10px 15px;
        margin-left: 15px;
        width: 70%;
        p {
          color: #2f2f2f;
          font-size: 8px;
          margin: 0;
          span {
            font-weight: 700;
          }
          .time {
            width: 8px;
            height: 8px;
          }
        }
        h2 {
          color: #2f2f2f;
          font-size: 10px;
          font-weight: 500;
          margin: 2px 0;
          span {
            font-weight: 700;
          }
        }
      }
      .activelogsleft {
        display: flex;
        align-items: center;
        justify-content: center;
        .activelogsimg {
          // padding: 9px 8px 8px 9px;
          background-color: #b1ffba;
          width: 26px;
          height: 26px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 10px;
            height: 10px;
          }
        }
        .activelogsimgcolor {
          background-color: #dedede;
        }
      }
    }
  }
}

.talentdetails {
  border-radius: 5px;
  background-color: #fff;
  width: 60%;
  margin-right: 20px;
  padding: 20px;

  .detailsimg {
    h6 {
      background-color: #0c2659;
      color: #fff;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 50%;
      margin: 0 15px 0 0;
    }
  }

  .detailscontent {
    .detailsheading {
      h2 {
        color: #0c2659;
        font-size: 18px;
        font-weight: bold;
        margin: 0;
      }

      p {
        color: #2f2f2f;
        font-size: 12px;
        margin: 0;

        span {
          font-weight: 600;
        }
      }
    }

    h3 {
      color: #0c2659;
      font-size: 14px;
      font-weight: bold;
      margin: 20px 0 10px;
    }

    h4 {
      color: #2f2f2f;
      font-size: 12px;
      font-weight: 700;
    }

    p {
      color: #2f2f2f;
      font-size: 12px;
      margin: 0;
    }

    .boxx {
      border-radius: 5px;
      background-color: #f4f4f4;
      padding-left: 10px;
      padding-top: 15px;
      padding-bottom: 15px;

      margin-right: 12px;
      margin-bottom: 15px;
      margin-top: 10px;
      flex: 0 0 55%;

      h5 {
        color: #2f2f2f;
        font-size: 12px;
        margin: 0;
        font-weight: 700;
      }

      p {
        color: #a3a3a3;
        font-weight: 500;
      }

      h1 {
        color: #2f2f2f;
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 0;
        padding-top: 5px;
      }
    }
  }
}

.deletetext {
  color: #bf1736;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  margin-right: 25px;
  text-decoration: underline;
  cursor: pointer;
  padding: 10px 10px !important;
  border-radius: 5px !important;
  &:hover {
    background-color: #bf173612 !important;
  }
}
