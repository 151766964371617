.dropButton {
  background-color: #ffffff;
  font-weight: bold;
  color: #011cf5;
  border: none;
  font-size: 12px;
  width: 108px;
  height: 24px;
  border-radius: 4px;
}
