@import "../../../../styles/variables.scss";

.clientdetail {
  margin-top: 20px;
}
.head {
  color: #2f2f2f;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}
.personName {
  color: $secondaryColor;
  font-size: 12px;
  font-weight: normal;
  margin: 0;
  line-height: 1.4;
}

.personDesignation {
  color: $secondaryColor;
  font-size: 12px;
  font-weight: normal;
  margin: 0;
  line-height: 1.4;
}

.personEmail {
  color: $primaryColor;
  font-size: 12px;
  font-weight: 500;
  display: block;
  line-height: 1.4;
}

.personPhone {
  color: $primaryColor;
  font-size: 12px;
  font-weight: 500;
  display: block;
  line-height: 1.4;
}
