.mainContainer {
  border-top: 0px solid #011cf5;
  font-family: "Public Sans", sans-serif !important;
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-repeat: no-repeat;
  background-size: cover;
  --tw-bg-opacity: 1;
  background-color: rgb(12 38 89 / var(--tw-bg-opacity));
  border-top-width: 8px;
  min-height: 100vh;
  position: relative;
  margin: 0;
  line-height: inherit;
  background-image: url("../../../../public//images/background.png");
}
.middleBox {
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100vh - 138px);
  display: flex;
  position: relative;
  align-items: center;
}
.headingContainer {
  --tw-text-opacity: 1;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
  text-align: center;
  font-family: "Inter", sans-serif;
  width: 1050px;
}
.headingtext {
  font-size: 26px !important;
  // font-weight: 500 !important;
  color: #ffffff;
  text-align: center;
  font-weight: bold;

  margin-top: 32px !important;
  margin-bottom: 3px !important;
}
.headingPara {
  margin: 0px !important;

  color: #ffffff;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500 !important;
}
.formContainer {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  // margin-top: 49px;
  flex-direction: column;
  width: 50%;
}
.inputContainer {
  width: 100%;
}
.inputStyles {
  padding: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 100%;
  border-radius: 0.375rem;
  outline: 0;
  background: #dedede;
  color: #2f2f2f;
  border: none;

  &:focus-visible {
    border: none;
    outline: none;
    background-color: #ffffff;
  }
}

.passwordContainer {
  position: relative;
  width: 100%;
}
.passwordInput {
  // margin-top: 3px;
  padding: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 100%;
  border-radius: 0.375rem;
  outline: 0;
  background: #dedede;
  color: #2f2f2f;
  border: none;

  &:focus-visible {
    border: none;
    outline: none;
    background-color: #ffffff;
  }
}
.svgStyles {
  cursor: pointer;
  position: absolute;
  top: 46%;
  --transform-translate-y: 50%;
  right: 16px;
  height: 25px;
}
.button {
  background-color: #000000;
  display: flex;
  padding: 1rem;

  color: #ffffff;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 0.375rem;
  border: none;

  &:hover {
    background-color: white !important;
    color: black !important;
  }
}

.forgetContainer {
  margin-top: 15px !important;
  color: #ffffff;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: right;
  font-weight: 500;
}

.forgetText {
  border-bottom-width: 1px;
  border-color: transparent;
  cursor: pointer;

  &:hover {
    color: white !important;
    border-color: #ffffff !important;
    text-decoration: underline !important;
  }
}

.arrowStyles {
  padding-left: 10px;
  cursor: pointer;
}

.labelContainer {
  margin-top: 4px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.errorMsg {
  color: #bf1736;
  font-size: 12px;
}

.backArrow {
  transform: rotate(180deg);
  margin-bottom: 2px;
  margin-right: 3px;
}
