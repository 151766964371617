@import "../../../styles//variables.scss";

.tablehead {
  th {
    letter-spacing: 0px;
    color: $secondaryColor;
    font-weight: 500;
    font-size: 12px;
  }

  td {
    border: none;
    color: $secondaryColor;
    font-weight: 400;
    font-size: 12px;

    img {
      width: 100%;
      max-width: 22px;
      text-align: left;
    }
  }

  thead {
    background: #f4f4f4 0% 0% no-repeat padding-box;
  }

  tbody {
    border: none !important;
  }

  tr {
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
  }
}
.fileNameTh {
  width: 40%;
}

.documentName {
  display: flex;
  justify-content: start;
  align-items: start;
}
.tableData {
  padding: 0px !important;
}
.tableLinkConatiner {
  padding: 0.7rem 0.5rem !important;
  display: flex;

  img {
    margin-left: 3px;
    height: 13px;
  }
}

.td2 {
  width: 17%;
}
.td3 {
  width: 14%;
}
.uploadedByStyles {
  width: 17%;
}
.actionHead {
  width: 15%;
}

.bottomStyles {
  font-size: 10px;
  color: #a3a3a3;
}
.byStyles {
  font-size: 10px;
  color: #a3a3a3;
}
.dossierFileName {
  width: 30%;
}
.dossierUploadedBy {
  width: 19%;
}
.thirdDiv {
  width: 14%;
}
.fourthDiv {
  width: 15%;
}

.noDataContainer {
  text-align: center;
}
.dataNotFound {
  text-align: center;
  font-size: 12px;
  font-weight: 500 !important;
  color: #a3a3a3 !important;
}
