@import "../../../../styles/variables.scss";
.navitem {
  margin-top: 6px;
  position: relative;
  color: #fff;
  font-size: 12px;
  padding: 0 5px;
}

.bellStyles {
  cursor: pointer;
  height: 30px;
  width: 29px;
  padding: 5px 5px 5px 5px !important;
  border-radius: 100%;

  &:hover {
    background: rgba(255, 255, 255, 0.148);
  }
}

.bellIcon {
  cursor: pointer;
  height: 19px;
}
.bellNotifyIcon {
  display: flex;
  padding: 0px 3px 0px 3px;
  position: absolute;
  background: #011cf5;
  top: -3px;
  width: 57%;
  height: 59%;
  line-height: 17px;
  border-radius: 50px;
  font-size: 9px;
  color: #f4f4f4;
  font-weight: 500;
  left: 16px;
  white-space: nowrap;
  justify-content: center;
  cursor: pointer;
}
