@import "../../../styles/variables.scss";

.overview {
  width: calc(100% - 50px);
  padding: 25px 55px 40px 50px;
}
.centerOverview {
  width: 100%;
  padding: 25px 50px 40px 50px;
}

.hasStyles {
  .bgprofileleft {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 13px 15px 14px 14px;

    width: 186px;
    position: inherit;
    top: 0;
    height: 100%;
    margin-right: 21px;
    .navlink.active,
    .show > .navlink {
      background: transparent;

      color: $primaryColor !important;
      text-align: left;

      &::after {
        position: absolute;
        content: "";
        width: 2.2px;
        height: 22px;
        background: $primaryColor !important;
        left: -15px;
        top: 5px;
      }
    }

    .navlink {
      padding: 7px 0px 10px 10px;
      text-align: left;
      color: $secondaryColor !important;
      font-size: 12px;
      font-weight: 600;
      background-color: transparent !important;
      position: relative;
      background: none;
      border: 0;
      border-radius: 0.25rem;
    }

    .tagbtn {
      border-radius: 5px;
      background-color: #f4f4f4;
      color: $secondaryColor;
      font-size: 10px;
      font-weight: 500;
      padding: 2px 7px;
      margin-right: 5px;
      display: inline-block;
      margin-bottom: 3px;
      margin-top: 3px;
      line-height: 1.1;
    }

    h4 {
      font-size: 12px;
      margin: 1px 0 0;
      text-transform: capitalize;
      font-weight: bold;
    }
  }

  .talentdetails {
    border-radius: 5px;
    background-color: #fff;
    width: 60%;
    margin-right: 20px;
    padding: 20px;

    .detailsimg {
      h6 {
        background-color: #0c2659;
        color: #fff;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 50%;
        margin: 0 15px 0 0;
      }
    }

    .detailscontent {
      .detailsheading {
        h2 {
          color: #0c2659;
          font-size: 18px;
          font-weight: bold;
          margin: 0;
        }

        p {
          color: $secondaryColor;
          font-size: 12px;
          margin: 0;
          display: flex;

          span {
            font-weight: 600;
          }
        }
      }

      h3 {
        color: #0c2659;
        font-size: 14px;
        font-weight: bold;
        margin: 24px 0 10px;
      }

      h4 {
        color: $secondaryColor;
        font-size: 12px;
        font-weight: 700;
      }

      p {
        color: $secondaryColor;
        font-size: 12px;
        margin: 0;
      }

      .boxx {
        border-radius: 5px;
        background-color: #f4f4f4;
        padding-left: 10px;
        padding-top: 15px;
        padding-bottom: 15px;

        margin-right: 12px;
        margin-bottom: 15px;
        margin-top: 10px;
        flex: 1;

        h5 {
          color: $secondaryColor;
          font-size: 12px;
          margin: 0;
          font-weight: 700;
        }

        p {
          color: #a3a3a3;
          font-weight: 500;
        }

        h1 {
          color: $secondaryColor;
          font-size: 36px;
          font-weight: 600;
          margin-bottom: 0;
          padding-top: 5px;
        }
      }
    }
  }

  .rightdetails {
    width: 30%;
    position: relative;
  }
}
.greenContainer {
  border-radius: 5px;
  background-color: #ebf6e9;
  color: #2f4e29;
  font-size: 12px;
  padding: 2px 7px;
  margin-right: 5px;
  display: inline-block;
  font-weight: 500;
  margin-bottom: 3px;
  margin-top: 3px;
}
.simple {
  border-radius: 5px;
  background-color: #f4f4f4;
  color: $secondaryColor;
  font-size: 12px;
  padding: 2px 7px;
  margin-right: 5px;
  display: inline-block;
  margin-bottom: 3px;
  margin-top: 3px;
  font-weight: 500;
}
.clientRightBox {
  padding-left: 0px;
  text-align: left;
  color: $secondaryColor !important;
  font-size: 12px;
  font-weight: 500;
  background-color: transparent !important;
  position: relative;
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.btntag {
  span {
    background-color: #f4f4f4;
    color: $secondaryColor;
  }

  h4 {
    margin-top: 0;
    padding-bottom: 2px;
  }
}

.sideClientText {
  line-height: 14px;
  line-break: anywhere;
  padding-left: 0px;
  text-align: left;
  color: #2f2f2f !important;
  font-size: 12px;
  font-weight: 500;
  background-color: transparent !important;
  position: relative;
  background: none;
  border: 0;
  border-radius: 0.25rem;
  padding-top: 4px;
}
