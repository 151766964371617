@import "../../../styles/variables.scss";

.details {
  width: 98%;

  .commenttext {
    h5 {
      background-color: #0c2659;
      margin: 0;
      width: 27px;
      height: 27px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-size: 12px;
      font-weight: 700;
      line-height: 27px;
      margin-right: 12px;
    }
  }
  .detailscontent {
    .detailsheading {
      h2 {
        text-align: left;
        padding-left: 0;
        padding-top: 0;
        font-size: 12px;
      }
    }
  }
}

.detailsheading {
  p {
    color: $secondaryColor;
    font-size: 12px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 16px;
    .replyTextArea {
      display: flex;
    }
  }
  .reply {
    color: #a3a3a3;
    font-size: 8px;
    font-weight: bold;
  }
  .replyText {
    color: $primaryColor;
    font-weight: bold;
    margin-right: 5px;
    font-size: 10px;
    text-decoration: underline;
    cursor: pointer;
  }
  .slash {
    color: #a3a3a3;
    font-weight: 600;
    margin-right: 5px;
    font-size: 10px;
  }
  .secondText {
    color: #a3a3a3;
    font-weight: 600;
    margin-right: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.comments {
  width: 100%;
}

.divider {
  margin-top: -8px !important;
  color: #707070;
  opacity: 0.1;
}

.replyBox {
  border-left: 1px solid #f1f1f1;
}
.adminThumImage {
  object-fit: cover;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  margin-right: 12px;
}
