.overview {
  width: calc(100% - 50px);
  padding: 30px 40px;
}
.topheading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0 20px;
}

.heading {
  border-bottom: 1px solid #dedede;
  height: 110px;
  border-radius: 5px;
  background-color: #fff;
  // background-image: url("../../../public/images/login/bg_header.svg");
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 28px;
  color: #ffffff;
  padding: 40px 10px 10px 17px;
  // border-bottom: 5px solid #011cf5;
}
.textCapital {
  text-transform: capitalize;
}

.bgprofileleft {
  padding-bottom: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  // padding: 20px;
  width: 196px;
  position: sticky;
  top: 0;
  height: 100%;

  .navlink.active,
  .show > .navlink {
    background: transparent;
    color: #011cf5;
    text-align: left;
  }

  .navlink {
    padding: 8px 19px;
    text-align: left;
    color: #2f2f2f !important;
    font-size: 12px;
    font-weight: 500;
    background-color: transparent !important;
  }
}
.userimg {
  padding: 12px 22px 5px 20px;

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
  }
}
.navlink.active {
  color: #011cf5 !important;
}

.externalLinkImage {
  margin-left: 5px;
}
.tabcontent {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 100%;
  // padding: 20px;
}

.h3heading {
  letter-spacing: 0px;
  color: #2f2f2f;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.middleCard {
  padding: 0px 20px 0 20px;
}
.cardDivider {
  margin-top: 11px;
  color: #dedede;
  margin-bottom: 8px;
}
.bottomDivider {
  margin-top: 49px;
  color: #dedede;
  margin-bottom: 0px;
}
.bottomFotter {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 20px 20px 32px 20px;
}
.adminName {
  font-weight: bold;
}
.middleContainer {
  // padding-left: 40px !important;
  padding-right: 40px !important ;
}
.imageRowHeading {
  padding-left: 40px !important;
  padding-right: 40px !important ;
}
