.textBlock {
  padding: 30px 0px 30px 0px;
}
.currencyBoxContainer {
  width: 100%;
}
.horizontalLine {
  border-top: 1px dashed #707070;
  letter-spacing: 4px;
  background-color: white;
  margin: 0px;
}
@media screen and (min-width: 1150px) and (max-width: 1250px) {
  .currencyBoxContainer {
    width: 100%;
  }
}
@media screen and (min-width: 1251px) {
  .currencyBoxContainer {
    display: flex;
  }
}
