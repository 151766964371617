@mixin baseButton {
  background: transparent linear-gradient(90deg, #011cf5 0%, #0c2659 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000040;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  color: #fff;
  padding: 4px 20px;
  font-weight: 400;
}

@mixin rightContainer {
  width: 26.4%;
  position: relative;
}

@mixin middleContainer {
  border-radius: 5px;
  background-color: #fff;
  width: 55.3%;
  margin-right: 20px;
  padding-bottom: 20px;
}

@mixin ristricMiddleContainer {
  border-radius: 5px;
  background-color: #fff;
  // min-width: 710px;
  width: 56.7%;
  margin-right: 20px;
  padding-bottom: 20px;
}
