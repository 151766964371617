.tabcontent {
  min-height: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 100%;
  // padding: 20px;
  // padding-bottom: 15%;
}
.topheading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 20px 20px 0 20px;
}
.h3heading {
  letter-spacing: 0px;
  color: #2f2f2f;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.topCardDivider {
  margin-top: 11px;
  color: #dedede;
  margin-bottom: 8px;
}
.middleCard {
  padding: 20px 20px 20px 20px;
  // display: flex;
  justify-content: end;
}
.bottomDivider {
  margin-top: 0px;
  color: #dedede;
  margin-bottom: 0px;
}

.eyeViewHide {
  height: 25px;
  width: 25px;
  top: 34px;
  right: 12px;
  position: absolute;
  cursor: pointer;
}

.tableHeading {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.empty {
  width: 18%;
  font-size: 12px !important;
  font-weight: 500;
}
.AddEdit {
  text-align: center;
}
.view {
  text-align: center;
}
.tableStyle {
  width: 30% !important;
}

.accessHeading {
  font-weight: 700;
  margin-top: 6px;
  font-size: 12px;
  margin-right: 10px;
  width: 201px;
}

.mainHeadding {
  display: flex;
  align-items: center;
}
.module {
  font-size: 12px;
}
.tableHeading {
  width: 100%;
  font-size: 12px;
}
.labelHeading {
  background-color: red;
  width: 60%;
}
.addEdit {
  background-color: green;
  width: 20%;
}
.view {
  width: 20%;
}

.tableContainer {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  width: 70%;
}

.mainHeading {
  display: flex;
  width: 41%;
  align-items: center;
}
.addEditStyles {
  width: 19.6%;
}
.viewStyles {
  width: 19.6%;
}

.selectAllStyles {
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;
}

.accessHeader {
  padding: 0px 20px 24px 20px;
}

.accessCard {
  padding: 0px 20px 20px 20px;
  justify-content: end;
}
