.filterContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 13px;
}
.rightContainer {
  width: 40%;
}
.filterLabel {
  color: #2f2f2f;
  font-size: 10px;
  font-weight: 600;
  white-space: nowrap;
  margin-right: 7px;
}
.statusSelect {
  border: none;
  border-radius: 5px;
  color: #2f2f2f !important;
  font-size: 10px;
  height: 23px;
  background-color: #f4f4f4;
  width: 67%;
  font-weight: 500;
  font-family: unset !important;
  font-size: 12px !important;
  font-weight: 500 !important;

  &::before {
    border-bottom: none !important;
    padding: 0px !important;
  }

  &::after {
    border-bottom: none !important;
  }
}
.selectItem {
  padding: 5px 10px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  font-family: unset !important;
}

.leftContainer {
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mraginLeft {
  margin-left: 15px;
}
