.navitem {
  margin-top: 6px;
  position: relative;
  color: #fff;
  font-size: 12px;
  padding: 0 5px;
}
.pointer {
  cursor: pointer;
}
.dropdowntoggle {
  cursor: pointer;
  background: #011cf5;
  border-radius: 50%;
  color: white;
  width: 30px;
  height: 30px;
  font-size: 13px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
.adminImage {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.dropContainer {
  padding-top: 2px;
  width: 26px;
  height: 30px;
}
