.text {
  color: #2f2f2f;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0;
}

.user {
  text-align: center;
  cursor: pointer;
  // :hover {
  //   color: #011cf5 !important;
  // }
}

.disabletext {
  color: lightgray !important;
}

.hoverEffect {
  color: #011cf5;
}
.offLimitActive {
  color: #bf1736;
}
