.tabcontent {
  min-height: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 100%;
  padding-bottom: 15%;
}

.h3heading {
  letter-spacing: 0px;
  color: #2f2f2f;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.topCardDivider {
  margin-top: 19px;
  color: #dedede;
  margin-bottom: 8px;
}

.middleCard {
  padding: 26px 20px;
  display: flex;
  // justify-content: end;
}
.searchInputContainer {
  width: 36%;
  position: relative;
  border: 1px solid transparent;
  border-radius: 5px;

  &:hover {
    border-radius: 5px;
    border: 1px solid #011cf5;
  }
  &:focus {
    box-shadow: 0px 0px 0px 10px rgb(0 0 0 / 16%) !important;
  }
}

.searchInputDataSets {
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #f4f4f4 !important;
  padding-left: 32px;
  &:focus {
    box-shadow: none !important;
    outline: none;
  }

  &::placeholder {
    color: #a3a3a3;
    opacity: 1;
  }
}
.searchIcon {
  position: absolute;
  left: 10px;
  height: 15px;
  width: 15px;
  top: 11px;
}
.paginationContiner {
  padding: 10px 20px 10px 20px;
}

.topheading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 20px 1px 20px;
}

.h3heading {
  letter-spacing: 0px;
  color: #2f2f2f;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.cardDivider {
  margin-top: 11px;
  color: #dedede;
  margin-bottom: 8px;
}

.addTalentUser {
  margin-right: 6px;
  width: 13px;
  height: 13px;
  margin-bottom: 2px;
}
.hidbutton {
  visibility: hidden !important;
}
