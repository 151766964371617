.searchContainer {
  overflow: scroll;
  position: absolute;
  z-index: 1;
  // height: 140px;
  position: absolute;
  z-index: 1;
  background-color: #ffff;
  margin-left: 33px;
  margin-top: 4px;
  border-radius: 5px;
  width: 324px;
  max-height: 184px;
  box-shadow: 0 10px 30px 0 rgb(0 0 0 / 16%);
  // border: solid 0.5px #dedede;
}
.searchRow {
  display: flex;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #d9dffc;
  }
}
.commenttext {
  display: flex;

  width: 28px;
  margin-right: 9px;
  h5 {
    background-color: #0c2659;
    margin: 0;
    width: 27px;
    height: 26px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 700;
    line-height: 27px;
    // margin-right: 12px;
  }
}
.adminThumImage {
  object-fit: cover;
  border-radius: 50%;
  width: 27px;
  height: 27px;
}
.adminInitail {
  text-transform: uppercase;
}
.nameStyles {
  text-transform: capitalize;
  font-size: 12px !important;
  line-height: 14px;
}
.emailStyle {
  font-size: 10px !important;
  color: #a3a3a3 !important;
}
.searchDivider {
  margin: 0px 0px !important;
  color: #dedede !important;
}
