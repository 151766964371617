.yellow-dot {
  width: 10px;
  height: 10px;
  border-radius: 4px;
  background-color: #ffcd46;
  margin: 0px 0px 0px 10px;
}
.green-dot {
  width: 10px;
  height: 10px;
  border-radius: 4px;
  background-color: #b1ffba;
  margin: 0px 0px 0px 10px;
}
.red-dot {
  width: 10px;
  height: 10px;
  border-radius: 4px;
  background-color: #bf1736;
  margin: 0px 0px 0px 10px;
}
