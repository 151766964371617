.newAddButton {
  color: #2f2f2f;
  border: 1px solid #2f2f2f;
  border-radius: 5px;
  font-size: 12px;
  background: transparent;
  font-weight: 500;
  padding: 5px 15px 5px 12px;
  align-items: center;
  display: flex;
  justify-content: center;

  img {
    max-width: 18px;
    margin-right: 5px;
  }

  &:hover {
    border: 1px solid #011cf5 !important;
    color: #011cf5 !important;
  }
}
.activeBtn {
  border: 1px solid #011cf5 !important;
  color: #011cf5 !important;
}
