.overview {
  width: calc(100% - 80px);
  padding: 26px 50px 30px 50px;
}
.centerOverview {
  width: 100%;
  padding: 26px 50px 30px 50px;
}

.hasStyles {
  // search css end here
  .searchfilter {
    width: 100%;
    max-width: 85%;
    margin: 0 auto;
    form {
      padding-top: 5px;
      padding-bottom: 5px;
      // border-radius: 5px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      background-color: #fff;
      border: none;
      input {
        width: 90%;
        border: none;
        height: 34px;
        outline: none;
        appearance: none;
        color: #2f2f2f;
        font-size: 14px;
        font-weight: 500;
        border-right: 1px solid #dedede;

        &::placeholder {
          color: #a3a3a3;
        }

        :focus-visible {
          outline: none;
          appearance: none;
        }
      }

      svg {
        color: #a3a3a3;
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }

    .filters {
      p {
        color: #011cf5;
        font-size: 14px;
        font-weight: 500;
        margin: 0 22px 0 12px;
        text-transform: capitalize;
      }

      svg {
        color: #011cf5 !important;
        width: 21px;
        height: 21px;
        margin-right: 0;
      }

      .searchbtn {
        background: transparent linear-gradient(90deg, #011cf5 0%, #0c2659 100%)
          0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000040;
        border-radius: 5px;
        border: none;
        font-size: 14px;
        color: #fff;
        padding: 5px 25px;
      }
    }
  }

  // search css end here
  .showingresult {
    p {
      font-weight: 500;
      color: #2f2f2f;
      font-size: 12px;
      margin: 0;
    }

    .btntransh {
      border: 1px solid #a3a3a3;
      border-radius: 5px;
      color: #a3a3a3;
      font-size: 10px;
      padding: 3px 15px;
      font-weight: 500;
    }

    .showingright {
      img {
        margin-left: 3px;
        width: 18px;
        height: 18px;
      }
    }

    .btnactive {
      border: 1px solid #011cf5;
      color: #011cf5;
      display: flex;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  .userdetails {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 15px;
    border: 1px solid transparent;

    h2 {
      color: #011cf5;
      font-size: 14px;
      font-weight: bold;
      margin: 0;
    }

    p {
      letter-spacing: 0px;
      color: #2f2f2f;
      font-size: 12px;
      margin: 0;
    }

    h3 {
      color: #2f2f2f;
      font-size: 14px;
      font-weight: bold;
      margin: 0;
    }

    button {
      background: #ebf6e9 0% 0% no-repeat padding-box;
      border-radius: 5px;
      color: #2f4e29;
      font-size: 12px;
      padding: 3px 15px;
      font-weight: 500;
    }

    svg {
      width: 17px;
      height: 13px;
    }

    .username {
      svg {
        color: #a3a3a3;
        width: 21px;
        height: 17px;
      }
    }
  }

  .diffclass {
    border-left: 3px solid #bf1736;
    border-radius: 5px 0px 0px 5px;

    h2 {
      color: #bf1736;
    }
  }

  .selectboxes {
    width: 100%;
    max-width: 85%;
    margin: 0 auto;
    .select {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;

      select {
        border: 1px solid #2f2f2f;
        border-radius: 5px;
        color: #2f2f2f;
        font-size: 10px;
        height: 32px;
        background-color: transparent;
        width: 100%;
        font-weight: 500;
        padding: 0px 10px;
      }

      :focus {
        outline: none;
        box-shadow: none;
      }
    }

    :first-child {
      padding-left: 0;
    }

    :last-child {
      padding-right: 0;
    }
  }
}

.selectboxesinput {
  width: 100%;
  max-width: 85%;
  margin: 5px auto 0px;
  border-top: 1px solid #ccc;
  padding-top: 15px;
  .selectinput {
    label {
      color: #2f2f2f;
      font-size: 12px;
      font-weight: 400;
      display: flex;
      align-items: center;

      input {
        width: 25px;
        height: 25px;
        border-radius: 10px;
        background: #dddddd 0% 0% no-repeat padding-box;
        border: none;
      }
    }
  }
  .selectborder {
    border-right: 1px solid #dedede;
  }
}

.userdetails:hover {
  box-shadow: 0px 0px 20px #0000001a;
  border: 1px solid #011cf5;
}

button:focus {
  box-shadow: none !important;
}
.active select {
  background-color: #011cf5 !important;
  color: #ffffff !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #021cf5;
}

input:focus + .slider {
  box-shadow: 0 0 1px #021cf5;
}

input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.socialIcon {
  height: 16px;
  width: 16px;
  cursor: pointer;
  margin-right: 4px;
}
.searchIcon {
  height: 16px;
  width: 16px;
}

.tabButton {
  background-color: #fff;
  border-radius: 5px;

  border: none;
  font-size: 12px;
  padding: 10px 10px 10px 10px;
}

.tabColumn {
  height: 36px;
}
.navItem {
  width: 111px;
  text-align: center;
  font-size: 14px;
  margin-right: 10px;
  // border: 1px solid transparent;
  // &:hover {
  //   border: 1px solid #011cf5;
  //   border-radius: 6px;
  // }
}
.inActiveButton {
  background-color: #f4f4f4 !important;
  color: #2f2f2f !important;
  border: 1px solid #dee2e6 !important;
  font-size: 12px !important;
  font-weight: normal !important;
}
.activeButtonCss {
  color: #011cf5 !important;
  font-size: 12px !important;
  font-weight: bold !important;
}
.container {
  margin-top: 1rem !important;
}

.activeTabStyles {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1) !important;
}

.defaultSelect {
  padding: 3px 0px 3px 10px;
  &:hover {
    border-radius: 5px;
    // background-color: #e7e9fe;
  }
}

.test {
  // width: 100%;
  // max-width: 85%;
  margin: 0px auto 0px;
}
