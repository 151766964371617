@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.headContainer {
  display: flex;
  width: 100%;
}
.middleContainer {
  display: flex;
  width: 70%;
}
.leftConatainer {
  display: flex;
  align-items: flex-start;
  width: 12%;
}

.talentUserImage {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin: 0 12px 0 0;
  object-fit: cover;
  border: 1px solid #f4f4f4;
}
.detailscontent {
  width: 100% !important;

  .detailsheading {
    h2 {
      color: #0c2659;
      font-size: 18px;
      font-weight: bold;
      margin: 0;
      text-transform: capitalize;
    }

    p {
      color: $secondaryColor;
      font-size: 12px;
      margin: 0;
      display: flex;
      font-weight: 500;
      span {
        font-weight: 600;
      }
    }
  }

  h3 {
    color: #0c2659;
    font-size: 14px;
    font-weight: bold;
    margin: 32px 0 10px;
  }

  h4 {
    color: $secondaryColor;
    font-size: 12px;
    font-weight: 700;
  }

  p {
    color: $secondaryColor;
    font-size: 12px;
    margin: 0;
    // line-height: 1.625;
    font-weight: 400;
  }

  .boxx {
    border-radius: 5px;
    background-color: #f4f4f4;
    padding: 18px;
    margin-right: 12px;
    margin-bottom: 15px;
    margin-top: 10px;
    flex: 1;

    h5 {
      color: $secondaryColor;
      font-size: 12px;
      margin: 0;
      font-weight: 500;
    }

    p {
      padding-bottom: 4px;
      color: #a3a3a3;
      font-weight: 500;
      font-size: 10px;
    }

    h1 {
      color: $secondaryColor;
      font-size: 34px;
      font-weight: 600;
      margin-bottom: 0;
      padding-top: 0px;
      white-space: nowrap;
    }
  }
}
.workStyle {
  width: 100%;
  display: flex;
}

.jobStyle {
  color: $secondaryColor !important;
  font-size: 12px !important;
  margin: 0 !important;
  display: block !important;
  font-weight: 500 !important;
  span {
    font-weight: 600 !important;
  }
}
.cityHeading {
  color: $secondaryColor;
  font-weight: 600;
  margin-right: 2px;
  font-size: 12px;
}
.detailsicons {
  h5 {
    color: #969292;
    font-size: 11px;
    margin: 0;
    font-weight: 900;
  }
}

.rightConatainer {
  width: 30%;
  display: flex;
  justify-content: end;
}
