.overview {
  width: calc(100% - 80px);
  padding: 26px 50px 30px 50px;
}
.centerOverview {
  width: 100%;
  padding: 26px 50px 30px 50px;
}
.hasStyles {
  // search css end here
  .searchfilter {
    width: 100%;
    max-width: 85%;
    margin: 5px auto 0px;

    form {
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 5px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      background-color: #fff;

      input {
        width: 90%;
        border: none;
        height: 34px;
        outline: none;
        appearance: none;
        color: #2f2f2f;
        font-size: 14px;
        font-weight: 500;
        border-right: 1px solid #dedede;

        ::placeholder {
          color: #a3a3a3;
        }

        :focus-visible {
          outline: none;
          appearance: none;
        }
      }

      svg {
        color: #a3a3a3;
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }

    .filters {
      p {
        color: #011cf5;
        font-size: 14px;
        font-weight: 500;
        margin: 0 22px 0 12px;
        text-transform: capitalize;
      }

      svg {
        color: #011cf5 !important;
        width: 21px;
        height: 21px;
        margin-right: 0;
      }

      .searchbtn {
        background: transparent linear-gradient(90deg, #011cf5 0%, #0c2659 100%)
          0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000040;
        border-radius: 5px;
        border: none;
        font-size: 14px;
        color: #fff;
        padding: 5px 25px;
      }
    }
  }

  // search css end here
  .showingresult {
    p {
      font-weight: 500;
      color: #2f2f2f;
      font-size: 12px;
      margin: 0;
    }

    .showingright {
      img {
        margin-left: 3px;
        width: 18px;
        height: 18px;
      }
    }
  }

  .clientpage {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #0000001a;
    padding: 20px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 20px;

    .clienthead {
      h3 {
        color: #011cf5;
        font-size: 24px;
        font-weight: bold;
        margin: 0;
      }

      p {
        color: #2f2f2f;
        font-size: 12px;
        font-weight: normal;
        margin: 0;
      }

      h4 {
        color: #2f2f2f;
        font-size: 14px;
        font-weight: bold;
        margin: 0;
      }

      a {
        color: #011cf5;
        font-size: 12px;
        font-weight: 500;
        display: block;
      }

      img {
        width: 100%;
        max-width: 60px;
        margin-top: 10px;
      }
    }

    .clienbig {
      border-left: 1px solid #dedede57;
      border-right: 1px solid #dedede57;
      padding-left: 20px;
      margin-right: 20px;
      padding-right: 10px;
    }
  }
}

.searchContainer {
  overflow: scroll;
  position: absolute;
  z-index: 1;
  // height: 140px;
  position: absolute;
  z-index: 1;
  background-color: #ffff;
  margin-left: 49px;
  margin-top: 4px;
  border-radius: 5px;
  width: 324px;
  max-height: 185px;
  box-shadow: 0 10px 30px 0 rgb(0 0 0 / 16%);
  // border: solid 0.5px #dedede;
}
.searchRow {
  display: flex;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #d9dffc;
  }
}
.commenttext {
  display: flex;

  width: 28px;
  margin-right: 9px;
  h5 {
    background-color: #0c2659;
    margin: 0;
    width: 27px;
    height: 26px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 700;
    line-height: 27px;
    // margin-right: 12px;
  }
}
.adminThumImage {
  object-fit: cover;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  border: 1px solid #f4f4f4;
}
.adminInitail {
  text-transform: uppercase;
}
.nameStyles {
  text-transform: capitalize;
  font-size: 12px !important;
  line-height: 14px;
}

.emailStyle {
  font-size: 10px !important;
  color: #a3a3a3 !important;
}
.searchDivider {
  margin: 0px 0px !important;
  color: #dedede !important;
}

.select {
  padding: 3px 0px 3px 10px;

  &:hover {
    border-radius: 5px;
    background-color: #e7e9fe;
  }
}
.textCenter {
  text-align: center;
}
