@import "../../../../styles/variables.scss";
.organization {
  width: 25%;
}

.organizationName {
  color: $primaryColor;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  text-transform: capitalize;
}

.category {
  color: $secondaryColor;
  font-size: 12px;
  font-weight: normal;
  margin: 0;
}

.logo {
  width: 100%;
  max-width: 90px;
  margin-top: 30px;
}
