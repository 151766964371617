@import "../../../styles/variables.scss";
.label {
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
  margin-left: 25px;
  cursor: pointer;
}

.input {
  width: 35px;
  height: 30px;
  border-radius: 10px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  position: relative;
  top: -3px;
  overflow: hidden;
}

.container {
  margin-top: 12px;
  display: flex;
  margin-bottom: 12px;
}

.dossierContainer {
  margin-top: 12px;
  display: flex;
  margin-bottom: 18px;
}

.round {
  position: relative;
}

.smallLabel {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 25%;
  cursor: pointer;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}
.bigLabel {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 25%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;

  &:hover {
    background-color: #e7e9fe;
  }
}

.disabled {
  background-color: #ccc;
}

.smallLabel:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 12px;
}
.bigLabel:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  font-size: bold;
  content: "";
  height: 7px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 17px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: $primaryColor;
  border-color: $primaryColor;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.dossierStyles {
  font-size: 10px;
  font-weight: 500;
  margin-top: 7px;
  margin-left: 30px;
  cursor: pointer;
}
