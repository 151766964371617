@import "../../../styles/variables.scss";

.clientRow {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #0000001a;
  padding: 26px;
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid #011cf5;
  }
}

.details {
  width: 20%;
}

.management {
  width: 17%;
  padding-left: 20px;
}

.director {
  color: #2f2f2f;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.directorName {
  color: $secondaryColor;
  font-size: 12px;
  font-weight: normal;
  margin: 0;
  line-height: 1.4;
}

.manager {
  color: #2f2f2f;
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 0;
}

.managerName {
  color: $secondaryColor;
  font-size: 12px;
  font-weight: normal;
  margin: 0;
  line-height: 1.4;
}

@media screen and (min-width: 1150px) and (max-width: 1200px) {
  .ongoingAssingnment {
    font-size: 11px !important;
  }
}
