@import "../../../../../styles/mixins.scss";
@import "../../../../../styles/variables.scss";

.thCheckbox {
  width: 1%;
  justify-content: center;
  color: #2f2f2f;
  font-size: 10px;
  font-weight: 600;
}
.thName {
  width: 5%;
  color: #2f2f2f;
  font-size: 11px;
  font-weight: 600;
}

.thStatus {
  width: 35%;
  margin-right: 20px;
  color: #2f2f2f;
  font-size: 11px;
  font-weight: 600;
  justify-content: center;
}

.thRefCheck {
  width: 2%;
  color: #2f2f2f;
  font-size: 11px;
  font-weight: 600;
  justify-content: center;
}

.thPotCheck {
  width: 2%;
  color: #2f2f2f;
  font-size: 11px;
  font-weight: 600;
  justify-content: center;
}

.thDelete {
  width: 1%;
  color: #2f2f2f;
  font-size: 11px;
  font-weight: 600;
  justify-content: center;
}

.checkedRow {
  background-color: rgb(1, 28, 245, 0.03) !important;
}

.tlAssignedCandidate {
  font-size: 11px !important;
  font-weight: 600;
  color: #110fc5 !important;
  border-bottom: 1px solid transparent;
  padding-bottom: 2px;
  margin-bottom: 2px;
}

.tlAssignedCandidate:hover {
  border-color: #110fc5 !important;
  transition: all 0.3s ease-in-out;
}
