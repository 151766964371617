.userIconContainer {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 13px;
  /* margin-top: 6px; */
  cursor: pointer;
  background-color: transparent;
  padding-top: 4px;

  &:hover {
    background-color: #f4f4f4 !important;
  }
}

.icon {
  width: 15px;
  height: 15px;
  margin-top: -5px;
  cursor: pointer;
}
