*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

.tw-container {
  width: 100%;
}

@media (min-width: 640px) {
  .tw-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .tw-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .tw-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .tw-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .tw-container {
    max-width: 1536px;
  }
}

.tw-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.tw-fixed {
  position: fixed;
}

.tw-absolute {
  position: absolute;
}

.tw-relative {
  position: relative;
}

.tw-inset-0 {
  inset: 0px;
}

.tw-inset-y-0 {
  top: 0px;
  bottom: 0px;
}

.tw-left-0 {
  left: 0px;
}

.tw-left-\[18\.7\%\] {
  left: 18.7%;
}

.tw-left-\[23\%\] {
  left: 23%;
}

.tw-right-\[21\%\] {
  right: 21%;
}

.tw-right-\[28\%\] {
  right: 28%;
}

.tw-right-\[3\%\] {
  right: 3%;
}

.tw-right-\[31\.4\%\] {
  right: 31.4%;
}

.tw-right-\[33\%\] {
  right: 33%;
}

.tw-right-\[5\%\] {
  right: 5%;
}

.tw-right-\[8\%\] {
  right: 8%;
}

.tw-top-0 {
  top: 0px;
}

.tw-top-\[10\%\] {
  top: 10%;
}

.tw-top-\[12\%\] {
  top: 12%;
}

.tw-top-\[14\%\] {
  top: 14%;
}

.tw-top-\[15\%\] {
  top: 15%;
}

.tw-top-\[20\.6\%\] {
  top: 20.6%;
}

.tw-top-\[28\%\] {
  top: 28%;
}

.tw-top-\[45\%\] {
  top: 45%;
}

.tw-top-\[47\%\] {
  top: 47%;
}

.tw-top-\[49\.5\%\] {
  top: 49.5%;
}

.tw-top-\[6\.3\%\] {
  top: 6.3%;
}

.tw-top-\[7\.2\%\] {
  top: 7.2%;
}

.tw-start-0 {
  inset-inline-start: 0px;
}

.tw-z-50 {
  z-index: 50;
}

.tw-z-\[1000\] {
  z-index: 1000;
}

.tw-m-0 {
  margin: 0px;
}

.tw-m-1 {
  margin: 0.25rem;
}

.tw-mb-0 {
  margin-bottom: 0px;
}

.tw-mb-1 {
  margin-bottom: 0.25rem;
}

.tw-mb-2 {
  margin-bottom: 0.5rem;
}

.tw-mb-8 {
  margin-bottom: 2rem;
}

.tw-mr-6 {
  margin-right: 1.5rem;
}

.tw-mt-3 {
  margin-top: 0.75rem;
}

.tw-mt-4 {
  margin-top: 1rem;
}

.tw-block {
  display: block;
}

.tw-inline {
  display: inline;
}

.tw-flex {
  display: flex;
}

.tw-inline-flex {
  display: inline-flex;
}

.tw-size-6 {
  width: 1.5rem;
  height: 1.5rem;
}

.tw-size-3 {
  width: 0.75rem;
  height: 0.75rem;
}

.tw-h-16 {
  height: 4rem;
}

.tw-h-32 {
  height: 8rem;
}

.tw-h-4 {
  height: 1rem;
}

.tw-h-5 {
  height: 1.25rem;
}

.tw-h-8 {
  height: 2rem;
}

.tw-h-full {
  height: 100%;
}

.tw-h-max {
  height: -webkit-max-content;
  height: max-content;
}

.tw-h-screen {
  height: 100vh;
}

.tw-h-\[13px\] {
  height: 13px;
}

.tw-h-6 {
  height: 1.5rem;
}

.tw-h-3 {
  height: 0.75rem;
}

.tw-w-16 {
  width: 4rem;
}

.tw-w-32 {
  width: 8rem;
}

.tw-w-4 {
  width: 1rem;
}

.tw-w-5 {
  width: 1.25rem;
}

.tw-w-5\/12 {
  width: 41.666667%;
}

.tw-w-8 {
  width: 2rem;
}

.tw-w-\[400px\] {
  width: 400px;
}

.tw-w-full {
  width: 100%;
}

.tw-w-max {
  width: -webkit-max-content;
  width: max-content;
}

.tw-w-14 {
  width: 3.5rem;
}

.tw-w-12 {
  width: 3rem;
}

.tw-w-0 {
  width: 0px;
}

.tw-w-10 {
  width: 2.5rem;
}

.tw-w-3 {
  width: 0.75rem;
}

.tw-min-w-\[170px\] {
  min-width: 170px;
}

.tw-flex-none {
  flex: none;
}

.tw-table-auto {
  table-layout: auto;
}

.tw-table-fixed {
  table-layout: fixed;
}

.tw-rotate-\[270deg\] {
  --tw-rotate: 270deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-rotate-\[90deg\] {
  --tw-rotate: 90deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@-webkit-keyframes tw-spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes tw-spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.tw-animate-spin {
  -webkit-animation: tw-spin 1s linear infinite;
          animation: tw-spin 1s linear infinite;
}

.tw-cursor-not-allowed {
  cursor: not-allowed;
}

.tw-cursor-pointer {
  cursor: pointer;
}

.tw-flex-col {
  flex-direction: column;
}

.tw-items-center {
  align-items: center;
}

.tw-justify-end {
  justify-content: flex-end;
}

.tw-justify-center {
  justify-content: center;
}

.tw-justify-between {
  justify-content: space-between;
}

.tw-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.tw-space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-rounded-full {
  border-radius: 9999px;
}

.tw-rounded-md {
  border-radius: 0.375rem;
}

.tw-border {
  border-width: 1px;
}

.tw-border-l-8 {
  border-left-width: 8px;
}

.tw-border-b {
  border-bottom-width: 1px;
}

.tw-border-l {
  border-left-width: 1px;
}

.tw-border-l-2 {
  border-left-width: 2px;
}

.tw-border-\[\#bf1736\] {
  --tw-border-opacity: 1;
  border-color: rgb(191 23 54 / var(--tw-border-opacity));
}

.tw-border-transparent {
  border-color: transparent;
}

.tw-border-\[\#\#dedede\] {
  border-color: ##dedede;
}

.tw-border-\[\#011fc5\] {
  --tw-border-opacity: 1;
  border-color: rgb(1 31 197 / var(--tw-border-opacity));
}

.tw-bg-\[\#011cf5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(1 28 245 / var(--tw-bg-opacity));
}

.tw-bg-\[\#bf1736\] {
  --tw-bg-opacity: 1;
  background-color: rgb(191 23 54 / var(--tw-bg-opacity));
}

.tw-bg-\[\#e4e4e4\] {
  --tw-bg-opacity: 1;
  background-color: rgb(228 228 228 / var(--tw-bg-opacity));
}

.tw-bg-\[\#f4f4f4\] {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 244 / var(--tw-bg-opacity));
}

.tw-bg-black\/50 {
  background-color: rgb(0 0 0 / 0.5);
}

.tw-bg-black\/80 {
  background-color: rgb(0 0 0 / 0.8);
}

.tw-bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.tw-bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.tw-bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.tw-bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.tw-bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

.tw-bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}

.tw-bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.tw-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.tw-bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.tw-bg-transparent {
  background-color: transparent;
}

.tw-bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.tw-bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.tw-bg-\[\#011fc5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(1 31 197 / var(--tw-bg-opacity));
}

.tw-p-10 {
  padding: 2.5rem;
}

.tw-p-2 {
  padding: 0.5rem;
}

.tw-p-4 {
  padding: 1rem;
}

.tw-p-8 {
  padding: 2rem;
}

.tw-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.tw-py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.tw-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.tw-py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.tw-px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.tw-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.tw-px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.tw-pb-3 {
  padding-bottom: 0.75rem;
}

.tw-pb-4 {
  padding-bottom: 1rem;
}

.tw-pt-4 {
  padding-top: 1rem;
}

.tw-pt-1 {
  padding-top: 0.25rem;
}

.tw-text-center {
  text-align: center;
}

.tw-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.tw-text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.tw-text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.tw-text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.tw-text-\[12px\] {
  font-size: 12px;
}

.tw-text-\[10px\] {
  font-size: 10px;
}

.tw-font-bold {
  font-weight: 700;
}

.tw-font-normal {
  font-weight: 400;
}

.tw-font-medium {
  font-weight: 500;
}

.tw-uppercase {
  text-transform: uppercase;
}

.tw-tracking-tight {
  letter-spacing: -0.025em;
}

.tw-text-\[\#011cf5\] {
  --tw-text-opacity: 1;
  color: rgb(1 28 245 / var(--tw-text-opacity));
}

.tw-text-\[\#011cf5\]\/50 {
  color: rgb(1 28 245 / 0.5);
}

.tw-text-\[\#bf1736\] {
  --tw-text-opacity: 1;
  color: rgb(191 23 54 / var(--tw-text-opacity));
}

.tw-text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.tw-text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.tw-text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.tw-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.tw-text-\[\#212529\] {
  --tw-text-opacity: 1;
  color: rgb(33 37 41 / var(--tw-text-opacity));
}

.tw-text-\[\#dfdfdf\] {
  --tw-text-opacity: 1;
  color: rgb(223 223 223 / var(--tw-text-opacity));
}

.tw-text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.tw-text-\[\#011fc5\] {
  --tw-text-opacity: 1;
  color: rgb(1 31 197 / var(--tw-text-opacity));
}

.tw-accent-red-500 {
  accent-color: #ef4444;
}

.tw-accent-\[\#011cf5\] {
  accent-color: #011cf5;
}

.tw-shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-transition {
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.tw-transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.\[-webkit-tap-highlight-color\:_transparent\] {
  -webkit-tap-highlight-color: transparent;
}

.flux {
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

button {
  font-family: inherit;
  /* 1 */
  -webkit-font-feature-settings: inherit;
  font-feature-settings: inherit;
  /* 1 */
  font-variation-settings: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  font-weight: inherit;
  /* 1 */
  line-height: inherit;
  /* 1 */
  color: inherit;
  /* 1 */
  margin: 0;
  /* 2 */
  padding: 0;
  /* 3 */
  text-transform: none;
}

/* button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;

  background-color: transparent;

  background-image: none;

} */

button,
[role="button"] {
  cursor: pointer;
}

*,
::before,
::after {
  box-sizing: border-box;
  /* 1 */
  border-width: 0;
  /* 2 */
  border-style: solid;
  /* 2 */
  border-color: #e5e7eb;
  /* 2 */
}

.hover\:tw-border-\[\#bf1736\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(191 23 54 / var(--tw-border-opacity));
}

.hover\:tw-bg-\[\#306bff\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(48 107 255 / var(--tw-bg-opacity));
}

.hover\:tw-bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.hover\:tw-bg-slate-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.hover\:tw-bg-transparent:hover {
  background-color: transparent;
}

.hover\:tw-text-\[\#bf1736\]:hover {
  --tw-text-opacity: 1;
  color: rgb(191 23 54 / var(--tw-text-opacity));
}

.hover\:tw-text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.hover\:tw-text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.disabled\:tw-opacity-25:disabled {
  opacity: 0.25;
}

@media (min-width: 1024px) {
  .lg\:tw-bg-slate-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity));
  }

  .lg\:tw-bg-slate-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240 / var(--tw-bg-opacity));
  }

  .lg\:tw-bg-\[\#f4f4f4\] {
    --tw-bg-opacity: 1;
    background-color: rgb(244 244 244 / var(--tw-bg-opacity));
  }

  .lg\:tw-text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }

  .lg\:hover\:tw-bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-slate-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-slate-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-\[\#011fc5\]:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(1 31 197 / var(--tw-bg-opacity));
  }

  .lg\:hover\:tw-bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }

  .lg\:hover\:tw-text-black:hover {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }

  .lg\:hover\:tw-text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}
