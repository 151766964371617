@import "../../../styles/variables.scss";
.btn {
  padding-right: 2px;

  border-radius: 5px;
  border: none;
  font-size: 14px;
  color: #000;
  font-weight: 500;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-right: 15px;

  &:disabled {
    background: gray;
  }
}

.arrow {
  margin-right: 5px;
}

.inactive {
  opacity: 50%;
}

.hoverEffect {
  &:hover {
    color: #011cf5;
  }
}
