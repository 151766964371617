@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.talentdetails {
  @include middleContainer;
  padding: 32px 30px 0px 28px;

  .tableContainer {
    padding: 0px 0px 84px 0px;
    margin-top: 44px;
  }

  .detailsimg {
    h6 {
      background-color: #0c2659;
      color: #fff;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 50%;
      margin: 0 12px 0 0;
      font-weight: 700;
      font-size: 18px;
    }
  }

  .clientName {
    color: #0c2659;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }

  .clientCategoty {
    color: $secondaryColor;
    font-size: 12px;
    margin: 0;
    display: flex;
    font-weight: 400;
  }

  .cilentCity {
    color: $secondaryColor;
    font-size: 12px;
    margin: 0;
    display: flex;
    font-weight: 500;
    // margin-top: 8px;
  }

  .summary {
    padding-bottom: 0.25rem;
    margin-bottom: 1rem;
  }

  .summaryHead {
    color: #0c2659;
    font-size: 14px;
    font-weight: bold;
  }

  .summaryDetail {
    color: #2f2f2f;
    font-size: 12px;
    margin: 0;
    line-height: 17px;
    font-weight: 400;
  }

  .detailscontent {
    .detailsheading {
    }

    h4 {
      color: $secondaryColor;
      font-size: 12px;
      font-weight: 700;
    }

    .boxx {
      border-radius: 5px;
      background-color: #f4f4f4;
      padding: 18px;
      margin-right: 12px;
      margin-bottom: 15px;
      margin-top: 10px;
      flex: 1;

      h5 {
        color: $secondaryColor;
        font-size: 12px;
        margin: 0;
        font-weight: 700;
      }

      p {
        color: #a3a3a3;
        font-weight: 500;
        font-size: 10px;
      }

      h1 {
        color: $secondaryColor;
        font-size: 34px;
        font-weight: 600;
        margin-bottom: 0;
        padding-top: 0px;
        white-space: nowrap;
      }
    }
  }
}

.talentdetails {
  @include middleContainer;
  //@include ristricMiddleContainer;
}
.ristrictStyles {
  @include ristricMiddleContainer;
}

.detailsimg {
  h6 {
    background-color: #0c2659;
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    margin: 0 15px 0 0;
  }
}

.cityHeading {
  color: $secondaryColor;
  font-weight: 600;
  margin-right: 2px;
  font-size: 12px;
}

.detailsicons {
  h5 {
    color: #969292;
    font-size: 11px;
    margin: 0;
    font-weight: 900;
  }
}

.socialIcon {
  cursor: pointer;
}

.view_css {
  color: #0c2659;
  font-size: 12px;

  font-weight: 600;
  margin: 0;
  cursor: pointer;
}

.openMailLink {
  height: 27px;
}

.amountPostFix {
  font-size: 24px;
}

.assignmenttable {
  margin-top: 11px;
}

tHead {
  background-color: #f4f4f4;
}

.tBody {
  border-top: 0;
}

.thClient {
  // width: 30%;
  color: #2f2f2f;
  font-size: 12px;
  font-weight: 500;
}
.dot {
  width: 10px;
  height: 10px;
  border-radius: 4px;
  background-color: #ffcd46;
}
.summaryDivider {
  margin: 26px 0px 18px 0px;
  background-color: #707070;
}
.assignmentDivider {
  margin: 26px 0px 18px 0px;
  background-color: #707070;
}
.textCenter {
  text-align: center;
  font-size: 12px;
  color: #a3a3a3;
}

.imageIcon {
  margin-left: 5px;
  /* margin-top: 4px; */
  margin-bottom: 3px;
  height: 12px;
  width: 12px;
  cursor: pointer;
}

.clientLogo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 15px 0 0;
  object-fit: contain;
  border: 1px solid #f4f4f4;
}

.dataNotFound {
  text-align: center;
  font-size: 12px;
  // text-transform: capitalize;
  font-weight: 500 !important;
  padding: 20px;
  color: #a3a3a3 !important;
}

a:hover {
  color: #011cf5 !important;
}

.scrollContainer {
  margin-top: 4px;
  white-space: nowrap;
  overflow: scroll;
  text-overflow: ellipsis;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.tableTopContainer {
  display: flex;
  justify-content: space-between;
}
.addButton {
  font-size: 9px !important;
}
