.hideContainer {
  display: none;
}
.contactPerson {
  font-size: 16px;
  font-weight: 700;
  color: #2f2f2f;
  line-height: 1.4;
  margin-bottom: 0px;
}

.personName {
  padding: 0;
  color: #2f2f2f;
  font-size: 12px;
  margin: 0;
  line-height: 1.625;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
}
.hrDirector {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  color: #2f2f2f;
  line-height: 1.2;
  text-overflow: ellipsis;
  overflow: hidden;
}
.email {
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: #011cf5;
  line-height: 1.5;
  text-transform: none !important;
  text-decoration: none !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.phone {
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: #011cf5;
  line-height: 1.5;
  text-transform: none !important;
}
.viewAllContacts {
  margin-top: 10px;
  color: #0c2659;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: initial;
  line-height: 1;

  &:hover {
    color: #011cf5;
  }
}

@media screen and (min-width: 1150px) and (max-width: 1400px) {
  .email {
    display: block;
    font-size: 12px;
    font-weight: 500;
    color: #011cf5;
    line-height: 1.5;
    text-transform: none !important;
    text-decoration: none !important;
  }
}

@media screen and (min-width: 1401px) {
  .leftcontact {
    width: 85%;
    border-right: 1px solid rgba(222, 222, 222, 0.3411764706);
    margin-right: 70px;
  }
}
