.thPosition {
  // width: 40%;
  color: #2f2f2f;
  font-size: 12px;
  font-weight: 500;
}
.thAgeing {
  // width: 30%;
  color: #2f2f2f;
  font-size: 12px;
  font-weight: 500;
}
.tData {
  color: #2f2f2f;
  font-size: 12px;
  font-weight: 500;
}
.designationStyles {
  font-size: 10px;
  font-weight: 400;
}
.linkstyles {
  color: #011cf5 !important;
}
