.checkBox {
  margin-right: 8px;
}
.selectDropDown {
  background-color: white !important;
  border: 1px solid #ededed !important;
  font-size: 10px !important;
  color: #2f2f2f !important;
  padding: 5px 10px 5px 13px !important;
  // width: 140px !important;
  justify-content: space-between !important;
}
.dropDownActive {
  border: 1px solid #011cf5 !important;
}

.activeIcon {
  transition: all 0.2s ease;
  transform: rotate(180deg);
}
.activeIconColor {
  color: #011cf5;
  background-color: #e7e9fe;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-left: 10px;
}
.sliderCss {
  color: #011cf5 !important;
}
.mainContainer {
  padding: 7px 9px 20px 9px;
  font-size: 12px;
}
.assignmentStyles {
  background-color: white !important;
  border: 1px solid #ededed !important;
  font-size: 10px !important;
  color: #2f2f2f !important;
  padding: 5px 10px 5px 10px !important;
  width: 117px !important;
  justify-content: space-between !important;
  &:hover {
    border: 1px solid #011cf5 !important;
  }
}

.selectedDropDown {
  background-color: #011cf5 !important;
  color: #ffffff !important;
}

.labelText {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 700;
  bottom: -48px;
  top: unset;
  background-color: unset;
  color: #000;
}
.hoverEffect {
  &:hover {
    border: 1px solid #011cf5 !important;
  }
}
