@import "../../../../styles/mixins.scss";

.details {
  .dotss {
    img {
      width: 100%;
      max-width: 20px;
    }
  }

  .commenttext {
    h5 {
      background-color: #0c2659;
      margin: 0;
      width: 27px;
      height: 27px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-size: 14px;
      font-weight: 700;
      line-height: 27px;
      margin-right: 12px;
    }
  }

  .detailscontent {
    .detailsheading {
      h2 {
        text-align: left;
        padding-left: 0;
        padding-top: 0;
        font-size: 12px;
      }
    }
  }
}

.detailscontent {
  .detailsheading {
    h2 {
      color: #0c2659;
      font-size: 18px;
      font-weight: bold;
      margin: 0;
    }

    p {
      color: #2f2f2f;
      font-size: 12px;
      margin: 0;

      span {
        font-weight: 600;
      }
    }
  }

  h3 {
    color: #0c2659;
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0 10px;
  }

  h4 {
    color: #2f2f2f;
    font-size: 12px;
    font-weight: 700;
  }

  p {
    color: #2f2f2f;
    font-size: 12px;
    margin: 0;
  }
}

.detailsheading {
  h2 {
    text-align: left;
    padding-left: 0;
    padding-top: 0;
    font-size: 12px;
  }
}

.socialIcon {
  height: 18px;
  width: 18px;
}

.tabMainContent {
  @include middleContainer;
  // padding: 32px 30px 20px 28px;
  padding: 34px 32.8px 43.2px 36px;
}

.iconSmallCircle {
  background-color: #f4f4f4;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;

  img {
    width: 17px;
    height: 17px;
  }
}

.contactContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.candidateContainer {
  color: #0c2659;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0px;
}
.boxContainer {
  display: flex;
  flex-shrink: inherit;
  overflow: scroll;
}
.boxContainer::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.divider {
  margin-top: 20px;
  color: #dedede;
  margin-bottom: 15px;
}
.lineDivider {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #dedede !important;
}
.percentageStyle {
  font-size: 15px !important;
}

.dataNotFound {
  text-align: center;
  font-size: 12px;
  // text-transform: capitalize;
  font-weight: 500 !important;
  padding: 20px;
  color: #a3a3a3 !important;
}

.createByStyles {
  margin-bottom: 15px;
  font-size: 11px;
  color: #a3a3a3;
  line-height: 1rem;
}
