.container {
  display: flex;
}

.leftContainer {
  width: 45%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
  border-top: 10px solid #a3a3a3;
}

.rightContainer {
  width: 55%;
  height: 100vh;
  background-image: url("../../../public/images/login/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top: 10px solid #011cf5;
}

.login {
  width: 80%;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 70px;
}

.input {
  background-color: #dedede !important;
  border-radius: 10px !important;
  height: 60px;
  border: transparent solid 2px;
  padding: 20px 30px;
  width: 100%;
  outline: none;
  &:focus {
    // outline: #011cf5 auto 1px;
    border: 2px solid #011cf5;
    outline: none;
  }
  &:hover {
    border: 2px solid #011cf5;
    background-color: #f4f4f4 !important;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
    outline: none;
  }
  &:focus-visible {
    border: 2px solid #011cf5 !important;
    background-color: #f4f4f4 !important;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
    outline: none;
  }

  &::placeholder {
    color: #a3a3a3;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #dedede inset !important;
    border: #dedede solid 2px;
  }
}

.invalid {
  border: red solid 2px;
}

.signInBtn {
  color: white;
  height: 70px;
  border: none;
  margin-top: 40px;
  border-radius: 10px;

  background: transparent
    linear-gradient(90deg, #011cf5 0%, #0c2659 100%, #306bff 100%) 0% 0%
    no-repeat padding-box;

  &:hover {
    box-shadow: 0px 0px 30px #00000040;

    background: transparent linear-gradient(#0c2659 100%, #306bff 90%) 0% 0%
      no-repeat padding-box;
  }
}

.forgotPass {
  margin-top: 10px;
  text-align: right;
  color: #2f2f2f;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: #011cf5;
  }
}
.forgetContainer {
  display: flex;
  justify-content: end;
}

.errorMsg {
  color: red;
  font-size: 12px;
  margin-top: 2px;
}

.labelContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.title {
  font-size: 30px;
}

.info {
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.infoTitle {
  font-size: 30px;
  color: #fff;
  display: flex;
  align-items: baseline;
  font-weight: 500;
  white-space: nowrap;
}

.infoDesc {
  color: #fff;
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 0;
  padding: 8px 150px;
  letter-spacing: normal;
  text-align: center;
}

.infoFooter {
  position: absolute;
  bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.copyright {
  font-size: 12px;
  color: #fff;
  opacity: 0.49;
}
.logoLogin {
  display: inline-block;
  .logoStyle {
    width: 245.3px;
    height: 40.7px;
    margin: 0.3px 0 0;
  }
}
.logoGet {
  color: #a3a3a3;
  font-weight: 500;
  // text-transform: capitalize;
}
.BottomContent {
  position: absolute;
  bottom: 20px;
  p {
    margin: 0;
    color: #a3a3a3;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }
}

.eyeViewHide {
  height: 25px;
  width: 25px;
  top: 38px;
  right: 12px;
  position: absolute;
  cursor: pointer;
}

.relative {
  position: relative;
}

.textLogo {
  margin-left: 6px;
}

.arrow {
  margin-top: -2px;
  margin-left: 8px;
  font-size: large !important;
}
.headingStyles {
  line-height: 1.2 !important;
}

.afterSuccessContainer {
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 100px;
}
.westArrow {
  margin-top: -2px;
  margin-right: 4px;
  font-size: large !important;
}

.trasparentText {
  background-color: transparent;
  color: transparent;
}
.successText {
  padding-top: 40px;
  padding-bottom: 70px;
  color: #a3a3a3;
  font-size: 18px;
  font-weight: 400;
}
