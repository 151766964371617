@import "../../../styles/mixins.scss";

.tabMainContent {
  @include middleContainer;
  padding: 34px 32.8px 43.2px 36px;
}
.ristrictStyles {
  @include ristricMiddleContainer;
  padding: 34px 32.8px 43.2px 36px;
}

.details {
  .dotss {
    img {
      width: 100%;
      max-width: 20px;
    }
  }

  .commenttext {
    h5 {
      background-color: #0c2659;
      margin: 0;
      width: 27px;
      height: 27px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-size: 14px;
      font-weight: 700;
      line-height: 27px;
      margin-right: 12px;
    }
  }

  .detailscontent {
    .detailsheading {
      h2 {
        text-align: left;
        padding-left: 0;
        padding-top: 0;
        font-size: 12px;
      }
    }
  }
}

.detailscontent {
  .detailsheading {
    h2 {
      color: #0c2659;
      font-size: 18px;
      font-weight: bold;
      margin: 0;
    }

    p {
      color: #2f2f2f;
      font-size: 12px;
      margin: 0;

      span {
        font-weight: 600;
      }
    }
  }

  h3 {
    color: #0c2659;
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0 10px;
  }

  h4 {
    color: #2f2f2f;
    font-size: 12px;
    font-weight: 700;
  }

  p {
    color: #2f2f2f;
    font-size: 12px;
    margin: 0;
  }
}

.detailsheading {
  h2 {
    text-align: left;
    padding-left: 0;
    padding-top: 0;
    font-size: 12px;
  }
}

.socialIcon {
  height: 18px;
  width: 18px;
}

.mainHeading {
  color: #0c2659;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.04;
}
.iconsDiv {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.statusHeading {
  color: #0c2659;
  font-size: 11px;
}

.iconSmallCircle {
  background-color: #f4f4f4;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;

  img {
    width: 17px;
    height: 17px;
  }
}
.CSCHeading {
  font-size: 12px;
  font-weight: 400;
  color: #2f2f2f;
  margin: 7.9px 6.4px 32px -3.7px;
}
.stateHeading {
  font-size: 12px;
  font-weight: 500;
  color: #2f2f2f;
  margin: 7.9px 6.4px 32px 0.3px;
}
.companyNameHeading {
  font-size: 18px;
  font-weight: 500;
  color: #2f2f2f;
}
.contactContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.leftcontact {
  width: 47%;
  p {
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    color: #2f2f2f;
    line-height: 1.325;
  }
  h3 {
    font-size: 15px;
    font-weight: 700;
    color: #2f2f2f;
    line-height: 1.4;
  }
}
.rightsummary {
  width: 53%;
  margin-right: 6px;
  p {
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 500;
    color: #2f2f2f;
    // line-height: 1.2;
  }
  h3 {
    font-size: 15px;
    font-weight: bold;
    color: #0c2659;
    line-height: 1.4;
  }
}
.companyHeader {
  margin-top: 13px !important;
}

.candidateContainer {
  color: #0c2659;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0px;
}
.boxContainer {
  display: flex;
  flex-shrink: inherit;
  overflow: scroll;
}
.boxContainer::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.phonediv {
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: #011cf5;
  // line-height: 1.2;
  :hover {
    color: #011cf5 !important;
  }
}
.emaildiv {
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: #011cf5;
  line-height: 1.5;
  text-transform: none !important;
  text-decoration: none !important;
  overflow: hidden;
  text-overflow: ellipsis;
  :hover {
    color: #011cf5 !important;
  }
}

.divider {
  margin-top: 20px;
  color: #dedede;
  margin-bottom: 15px;
}
.lineDivider {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #dedede !important;
}
.percentageStyle {
  font-size: 15px !important;
}

.dataNotFound {
  text-align: center;
  font-size: 12px;
  // text-transform: capitalize;
  font-weight: 500 !important;
  padding: 20px;
  color: #a3a3a3 !important;
}

.mailIcon {
  cursor: pointer;
  margin-left: 7px;
  margin-bottom: 6px;
}

.statusButton {
  white-space: nowrap;
  background-color: #d9dffc;
  border-radius: 5px;
  font-size: 12px;
  padding: 3px 10px;
  font-weight: 700;
  color: #011cf5;
  margin-top: 3px;
}
