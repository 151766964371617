@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

$color_1: #fff;
$color_2: #fff;

.overlap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.btn {
  @include baseButton;
  color: red;
}

.header {
  height: 68px;
  background-color: #fff;
  background-image: url("../../../../public/images/nav-images/navigation-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 5px solid $primaryColor;
}

.logoStyle {
  width: 147px;
  margin: 3px 0 0 0;
}

.navbarbrand {
  span {
    color: $color_1;
    font-size: 18px;
    font-weight: bold;
    padding-left: 20px;
    border-left: 1px solid #dedede85;
    margin-left: 20px;
  }
}

.profilehead {
  .navitem {
    margin-top: 6px;

    position: relative;
    color: #fff;
    font-size: 12px;
    // font-weight: 500;
    padding: 0 5px;

    span {
      color: #fff;
      font-weight: bold;
    }

    svg {
      width: 20px;
      height: 20px;
      color: #fff;
    }

    .dropdownmenu {
      .userimg {
        padding: 18px 25px;
        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          object-fit: cover;
        }

        .username {
          h2 {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 0px;
          }

          p {
            color: $secondaryColor;
            font-size: 12px;
            font-weight: 500;
            margin: 0;
          }
        }
      }

      li {
        padding: 0px 0;
        .dropdownitem {
          font-size: 12px;
          color: $secondaryColor;
          font-weight: 500;
          padding: 3px 25px;
          img {
            width: 15px;
            height: 15px;
          }
        }

        .mangef {
          font-size: 14px;
          padding: 0px 25px;
        }

        .dropdown-divider {
          border-top: 1px solid #dddddd;
          opacity: 1;
        }
      }
    }
  }
}

.tempmodal {
  position: fixed;
  top: 68px;
  right: 70px;
  width: 22.4%;

  bottom: 10;
  color: black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}

.dropdownmenu {
  left: inherit;
  right: 0;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 30px #00000026;
  min-width: 100%;

  border: none;
  border-radius: 5px;
  top: 42px;
  height: 350px;
  .userimg {
    padding: 12px 5px;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      object-fit: cover;
    }

    .username {
      h2 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0px;
      }

      p {
        color: $secondaryColor;
        font-size: 12px;
        font-weight: 500;
        margin: 0;
      }
    }
  }

  li {
    list-style: none;
    padding: 0px 0;
    margin-top: 7px;
    .dropdownitem {
      font-size: 12px;
      color: $secondaryColor;
      font-weight: 500;
      padding: 3px 25px;
      img {
        width: 15px;
        height: 15px;
      }
    }

    .mangef {
      font-size: 14px;
      padding: 0px 25px;
    }

    .dropdown-divider {
      border-top: 1px solid #dddddd;
      opacity: 1;
    }
  }
}

.divider {
  color: #dddddd;
}

.fullBellIcon {
  height: 10px;
  width: 10px;
}
.assignmnetOuterDiv {
  z-index: 10;
  height: 100%;
  background-color: green;
  // display: flex;
  align-items: center;
}

.textStyle {
  width: 50px;
}
