@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.headContainer {
  display: flex;
  width: 100%;
}
.middleContainer {
  display: flex;
  width: 70%;
}

.detailsimg {
  h6 {
    background-color: #0c2659;
    color: #fff;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    margin: 0 12px 0 0;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 18px;
    padding: 2px 7px 1px 7px;
    height: 42px;
    width: 42px;
  }
}

.talentUserImage {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin: 0 12px 0 0;
  object-fit: cover;
  border: 1px solid #f4f4f4;
}
.detailscontent {
  width: 100% !important;

  .detailsheading {
    h2 {
      color: #0c2659;
      font-size: 18px;
      font-weight: bold;
      margin: 0;
    }

    p {
      color: $secondaryColor;
      font-size: 12px;
      margin: 0;
      display: flex;
      font-weight: 500;
      span {
        font-weight: 600;
      }
    }
  }

  h3 {
    color: #0c2659;
    font-size: 14px;
    font-weight: bold;
    margin: 32px 0 10px;
  }

  h4 {
    color: $secondaryColor;
    font-size: 12px;
    font-weight: 700;
  }

  p {
    color: $secondaryColor;
    font-size: 12px;
    margin: 0;

    font-weight: 400;
  }
}
.workStyle {
  width: 100%;
  display: flex;
}

.jobStyle {
  color: $secondaryColor !important;
  font-size: 12px !important;
  margin: 0 !important;
  display: block !important;
  font-weight: 500 !important;
  span {
    font-weight: 600 !important;
  }
}
.cityHeading {
  color: $secondaryColor;
  font-weight: 600;
  margin-right: 2px;
  font-size: 12px;
}
.detailsicons {
  h5 {
    color: #969292;
    font-size: 11px;
    margin: 0;
    font-weight: 900;
  }
}
