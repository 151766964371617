@import "../../styles/variables.scss";

.boxx {
  border-radius: 5px;
  background-color: #f4f4f4;
  padding: 18px;
  margin-right: 12px;
  // margin-bottom: 15px;
  // margin-top: 10px;
  flex: 1;

  h5 {
    color: $secondaryColor;
    font-size: 12px;
    margin: 0;
    font-weight: 500;
  }

  p {
    padding-bottom: 4px !important;
    color: #a3a3a3 !important;
    font-weight: 500 !important;
    font-size: 10px !important;
  }

  h1 {
    color: $secondaryColor;
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 0;
    padding-top: 0px;
    white-space: nowrap;
    line-height: 1;
  }
}

.amountPostFix {
  font-size: 24px;
}

@media screen and (min-width: 1150px) and (max-width: 1250px) {
  .boxx {
    width: 210px;
  }
}
