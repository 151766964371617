.inner {
  color: #bf1736;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 10px 10px !important;
  text-decoration: underline;
  border-radius: 5px !important;

  cursor: pointer;
  &:hover {
    background-color: #bf173612 !important;
  }
}
.deletetext {
  margin-right: 25px;
}

.ristrictButton {
  margin-right: 55px;
}
