.tabcontent {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 100%;
  // padding: 20px;
}
.topheading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0 20px;
}

.h3heading {
  letter-spacing: 0px;
  color: #2f2f2f;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.cardDivider {
  margin-top: 11px;
  color: #dedede;
  margin-bottom: 8px;
}
.middleCard {
  padding: 0px 20px 0 20px;
}

.bottomFotter {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 20px 20px 32px 20px;
}

.bottomDivider {
  margin-top: 21px;
  color: #dedede;
  margin-bottom: -6px;
}
.eyeViewHide {
  height: 25px;
  width: 25px;
  top: 34px;
  right: 12px;
  position: absolute;
  cursor: pointer;
}
