@import "../../../styles/variables.scss";

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
  h1 {
    font-size: 10px;
    font-weight: 500;
  }
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $primaryColor;
}

// input:focus + .slider {
//   box-shadow: 0 0 1px $primaryColor;
// }

input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.selectinput {
  display: flex;
  label {
    color: $secondaryColor;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;

    input {
      width: 25px;
      height: 25px;
      border-radius: 10px;
      background: #dddddd 0% 0% no-repeat padding-box;
      border: none;
    }
  }
}
