.hideRightSummary {
  width: 50%;
}
.accountDirector {
  font-size: 16px;
  font-weight: bold;
  color: #0c2659;
  line-height: 1.4;
  margin-bottom: 0px;
  margin-top: 0px;
}
.directorName {
  display: flex;
  align-items: end;
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 500;
  color: #2f2f2f;
  line-height: 1.2;
  margin-bottom: 16px;
}
.manager {
  font-size: 16px;
  font-weight: bold;
  color: #0c2659;
  line-height: 1.4;
  margin-bottom: 0px;
  margin-top: 0px;
}
.managerName {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 500;
  color: #2f2f2f;
  line-height: 1.2;
}
@media screen and (min-width: 1150px) and (max-width: 1400px) {
  .rightsummary {
    padding-top: 20px;
  }
}

@media screen and (min-width: 1401px) {
  .rightsummary {
    width: 50%;
    // border-left: 1px solid #dedede57;
    // padding-left: 55px;
  }
}
