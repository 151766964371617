@import "~bootstrap/scss/bootstrap";
@import "./variables.scss";

//@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

body {
  background: #f4f4f4 0% 0% no-repeat padding-box;
  font-family: "Public Sans", sans-serif !important;
  background-image: url("../../public/images/body-bg.jpg");
  background-size: cover;
  background-position: center;
  text-rendering: optimizeLegibility;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-attachment: fixed; /* <= This one */
}
a {
  text-decoration: none;
}
.main-wraper {
  display: flex;
}
html,
body {
  min-height: 100%;
  font-family: "Public Sans", sans-serif !important;
}

.tox-tinymce {
  z-index: 0;
}
.Toastify__toast {
  align-items: center !important;
  padding: 0px 8px 0px 8px !important;
  width: fit-content;
  min-width: 320px;
}

.Toastify__toast-body {
  margin: auto 12px auto 0px !important;
}

.Toastify__close-button {
  align-self: center !important;
}

.Toastify__toast-icon {
  margin-inline-end: 16px !important;
  width: 20px;
  flex-shrink: 0;
  display: flex;
}

.Toastify__toast--success {
  border-left: 5px solid #07bc0c;
}

.Toastify__toast--info {
  border-left: 5px solid #3498db;
}

.Toastify__toast--warning {
  border-left: 5px solid #f1c40f;
}

.Toastify__toast--error {
  border-left: 5px solid #e74c3c;
}

.pagination {
  margin: 0;
  li {
    a {
      background: transparent !important;
      border: none !important;
      color: #a3a3a3 !important;
      font-size: 13px !important;
      font-weight: 500 !important;
    }
  }
  .active {
    .page-link {
      background: transparent !important;
      color: black !important;
      border: none;
      font-size: 13px !important;
      font-weight: 500 !important;
    }
  }

  .page-link {
    &:focus {
      box-shadow: none !important;
    }
  }
}

//////// Comment section ///////

.commentDiv {
  color: $secondaryColor;
  font-size: 12px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.33;
  word-wrap: break-word;

  .mention {
    background-color: #e9eff6;
    color: #011cf5;
    padding: 1px 2px;
    border-radius: 5px;
    font-weight: 500;
  }

  .link {
    color: #011cf5;
    padding: 1px 2px;
    border-radius: 5px;
    font-weight: 500;
    text-decoration: underline;
  }
}

//////// custom datepicker /////////////

.custom-datepicker-wrapper {
  display: flex;
  width: 53%;
  cursor: pointer;

  .react-datepicker__input-container {
    height: 100%;
  }

  .custom-input {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #c9c9c9;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right-width: 0px;
    &:hover {
      border-color: #011fc5;
      border-right-width: 1px;
    }
  }

  .input-value {
    font-size: 12px;
    font-weight: 700;
  }
}

.custom-datepicker-calendar {
  border: 1px solid #cecdcd;
  -webkit-box-shadow: 0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%), 0px 2px 4px -1px rgb(0 0 0 / 20%);
  box-shadow: 0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%), 0px 2px 4px -1px rgb(0 0 0 / 20%);

  .react-datepicker__day--selected {
    color: white;
    background-color: #011cf5;
  }
  .react-datepicker__header {
    background-color: #f4f4f4;
  }
  .react-datepicker__navigation {
    top: 8px;
  }
  .react-datepicker__navigation-icon::before {
    border-color: #7282fb;
  }

  .react-datepicker__day--keyboard-selected {
    color: white;
    background-color: #011cf5;;
  }
}
