.checkBox {
  margin-right: 8px;
}
.selectDropDown {
  background-color: white !important;
  border: 1px solid #ededed !important;
  font-size: 10px !important;
  color: #2f2f2f !important;
  padding: 5px 10px 5px 10px !important;
  // width: 140px !important;
  justify-content: space-between !important;
}
.dropDownActive {
  border: 1px solid #011cf5 !important;
}

.activeIcon {
  transition: all 0.2s ease;
  transform: rotate(180deg);
}
.activeIconColor {
  color: #011cf5;
  background-color: #e7e9fe;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-left: 10px;
}
.selectedDropDown {
  background-color: #011cf5 !important;
  color: #ffffff !important;
}

.assignmentStyles {
  background-color: white !important;
  border: 1px solid #ededed !important;
  font-size: 10px !important;
  color: #2f2f2f !important;
  padding: 5px 10px 5px 10px !important;
  width: 117px !important;
  justify-content: space-between !important;
}

.selectedAssignmentStyles {
  background-color: #011cf5 !important;
  color: #ffffff !important;
}
.searchbale {
  background-color: #ffffff !important;
  &:hover {
    background-color: #ffffff !important;
  }
}
.menuValue {
  // font-weight: bold;
  padding-left: 6px;
}

.valueOverflow {
  overflow: hidden;
  width: 78%;
  text-overflow: ellipsis;
}

.menuDataCss {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2px;
}
.cityOrState {
  color: #a3a3a3;
}

.hoverEffect {
  &:hover {
    border: 1px solid #011cf5 !important;
  }
}
