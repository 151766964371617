@import "../../../styles/mixins.scss";
@import "../../../styles//variables.scss";

.talentdetails {
  @include middleContainer;
}

.ristrictAcces {
  @include ristricMiddleContainer;
}

.containerInner {
  padding: 32px 30px 0px 28px;
  .detailsimg {
    h6 {
      background-color: #0c2659;
      color: #fff;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 50%;
      margin: 0 12px 0 0;
      font-weight: 700;
      font-size: 18px;
    }
  }

  .summary {
    padding-bottom: 0.25rem;
    margin-bottom: 1rem;
  }

  .summaryHead {
    color: #0c2659;
    font-size: 14px;
    font-weight: bold;
  }

  .summaryDetail {
    color: #2f2f2f;
    font-size: 12px;
    margin: 0;
    line-height: 17px;
    font-weight: 400;
  }
}

.view_css {
  color: #0c2659;
  font-size: 12px;

  font-weight: 600;
  margin: 0;
  cursor: pointer;
}

.amountPostFix {
  font-size: 24px;
}

.assignmenttable {
  margin-top: 11px;
}

tHead {
  background-color: #f4f4f4;
}

.tData {
  color: #2f2f2f;
  font-size: 12px;
  font-weight: 500;
}

.tBody {
  border-top: 0;
}

.thPosition {
  width: 40%;
  color: #2f2f2f;
  font-size: 10px;
  font-weight: 500;
}

.thAgeing {
  width: 30%;
  color: #2f2f2f;
  font-size: 10px;
  font-weight: 500;
}

.thClient {
  width: 30%;
  color: #2f2f2f;
  font-size: 10px;
  font-weight: 500;
}
.dot {
  width: 10px;
  height: 10px;
  border-radius: 4px;
  background-color: #ffcd46;
}
.summaryDivider {
  margin: 26px 0px 18px 0px;
  background-color: #707070;
}
.assignmentDivider {
  margin: 26px 0px 18px 0px;
  background-color: #707070;
}
.textCenter {
  text-align: center;
  font-size: 12px;
  color: #a3a3a3;
}

.dataNotFound {
  text-align: center;
  font-size: 12px;
  // text-transform: capitalize;
  font-weight: 500 !important;
  padding: 20px;
  color: #a3a3a3 !important;
}

.tableContainer {
  padding: 0px 50px 0px 50px;
  margin-top: 44px;
}

.workStyle {
  width: 100%;
  display: flex;
}
.jobStyle {
  color: $secondaryColor !important;
  font-size: 12px !important;
  margin: 0 !important;
  display: block !important;
  font-weight: 500 !important;
  span {
    font-weight: 600 !important;
  }
}
