@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}
.infoHeading {
  line-height: 1;
  font-size: 12px;
  font-weight: bold;
  color: #a3a3a3;
  padding: 0px 10px 0px 7px;
  margin-top: 10px;
}
.tableBlock {
  animation: growDown 300ms ease-in-out forwards;
  display: block;
  transform-origin: top center;
}

.noDataFound {
  text-align: center;
  font-size: 12px;
  font-weight: 500 !important;
  /* padding: 20px; */
  color: #a3a3a3 !important;
}
