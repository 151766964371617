@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.searchfilter {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
.title {
  color: #0c2659;
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 0px;
  margin-bottom: 5px;
  text-transform: capitalize;
}
.fw400 {
  font-weight: 400;
}
.divider {
  margin-top: 12px;
  margin-bottom: 12px;
  border-color: #707070;
  opacity: 0.1;
}

.tablehead {
  margin-top: 8px;

  thead {
    background-color: #f4f4f4;

    th {
      color: #2f2f2f;
      font-size: 11px;
      font-weight: 600;
    }

    .currenttable {
      width: 25%;
      margin-right: 20px;
    }
  }

  tbody {
    border: none !important;

    tr {
      border-bottom-width: 1px;
      border-color: #dedede;
    }

    td {
      border-color: #dedede;
      //padding-top: 10px;
      //padding-bottom: 10px;
      // vertical-align: bottom;
      border: none;
      h2 {
        color: #2f2f2f;
        font-size: 10px;
        font-weight: 700;
        margin-bottom: 0;
      }

      p {
        width: 185px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 10px;
        margin-bottom: 0;
        font-weight: normal;
        color: #2f2f2f !important;
      }

      .selectcolor {
        select {
          background-color: #b1ffba;
        }
      }
    }
  }
}

// .widthselect .select {
//   width: 150px;
// }

.tabMainContent {
  @include middleContainer;
  padding: 23px 36px 43.2px 36px;
}

.bulkText {
  font-weight: bold;
  font-size: 10px;
  white-space: nowrap;
}
.showingResult {
  font-size: 11px;
  width: 8%;
  font-weight: 500;
  color: #2f2f2f;
  white-space: nowrap;
  margin-top: 12px;
}
.rightTextDiv {
  width: 12%;
  width: 12%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.iconSmallCircle {
  background-color: #f4f4f4;
  width: 30px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
  padding: 1px 8px 3px 10px;

  img {
    width: 17px;
    height: 17px;
  }
  &:hover {
    background-color: #e7e9fe;
  }
}

.topSelect {
  border: none;
  border-radius: 5px;
  color: #2f2f2f;
  font-size: 10px;
  height: 23px;
  background-color: #f4f4f4;
  width: 100%;
  font-weight: 500;
  font-family: unset !important;
  font-size: 12px !important;
  font-weight: 500 !important;

  &::before {
    border-bottom: none !important;
    padding: 0px !important;
  }

  &::after {
    border-bottom: none !important;
  }
}

.greenSelect {
  background-color: $green !important;
}

.selectItem {
  padding: 4px 10px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  font-family: unset !important;
  // margin-top: 6px !important;
}
.tableAddUser {
  margin-bottom: 6px;

  height: 17px;
  width: 17px;
  cursor: pointer;
  margin-right: 4%;
}

.thCheckBox {
  width: 2%;
}
.thName {
  width: 10%;
}
.thCurrentStatus {
  width: 25%;
}
.thAddUser {
  // margin-right: 24px;
}
.bold {
  font-weight: bold;
  font-size: 11px;
}
.checkedRow {
  background-color: rgb(1, 28, 245, 0.03) !important;
}
.sortingDiv {
  width: 28%;
  margin-top: 7px;
  justify-content: end;
  /* text-align: left; */
  display: flex;
}

.addedTextCss {
  display: contents;
  font-size: 10px;
  font-weight: 500;
  color: #a3a3a3;
}

.addedTextOfflimitCss {
  display: contents;
  font-size: 10px;
  font-weight: 500;
  color: #bf1736;
}

.sortingContainer {
  padding: 1px 5px 1px 5px;

  &:hover {
    // background: #e7e9fe;
    border-radius: 5px;
  }
}

.userIconContainer {
  // width: 30px;
  // height: 30px;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
  background-color: transparent;
  padding: 7px 8px 3px 10px;

  &:hover {
    background-color: #f4f4f4 !important;
  }
}

.iconTdContaner {
  min-height: 43px;
  background-color: red;
  line-height: 1.11;
  padding: 13px 10px 14px 10px !important;
  font-size: 10px;
  display: flex;
  font-weight: 500;
  color: #a3a3a3;
  justify-content: end;
  align-items: center;
  text-align: end;
}

.iconNewContaner {
  min-height: 43px;
  background-color: red;
  line-height: 1.11;
  padding: 0 !important;
  font-size: 10px;
  display: flex;
  font-weight: 500;
  color: #a3a3a3;
  justify-content: end;
  align-items: center;
  text-align: end;
}
.nodataStyle {
  text-align: center;
  font-size: 12px;
  font-weight: 500 !important;
  /* padding: 20px; */
  color: #a3a3a3 !important;
}
.trasnStyles {
  padding: 5px 10px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  font-family: unset !important;
  background-color: transparent !important;
}

@media screen and (min-width: 1150px) and (max-width: 1257px) {
  .iconTdContaner {
    // padding: 10px 0px 0px 0px !important;
  }
}
@media screen and (min-width: 1150px) and (max-width: 1400px) {
  th {
    color: #2f2f2f;
    font-size: 11px !important;
    font-weight: 500;
  }
}

.tlAssignedCandidate {
  font-size: 11px !important;
  font-weight: 600;
  color: #110fc5 !important;
  border-bottom: 1px solid transparent;
  padding-bottom: 2px;
  margin-bottom: 2px;
}

.tlAssignedCandidate:hover {
  border-color: #110fc5 !important;
  transition: all 0.3s ease-in-out;
}
