.sideMenu {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 13px 15px 0px 14px;
  width: 186px;
  position: inherit;
  top: 0;
  height: 100%;
  margin-right: 21px;

  .navlink.active,
  .show > .navlink {
    background: transparent;
    color: #011cf5 !important;
    text-align: left;

    &::after {
      position: absolute;
      content: "";
      width: 2.2px;
      height: 22px;
      background: #011cf5 !important;
      left: -15px;
      top: 5px;
    }
  }

  .navlink {
    padding: 8px 0px 8px 10px;
    text-align: left;
    color: #2f2f2f !important;
    font-size: 12px;
    font-weight: 600;
    background-color: transparent !important;
    // background-color: red !important;
    position: relative;
    background: none;
    border: 0;
    border-radius: 0.25rem;
    cursor: pointer;

    &:hover {
      //background-color: #f4f4f4 !important;
      color: #011cf5 !important;
    }
  }
}

.imageIcon {
  margin-left: 5px;
  margin-top: 4px;
  height: 10px;
  width: 10px;
}

.dataSetsStyle {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 0px 25px 0px 14px;
  width: 186px;
  position: inherit;
  top: 0;
  height: 100%;
  margin-right: 21px;

  .navlink.active,
  .show > .navlink {
    background: transparent;
    color: #011cf5 !important;
    text-align: left;

    &::after {
      position: absolute;
      content: "";
      width: 2.2px;
      height: 22px;
      // background: #011cf5 !important;
      left: -15px;
      top: 5px;
    }
  }

  .navlink {
    padding: 5px 0px 0px 12px;
    text-align: left;
    color: #a3a3a3 !important;
    font-size: 10px;
    font-weight: 500;
    background-color: transparent !important;
    position: relative;
    background: none;
    border: 0;
    border-radius: 0.25rem;
    cursor: pointer;

    &:hover {
      //background-color: #f4f4f4 !important;
      color: #011cf5 !important;

    }
  }
}
