.overview {
  width: calc(100% - 50px);
  padding: 25px 55px 40px 50px;

  .heading {
    h2 {
      color: #0c2659;
      font-size: 24px;
      font-weight: bold;
    }
  }
}

.ristrictContainer {
  width: 100%;
  padding: 25px 50px 40px 50px;

  .heading {
    h2 {
      color: #0c2659;
      font-size: 24px;
      font-weight: bold;
    }
  }
}

.tabcontent {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 55.2%;
}

.ristrictInnerContainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 54.7%;
}

.innerContainer {
  .searchbtn {
    background: transparent linear-gradient(90deg, #011cf5 0%, #0c2659 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000040;
    border-radius: 5px;
    border: none;
    font-size: 14px;
    color: #fff;
    padding: 5px 25px;
    font-weight: 300;
    height: 32px;
  }

  .searchbtnposition {
    color: #2f2f2f;
    border: 1px solid #2f2f2f;
    border-radius: 5px;
    font-size: 12px;
    background: transparent;
    font-weight: 500;
    padding: 5px 15px;
    align-items: center;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      max-width: 18px;
      margin-right: 5px;
    }
  }

  .edithead {
    h1 {
      font-size: 26px;
      font-weight: 1000;
      color: #000e75;
    }
    h5 {
      font-size: 13px;
      font-weight: 800;
      color: #000e75;
    }
    h3 {
      letter-spacing: 0px;
      color: #2f2f2f;
      font-size: 14px;
      font-weight: 500;
    }
  }

  P {
    color: #a3a3a3;
    font-size: 12px;
  }

  .dragattach {
    background: #0023410a 0% 0% no-repeat padding-box;
    border: 1px dashed #011cf5;
    border-radius: 5px;
    text-align: center;
    padding: 80px 0;

    h5 {
      color: #2f2f2f;
      font-size: 12px;
      margin: 5px 0;
      font-weight: 500;

      span {
        color: #011cf5;
      }
    }

    p {
      color: #a3a3a3;
      font-size: 10px;
      margin: 0;
    }
  }

  .tablehead {
    th {
      letter-spacing: 0px;
      color: #2f2f2f;
      font-weight: 500;
      font-size: 12px;
    }

    td {
      border: none;
      color: #2f2f2f;
      font-weight: 400;
      font-size: 12px;

      img {
        width: 100%;
        max-width: 22px;
        text-align: left;
      }
    }

    thead {
      background: #f4f4f4 0% 0% no-repeat padding-box;
    }

    tbody {
      border: none !important;
    }

    tr {
      border-top: 1px solid #dedede;
      border-bottom: 1px solid #dedede;
    }
  }
}

.navlink.active {
  color: #011cf5 !important;
}
