.nameStyle {
  line-height: 16px;
  font-size: 14px;
  font-weight: bold;
  color: #2f2f2f;
  text-transform: capitalize;
}
.designationStyle {
  padding: 0;
  color: #2f2f2f;
  font-size: 12px;
  margin: 0;
  line-height: 16px;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
}
.emailStyle {
  display: block;
  font-size: 12px;
  font-weight: 400;
  color: #011cf5;
  line-height: 16px;
  text-transform: none !important;
  text-decoration: none !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.phNumberStyle {
  display: block;
  font-size: 12px;
  font-weight: 400;
  color: #011cf5;
  line-height: 16px;
  text-transform: none !important;
}
