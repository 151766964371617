@import "../../../styles/variables.scss";

.rowSetting {
  height: 70px;
}
.red {
  border-right: 1px solid white !important;
  border-top: 1px solid white !important;
  border-bottom: 1px solid white !important;
  border-left: 4px solid #bf1736 !important;
  h2 {
    color: #bf1736 !important;
  }
  &:hover {
    border-right: 1px solid #bf1736 !important;
    border-top: 1px solid #bf1736 !important;
    border-bottom: 1px solid #bf1736 !important;
    border-left: 4px solid #bf1736 !important;
  }
}

.usersimple {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  // padding: 14px 12px 14px 0px;
  padding: 0px 12px 0px 12px;

  border: 1px solid white;
  position: relative;
  cursor: pointer;

  h2 {
    color: $primaryColor;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    display: block;
    overflow: hidden;
    width: 228px;
  }

  p {
    letter-spacing: 0px;
    color: $secondaryColor;
    font-size: 12px;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    width: 14em;
  }

  h3 {
    color: $secondaryColor;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }

  // button {
  //   background: #ebf6e9 0% 0% no-repeat padding-box;
  //   border-radius: 5px;
  //   color: #2f4e29;
  //   width: 100%;
  //   font-size: 12px;
  //   padding: 3px 10px;
  //   font-weight: 500;
  // }
}

.diffclass {
  .username {
    // background-color: red;
    h2 {
      color: #bf1736;
    }
    svg {
      border-right: 3px solid #bf1736;
      border-radius: 5px 0px 0px 5px !important;
    }
  }
}

.usersimple:hover {
  box-shadow: 0px 0px 20px #0000001a;
  border: 1px solid $primaryColor;
}

button:focus {
  box-shadow: none !important;
}

.socialIcon {
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.blankImg {
  display: inline-flex;

  height: 16px;
  width: 16px;
}

.filterIcon {
  width: 21px;
  height: 21px;
}

.tooltip {
  cursor: pointer;

  display: flex;
  align-items: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  .tooltiptext {
    font-size: 12px;
    justify-content: space-around;
    display: inline-flex;
    visibility: hidden;
    min-width: 300px !important;
    background-color: rgb(73, 72, 72);
    color: #fff;
    text-align: center;
    padding: 10px !important;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 35px;
    left: 10px;
    .tooltipEmailImages {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
    .tooltipMobileImages {
      width: 18px;
      height: 18px;
      margin-right: 2px;
    }
  }

  .usertoolbtn {
    background-color: transparent;
    border: none;
    padding: 0;
    width: 31px;
    border-radius: 5px;

    height: 50px;
  }
  svg {
    fill: #a3a3a3;
    color: #fff;
    padding: 5px;
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.bgRed {
  background-color: #bf17360f;
  border-radius: 5px;
  &:hover {
    // background-color: #a59e9e0f;

    svg {
      fill: #bf1736;
    }
    .usersimple {
      box-shadow: 0px 0px 20px #0000001a;
      border: 1px solid $primaryColor;
    }
  }
}

.bgGray {
  background-color: #a59e9e0f;
  border-radius: 5px;
  &:hover {
    // background-color: #a59e9e0f;

    svg {
      fill: $primaryColor;
    }
    .usersimple {
      box-shadow: 0px 0px 20px #0000001a;
      border: 1px solid $primaryColor;
    }
  }
}
.userstatus {
  text-align: center;
  width: 11%;
}
.usersocial {
  display: flex;
}

.statusConvertedButton {
  background-color: #b1ffba !important;
  color: $secondaryColor !important;
}

.statusButton {
  white-space: nowrap;
  background-color: #dedede;
  border-radius: 5px;
  color: $secondaryColor;
  width: 100%;
  font-size: 12px;
  padding: 3px 5px;
  font-weight: 500;
}

.userName {
  width: 20%;
}

.client {
  width: 7%;
}

// .totalcandidates {
//   width: 9.5%;
// }

// .usercompanyold {
//   width: 12%;
// }

.userAction {
  width: 3%;
  svg {
    width: 25px;
    height: 25px;
    color: #2f2f2f;
  }
}
.tableDataActionsSize {
  position: relative !important;
  width: 10% !important;
  // justify-content: center !important;
  align-items: center !important;
  display: flex !important;
  height: 33px !important;
}

.menuItemStyle {
  padding: 8px 14px !important;
  font-size: 13px !important;
  font-family: unset !important;
}

.iconstyle {
  height: 14px;
  width: 14px;
  margin-right: 10px;
}

.iconHover {
  :hover {
    color: $primaryColor;
  }
}

@media screen and (min-width: 1150px) and (max-width: 1310px) {
  .userstatus {
    width: 13%;
  }
  .usercity {
    width: 13%;
    display: none;
  }
}
@media screen and (min-width: 1311px) {
  .usercity {
    width: 13%;
    display: block;
  }
}
