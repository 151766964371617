@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.talentdetails {
  @include middleContainer;
}
.ristrictContainer {
  @include ristricMiddleContainer;
  width: 57.1% !important;
}
.topheading {
  h2 {
    color: #1c1c1d;
    font-size: 14px;
    font-weight: bold;
    font-weight: 1000;
    margin: 0;
  }
}
.overviewHeading {
  padding: 20px;
  border-bottom: 1px solid rgba(222, 222, 222, 0.5215686275);
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
}
