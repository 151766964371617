// @import "../../../../../styles/variables.scss";

// .status {
//   text-align: center;
//   cursor: pointer;
// }

// .active {
//   color: #bf1736;
// }

// .activeModal {
//   z-index: 99;
// }
.leftContainer {
  // background-color: yellow;
  width: 65%;
}
.rightContainer {
  // background-color: blue;
  width: 32%;
}

@media screen and (min-width: 1401px) {
  .upperDiv {
    // background-color: green;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
@media screen and (min-width: 1150px) and (max-width: 1400px) {
  .upperDiv {
    // background-color: green;
    width: 100%;
  }
  .leftContainer {
    // background-color: yellow;
    width: 100%;
  }
  .rightContainer {
    margin-top: 12px;
    // background-color: blue;
    width: 100%;
  }
}
