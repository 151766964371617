.offlimitToggle {
  width: 100%;
  padding: 15px 12px 15px 12px;
  border-radius: 5px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #ddd;
  background-color: #fff;
}
.statustext {
  // text-align: center;
  // margin-top: 19px;
  margin-bottom: 6px;
  font-size: 15px;
  font-weight: bold;
  color: #0c2659;
}
.changeSelect {
  // border: 2px solid #2f2f2f;
  background-color: #f4f4f4;
  border-radius: 5px;
  color: #011cf5 !important;
  font-size: 10px;
  height: 23px;
  width: 100%;
  font-weight: 500;
  font-family: unset !important;
  font-size: 12px !important;
  font-weight: 500 !important;

  &::before {
    border-bottom: none !important;
    padding: 0px !important;
  }

  &::after {
    border-bottom: none !important;
  }
  svg {
    height: 17px;
    width: 17px;
    margin-top: 4px;
    margin-right: 6px;
    color: black;
  }
}
.selectItem {
  padding: 4px 15px !important;
  font-size: 12px !important;
  font-weight: normal !important;
  font-family: unset !important;
  // margin-top: 6px !important;
}

// @media screen and (min-width: 1150px) and (max-width: 1200px) {
//   .offlimitToggle {
//     width: 100% !important;
//     margin-bottom: 40% !important;
//   }
// }
// @media screen and (min-width: 1201px) and (max-width: 1370px) {
//   .offlimitToggle {
//     width: 100% !important;
//     margin-bottom: 36% !important;
//   }
// }
