@import "../../../styles/variables.scss";

.btn {
  background-color: #011cf5;
  border-radius: 5px;
  border: none;
  font-size: 12px;
  color: #fff;
  padding: 5px 10px;
  font-weight: 500;
  height: 32px;
  min-width: 65px;
  cursor: pointer;
  border: 1px solid transparent;

  &:disabled {
    background: gray;
  }
}

.hoverEffect {
  &:hover {
    background-color: #ffff;
    color: #011cf5;
    border: 1px solid #011cf5;
  }
}

.iconContainer {
  justify-content: center;
  display: flex;
  align-items: center;
}

// }
// .imageStyle {
//   margin-right: 6px;
// }
// .talentLabIcon {
//   margin-top: -3px;
//   margin-right: 6px;
// }

// .danger {
//   background-color: #bf1736;
// }
