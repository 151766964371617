.bgimg {
  padding: 15px 22px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
  background-color: #fff;
  .box {
    padding: 10px;
    border-radius: 5px;
    background-color: #f4f4f4;
    p {
      color: #000000;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 10px;
      line-height: 16px;
      margin-left: 7px;
    }
    h2 {
      color: #2f2f2f;
      font-weight: 600;
      font-size: 36px;
    }
  }
  .converted {
    // background-color: #E6E8D5ß;
    h2 {
      color: #011cf5;
    }
    p {
      color: #011cf5;
    }
  }
}
.boxStyle {
  padding-left: 6px !important;
  padding-right: 6px !important;
}
.userImage {
  margin-top: -5px;
  height: 42px;
  width: 42px;
  margin-right: -3px;
}
