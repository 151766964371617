@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.talentdetails {
  @include middleContainer;
}
.ristricContainer {
  @include ristricMiddleContainer;
}

//////// &&&&&///////
.containerInner {
  padding: 32px 30px 21px 28px;
}

.divider {
  margin: 22px 0px 22px 0px;
  color: #707070;
  opacity: 0.1;
}

.topheading {
  h2 {
    color: #1c1c1d;
    font-size: 15px;
    font-weight: bold;
    padding: 0px 50px 0px 52px;
    margin: 0;
    line-height: 1;
  }
}

.noDetails {
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500 !important;
  padding-left: 52px;
  color: #a3a3a3 !important;
  line-height: 1;
}

.container {
  margin-top: 15px;
  width: 100%;
  padding: 0px 0px 0px 20px;
}
.title {
  font-size: 12px;
  font-weight: 500;
  color: #2f2f2f;
  margin: 0px;
  padding: 0px 30px 0px 32px;
}

.cardNumber {
  font-size: 12px;
  font-weight: 500;
  color: #011cf5;
  padding: 0px 30px 0px 32px;
}

.noDataFound {
  margin-top: 21px;
  margin-bottom: 21px;
  text-align: center;
  font-size: 12px;
  font-weight: 500 !important;
  /* padding: 20px; */
  color: #a3a3a3 !important;
}
