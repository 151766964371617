@import "../../../../../styles/variables.scss";
.hideContainer {
  display: none;
}
.offlimitToggle {
  width: 100%;
  padding: 15px 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #ddd;
  background-color: #fff;
  z-index: 9999;
}

.calender {
  text-align: center;
  cursor: pointer;
}
.user {
  text-align: center;
  cursor: pointer;
}
.text {
  color: $secondaryColor;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0;
}

.active {
  color: #bf1736;
}

.activeModal {
  z-index: 99;
  position: absolute;
}
