.assignedtable {
  margin-top: 11px;
  thead {
    background-color: #f4f4f4;
  }
  td {
    color: #2f2f2f;
    font-size: 11px;
    font-weight: 400;
  }

  tbody {
    border-top: 0 !important;
  }
}
.thName {
  width: 20%;
  color: #2f2f2f;
  font-size: 12px;
  font-weight: 500;
}

.thStatus {
  width: 30%;
  color: #2f2f2f;
  font-size: 12px;
  font-weight: 500;
}

.thAssigned {
  width: 10%;
  color: #2f2f2f;
  font-size: 12px;
  font-weight: 500;
}
.tableFooter {
  cursor: pointer;
  margin-top: 8px;
}

.tableFooterTxt {
  font-size: 11px;
  font-weight: bold;
  color: #011cf5;
  padding: 8px 0px 8px 8px;
}

.designationStyles {
  font-size: 9px !important;
}
