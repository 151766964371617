@import "../../../styles/variables.scss";

.clientLogo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 15px 0 0;
  object-fit: contain;
  border: 1px solid #f4f4f4;
}
.detailscontent {
  .detailsheading {
  }

  h4 {
    color: $secondaryColor;
    font-size: 12px;
    font-weight: 700;
  }

  .boxx {
    border-radius: 5px;
    background-color: #f4f4f4;
    padding: 18px;
    margin-right: 12px;
    margin-bottom: 15px;
    margin-top: 10px;
    flex: 1;

    h5 {
      color: $secondaryColor;
      font-size: 12px;
      margin: 0;
      font-weight: 700;
    }

    p {
      color: #a3a3a3;
      font-weight: 500;
      font-size: 10px;
    }

    h1 {
      color: $secondaryColor;
      font-size: 34px;
      font-weight: 600;
      margin-bottom: 0;
      padding-top: 0px;
      white-space: nowrap;
    }
  }
}
.clientName {
  color: #0c2659;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}
.clientCategoty {
  color: $secondaryColor;
  font-size: 12px;
  margin: 0;
  display: flex;
  font-weight: 400;
}
.cilentCity {
  color: $secondaryColor;
  font-size: 12px;
  margin: 0;
  display: flex;
  font-weight: 500;
  /* margin-top: 8px; */
}
.cityHeading {
  color: $secondaryColor;
  font-weight: 600;
  margin-right: 2px;
  font-size: 12px;
}
.socialIcon {
  cursor: pointer;
}
.openMailLink {
  height: 27px;
}
