@import "../../../styles/variables.scss";

.formgroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.labelContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  width: 100%;
  color: #a3a3a3;
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 4px;
  margin-top: 10px;
}

.input {
  border: 1px solid #dddddd;
  border-radius: 5px;
  width: 100%;
  height: 35px;
  padding: 15px;
  color: #2f2f2f;
  font-size: 12px;
  font-weight: 500;
  position: relative;
}
.input:focus {
  border: 1px solid $primaryColor;
}
.input:focus-visible {
  border: 1px solid $primaryColor;
  outline: none !important;
}

.textarea {
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 10px;
  color: #2f2f2f;
  font-size: 12px;
  font-weight: 500;
  // resize: none !important;
}
.textarea:focus {
  border: 1px solid $primaryColor;
}
.textarea:focus-visible {
  border: 1px solid $primaryColor;
  outline: none !important;
}

.error {
  border: 1px solid #bf1736;
}

.errorText {
  color: #bf1736;
  font-size: 8px;
  font-weight: 500;
  white-space: nowrap;
}

.focusLabel {
  color: black;
}

.filterSelect {
  border: 1px solid #2f2f2f;
  border-radius: 5px;
  color: #2f2f2f;
  font-size: 10px;
  height: 32px;
  background-color: transparent;
  min-width: 100%;
  font-weight: 500;
  padding: 0px 10px 0 10px;
}

.joiningDate {
  border-radius: 5px;
  width: 100%;
  height: 39px;
  margin: 2px 0px 15px 0px;
  color: #2f2f2f;
  font-size: 12px;
  font-weight: normal;
  background-color: #f4f4f4;
  padding: 10px;
  border: 1px solid rgb(229, 229, 229);

  &:focus {
    outline: none;
  }
  &:hover {
    border: 1px solid #011fc5;
  }
  &:focus {
    border: 1px solid #011fc5;
  }
}
.joiningDate:focus-visible {
  outline: none !important;
}
.joiningDate:focus-within {
  outline: none !important;
}
.joiningDate:visited {
  outline: none !important;
}

.joiningDateError {
  border: 1px solid #bf1736;
  &:hover {
    border: 1px solid #bf1736;
  }
  &:focus {
    border: 1px solid #bf1736;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
