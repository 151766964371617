.dropdownMenunew {
  z-index: 1;
  position: absolute;
  display: flex;
  left: -59px;
  bottom: -332%;
  padding: 16px 0px;
  background-color: #fff;
  color: black;
  width: 270px;
  visibility: visible;
  box-shadow: 0px 3px 7px lightgrey;
  border-radius: 3px;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}
.menuLink {
  background-color: red;
  cursor: pointer;
  font-size: 12px !important;
  padding-left: 30px !important;
  font-weight: bold !important;
  width: 1;
  width: 100%;
}

.active {
  color: #011cf5 !important;
}
