.header {
  padding: 20px 25px;
  display: flex;
  align-items: center;
}

.userImg {
  height: 61px;
  width: 61px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.userName {
  color: #2f2f2f;
  line-height: 1;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.userDetails {
  text-transform: capitalize;
  width: 150px;
}

.jobRole {
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  color: #2f2f2f;
}

.menu {
  // padding: 10px 25px;
  display: flex;
  flex-direction: column;
}

.menuTitle {
  font-size: 14px;
  font-weight: bold;
  color: #2f2f2f;
  padding: 0px 25px 0 25px;
}

.menuItem {
  font-size: 12px;
  font-weight: 600;
  color: #2f2f2f;
  // margin-bottom: 8px;
  cursor: pointer;
  padding: 4px 25px;

  &:hover {
    background-color: #e7e9fe;
  }
}

.menuIcon {
  height: 12px;
  width: 12px;
  margin-right: 8px;
}

.userIntial {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 61px;
  width: 61px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #0c2659;
  color: white;
  text-transform: capitalize;
}

.anchorMenuItem {
  font-size: 12px;
  font-weight: 600;
  color: #2f2f2f;
  cursor: pointer;
  padding: 4px 25px;

  &:hover {
    background-color: #e7e9fe;
    color: #2f2f2f !important;
  }
}
