@import "../../../../styles/mixins.scss";

// .rightdetails {
//   @include rightContainer;
// }

.rightdetails {
  width: 26.4%;
}

.bottomContainer {
  margin-top: 18px;
  width: 100%;
}
.upbottomContainer {
  margin-top: 0px !important;
  width: 100%;
}

@media screen and (min-width: 1401px) {
  .upperDiv {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
@media screen and (min-width: 1150px) and (max-width: 1400px) {
  .upperDiv {
    width: 100%;
  }
  .leftContainer {
    width: 100%;
  }
  .rightContainer {
    margin-top: 12px;

    width: 100%;
  }
}
