.popoverHead {
  font-size: 12px;
  font-weight: normal;
  color: #2f2f2f;
}

.subHEad {
  font-size: 12px;
  font-weight: 700;
  color: #2f2f2f;
}

.joiningDateFor {
  border: 1px solid rgb(229, 229, 229);
  border-radius: 5px;
  width: 100%;
  height: 75px;
  margin: 0px 0px 15px 0px;
  color: #2f2f2f;
  font-size: 12px;
  font-weight: normal;
  background-color: #f4f4f4;
  padding: 10px;
  resize: none;

  &:focus {
    border: 1px solid #011fc5;
    outline: none;
  }

  &:hover {
    border: 1px solid #011fc5;
  }
}

.timeSelect {
  border-radius: 5px;
  margin-top: 10px;
  padding: 2px 5px 2px 8px;
  width: fit-content;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #e7e9fe;
  }
}

.topSelect {
  border-radius: 5px;
  margin-top: 10px;
  padding: 0px 5px 2px 8px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #e7e9fe;
  }
}

.bellIcon {
  width: 17px;
  height: 17px;

  position: absolute;
}
.calenderIcon {
  width: 19px;
  height: 17px;
  position: absolute;
}

.select {
  top: 2px;

  font-family: unset !important;
  font-size: 12px !important;
  font-weight: 500 !important;

  &::before {
    border-bottom: none !important;
  }

  &::after {
    border-bottom: none !important;
  }
}

.selectItem {
  padding: 2px 10px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  font-family: unset !important;
}

.durationSelect {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 2px 5px 2px 8px;
  border-radius: 5px;
  width: fit-content;
  &:hover {
    background-color: #e7e9fe;
  }
}
.durationIcon {
  display: flex;
}

.bellIconContainer {
  position: absolute;
}
.selectDown {
  font-family: unset !important;
  font-size: 12px !important;
  font-weight: 500 !important;

  &::before {
    border-bottom: none !important;
  }

  &::after {
    border-bottom: none !important;
  }
}
