*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

.tw-container {
  width: 100%;
}

@media (min-width: 640px) {
  .tw-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .tw-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .tw-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .tw-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .tw-container {
    max-width: 1536px;
  }
}

.tw-fixed {
  position: fixed;
}

.tw-absolute {
  position: absolute;
}

.tw-inset-0 {
  inset: 0px;
}

.tw-left-\[18\.7\%\] {
  left: 18.7%;
}

.tw-left-\[23\%\] {
  left: 23%;
}

.tw-right-\[21\%\] {
  right: 21%;
}

.tw-right-\[3\%\] {
  right: 3%;
}

.tw-right-\[31\.4\%\] {
  right: 31.4%;
}

.tw-right-\[5\%\] {
  right: 5%;
}

.tw-right-\[8\%\] {
  right: 8%;
}

.tw-top-\[10\%\] {
  top: 10%;
}

.tw-top-\[12\%\] {
  top: 12%;
}

.tw-top-\[14\%\] {
  top: 14%;
}

.tw-top-\[15\%\] {
  top: 15%;
}

.tw-top-\[20\.6\%\] {
  top: 20.6%;
}

.tw-top-\[28\%\] {
  top: 28%;
}

.tw-top-\[45\%\] {
  top: 45%;
}

.tw-top-\[47\%\] {
  top: 47%;
}

.tw-top-\[6\.3\%\] {
  top: 6.3%;
}

.tw-top-\[7\.2\%\] {
  top: 7.2%;
}

.tw-z-50 {
  z-index: 50;
}

.tw-z-\[1000\] {
  z-index: 1000;
}

.tw-m-0 {
  margin: 0px;
}

.tw-mb-0 {
  margin-bottom: 0px;
}

.tw-mb-1 {
  margin-bottom: 0.25rem;
}

.tw-mb-2 {
  margin-bottom: 0.5rem;
}

.tw-mb-8 {
  margin-bottom: 2rem;
}

.tw-mt-3 {
  margin-top: 0.75rem;
}

.tw-mt-4 {
  margin-top: 1rem;
}

.tw-inline {
  display: inline;
}

.tw-flex {
  display: flex;
}

.tw-inline-flex {
  display: inline-flex;
}

.tw-h-16 {
  height: 4rem;
}

.tw-h-32 {
  height: 8rem;
}

.tw-h-8 {
  height: 2rem;
}

.tw-h-full {
  height: 100%;
}

.tw-h-max {
  height: -webkit-max-content;
  height: max-content;
}

.tw-w-16 {
  width: 4rem;
}

.tw-w-32 {
  width: 8rem;
}

.tw-w-8 {
  width: 2rem;
}

.tw-w-\[400px\] {
  width: 400px;
}

.tw-w-full {
  width: 100%;
}

.tw-w-max {
  width: -webkit-max-content;
  width: max-content;
}

.tw-rotate-\[270deg\] {
  --tw-rotate: 270deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-cursor-pointer {
  cursor: pointer;
}

.tw-flex-col {
  flex-direction: column;
}

.tw-items-center {
  align-items: center;
}

.tw-justify-center {
  justify-content: center;
}

.tw-justify-between {
  justify-content: space-between;
}

.tw-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.tw-rounded-full {
  border-radius: 9999px;
}

.tw-rounded-md {
  border-radius: 0.375rem;
}

.tw-bg-\[\#011cf5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(1 28 245 / var(--tw-bg-opacity));
}

.tw-bg-black\/80 {
  background-color: rgb(0 0 0 / 0.8);
}

.tw-bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.tw-bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.tw-bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.tw-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.tw-p-10 {
  padding: 2.5rem;
}

.tw-p-2 {
  padding: 0.5rem;
}

.tw-p-4 {
  padding: 1rem;
}

.tw-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.tw-py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.tw-pb-12 {
  padding-bottom: 3rem;
}

.tw-text-center {
  text-align: center;
}

.tw-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.tw-text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.tw-text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.tw-text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.tw-font-bold {
  font-weight: 700;
}

.tw-uppercase {
  text-transform: uppercase;
}

.tw-tracking-tight {
  letter-spacing: -0.025em;
}

.tw-text-\[\#011cf5\] {
  --tw-text-opacity: 1;
  color: rgb(1 28 245 / var(--tw-text-opacity));
}

.tw-text-\[\#011cf5\]\/50 {
  color: rgb(1 28 245 / 0.5);
}

.tw-text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.tw-text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.tw-text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.tw-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.tw-shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.flux {
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

button {
  font-family: inherit;
  /* 1 */
  -webkit-font-feature-settings: inherit;
  font-feature-settings: inherit;
  /* 1 */
  font-variation-settings: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  font-weight: inherit;
  /* 1 */
  line-height: inherit;
  /* 1 */
  color: inherit;
  /* 1 */
  margin: 0;
  /* 2 */
  padding: 0;
  /* 3 */
  text-transform: none;
}

/* button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;

  background-color: transparent;

  background-image: none;

} */

button,
[role="button"] {
  cursor: pointer;
}

*,
::before,
::after {
  box-sizing: border-box;
  /* 1 */
  border-width: 0;
  /* 2 */
  border-style: solid;
  /* 2 */
  border-color: #e5e7eb;
  /* 2 */
}

.hover\:tw-bg-\[\#306bff\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(48 107 255 / var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.hover\:tw-bg-slate-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.hover\:tw-text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}
