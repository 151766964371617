.topheading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 27px 20px 26px 20px;
  border-bottom: 1px solid rgba(222, 222, 222, 0.5215686275);
}
.tableHeadinbg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 20px 19px 20px;
  border-bottom: 1px solid rgba(222, 222, 222, 0.5215686275);
}

.headingText {
  color: #1c1c1d;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}
