.iconContainer {
  background-color: #dedede;
  // width: 30px;
  // height: 30px;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    background-color: #dddddd !important;
  }
}

.editIcon {
  cursor: pointer;
  width: 12.5px;
  height: 12.5px;
}
