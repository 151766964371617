@import "../../../styles/variables.scss";
.btn {
  border-radius: 5px;
  border: none;
  font-size: 12px;
  color: #000;
  font-weight: 500;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;

  &:disabled {
    background: gray;
  }
  &:hover {
    color: #011cf5;
  }
}

.arrow {
  margin-right: 5px;
}
