@import "../../../../styles/variables.scss";

.overview {
  width: calc(100% - 50px);
  padding: 30px 55px 40px 50px;

  .heading {
    h2 {
      color: #0c2659;
      font-size: 24px;
      font-weight: bold;
    }
  }

  .bgprofileleft {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 20px 15px;
    width: 196px;
    position: sticky;
    top: 30px;
    height: 100%;

    .navlink.active,
    .show > .navlink {
      background: transparent;
      color: $primaryColor;
      text-align: left;
      position: relative;

      &::after {
        position: absolute;
        content: "";
        width: 2.5px;
        height: 24px;
        background: $primaryColor;
        left: -15px;
        top: 5px;
      }
    }

    .navlink {
      padding: 8px 10px;
      text-align: left;
      color: $secondaryColor;
      font-size: 12px;
      font-weight: 500;
      background-color: transparent !important;
    }
  }
}
.innerContainer {
  .searchbtn {
    background: transparent
      linear-gradient(90deg, $primaryColor 0%, #0c2659 100%) 0% 0% no-repeat
      padding-box;
    box-shadow: 0px 0px 10px #00000040;
    border-radius: 5px;
    border: none;
    font-size: 14px;
    color: #fff;
    padding: 5px 25px;
    font-weight: 300;
    height: 32px;

    &:disabled {
      background: gray;
    }
  }

  .searchbtnposition {
    color: $secondaryColor;
    border: 1px solid $secondaryColor;
    border-radius: 5px;
    font-size: 12px;
    background: transparent;
    font-weight: 500;
    padding: 5px 15px;
    align-items: center;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      max-width: 18px;
      margin-right: 5px;
    }
  }

  .edithead {
    h3 {
      letter-spacing: 0px;
      color: $secondaryColor;
      font-size: 14px;
      font-weight: 500;
      margin: 0;
    }
  }

  .formname {
    .formgroup {
      label {
        width: 100%;
        color: #a3a3a3;
        font-size: 10px;
        font-weight: 500;
        margin-bottom: 4px;
        margin-top: 10px;
      }

      input {
        border: 1px solid #dddddd;
        border-radius: 5px;
        width: 100%;
        height: 35px;
        padding: 15px;
        color: $secondaryColor;
        font-size: 12px;
        font-weight: 500;
      }

      :focus {
        outline: none;
      }

      .midname {
        label {
          width: 60% !important;
        }

        p {
          color: #bf1736;
          font-size: 8px;
          font-weight: 500;
          margin: 0;
        }
      }

      .workingcheck {
        input {
          width: 35px;
          height: 30px;
          border-radius: 10px;
        }

        h4 {
          color: $secondaryColor;
          font-size: 10px;
          margin: 0 0 0 10px;
        }
      }

      select {
        color: $secondaryColor;
        font-size: 12px;
        height: 35px;
      }
    }

    h3 {
      color: $secondaryColor;
      font-size: 12px;
      font-weight: 500;
      margin-top: 30px;
    }
  }

  P {
    color: #a3a3a3;
    font-size: 12px;
  }

  .tablehead {
    th {
      letter-spacing: 0px;
      color: $secondaryColor;
      font-weight: 500;
      font-size: 12px;
    }

    td {
      border: none;
      color: $secondaryColor;
      font-weight: 400;
      font-size: 12px;

      img {
        width: 100%;
        max-width: 22px;
        text-align: left;
      }
    }

    thead {
      background: #f4f4f4 0% 0% no-repeat padding-box;
    }

    tbody {
      border: none !important;
    }

    tr {
      border-top: 1px solid #dedede;
      border-bottom: 1px solid #dedede;
    }
  }
}
.tabcontent {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 55.2%;
}
.ristrictContainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 54.7%;
}

.navlink.active {
  color: $primaryColor;
}

.mobileRow {
  display: flex;
  width: 100%;
}

.mobileInput {
  width: 90%;
}

.mobilebuttons {
  width: 10%;
  display: flex;
  align-items: flex-end;
}

.disableLink {
  color: rgb(164, 164, 164) !important;
}

.dateContainer {
  display: flex;
  justify-content: space-between;
}

.dateSection {
  display: flex;
  width: 100%;
}

.datePicker {
  width: 45%;
  margin-right: 10px;
}

.smallDatePicker {
  width: 20%;
  margin-right: 10px;
}

.resetdata {
  color: $secondaryColor;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10px;
  margin-top: 8px;
  text-decoration: underline;
}
.totalcom {
  width: 50%;
}
.totalcom h4 {
  color: $secondaryColor;
  font-size: 10px;
  font-weight: 500;
  margin: 0;
}
.totalcom h2 {
  color: $primaryColor;
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.totalcom h2 img {
  width: 20px;
}
.totalavg {
  background-color: #e9eff6;
  padding: 10px;
}

.totaltable th {
  color: $secondaryColor;
  font-size: 10px;
  font-weight: 500;
}
.totaltable th p {
  font-size: 7px !important;
  color: $secondaryColor !important;
  margin: 0;
}

.totaltable td h2 {
  color: $primaryColor;
  font-size: 12px;
  font-weight: 500;
}
.overview .totaltable td p {
  font-size: 10px;
  color: $secondaryColor;
  font-weight: 500;
  margin: 0;
}
.totaltable td.score {
  color: $primaryColor;
  font-size: 12px;
  font-weight: 700;
}
td.redcolor {
  color: #bf1736;
  font-size: 8px;
  font-weight: 500;
  cursor: pointer;
}
.edittd p {
  border: 1px solid;
  padding: 4px;
}
.totaltable thead {
  background-color: #f4f4f4;
}

.viewprofile h4 {
  color: $primaryColor;
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}
.totaltable tbody {
  border: none !important;
}
.totaltable td {
  border-bottom: 1px solid #dedede;
}

.fullTabContent {
  width: 83% !important;
}

.compentancyRow {
  height: 80px;
  vertical-align: middle;
}

.fullwidth {
  width: 100%;
}

.active_button {
  color: $primaryColor !important;
  border: 1px solid $primaryColor !important;
}

.documentList {
  cursor: pointer;
  margin-bottom: 12px !important;
  margin-left: 8px !important;
}

.withOutHeading {
  .overview {
    width: calc(100% - 50px);
    padding: 10px 40px;
  }
}

.listLabel {
  color: $secondaryColor;
  font-weight: 500;
  margin-top: 20px;
  font-size: 12px;
}

.lightLabel {
  color: #a3a3a3;
  font-size: 10px;
}

.helperText {
  color: #a3a3a3 !important;
  font-size: 10px !important;
  margin-top: 10px !important;
}

.pdfIcon {
  height: 20px;
  margin-left: 10px;
}
.topheading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0 20px;
}
.middleCard {
  padding: 0px 20px 0 20px;
}
.bottomFotter {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 20px 20px 20px 20px;
}
.cardDivider {
  margin-top: 11px;
  color: #dedede;
  margin-bottom: 8px;
}

.socialProfileH3 {
  color: $secondaryColor;
  font-size: 12px !important;
  font-weight: 500 !important;
  margin-top: 20px !important;
}
.toggleContainer {
  margin-top: 20px;
}

.saveButton {
  background-color: $primaryColor;
  color: white;
}
