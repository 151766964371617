.boxx {
  border-radius: 5px;
  background-color: #f4f4f4;
  margin-right: 6px;
  margin-bottom: 15px;
  margin-top: 6px;
  flex: 0 0 17% !important;
  padding: 11px 20px 11px 20px;
  h5 {
    color: #2f2f2f;
    font-size: 12px;
    margin: 0;
    font-weight: 500;
    line-height: 1;
    height: 27px;
    display: flex;
    align-items: end;
    // padding-left: 10px;
  }
  h3 {
    font-size: 24px;
  }

  p {
    color: #a3a3a3;
    font-weight: 500;
  }

  h1 {
    color: #011cf5 !important;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 0;
    padding-top: 5px;
  }
}

.totalCandidates {
  font-weight: 700 !important;
  font-size: 12px !important;
}
.lineDivider {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #dedede !important;
}

.percentageStyle {
  font-size: 15px !important;
}
