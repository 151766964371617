.blockContainer {
  background-color: #f4f4f4;
  border-radius: 5px;
  padding: 20px;
  margin-top: 21px;
}
.heading {
  font-size: 10px;
  color: #a3a3a3;
  font-weight: 400;
  line-height: 1px;
}
