@tailwind base;
@tailwind components;
@tailwind utilities;

.flux {
  @apply tw-transition-all tw-duration-300 tw-ease-in-out;
}

button {
  font-family: inherit;
  /* 1 */
  -webkit-font-feature-settings: inherit;
  font-feature-settings: inherit;
  /* 1 */
  font-variation-settings: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  font-weight: inherit;
  /* 1 */
  line-height: inherit;
  /* 1 */
  color: inherit;
  /* 1 */
  margin: 0;
  /* 2 */
  padding: 0;
  /* 3 */
}

button {
  text-transform: none;
}

/* button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;

  background-color: transparent;

  background-image: none;

} */

button,
[role="button"] {
  cursor: pointer;
}

*,
::before,
::after {
  box-sizing: border-box;
  /* 1 */
  border-width: 0;
  /* 2 */
  border-style: solid;
  /* 2 */
  border-color: #e5e7eb;
  /* 2 */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* width */
.thin-scrollbar::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.thin-scrollbar::-webkit-scrollbar-track {
  background: #f3f3f3;
  border-radius: 10px;
}

/* Handle */
.thin-scrollbar::-webkit-scrollbar-thumb {
  background: #011cf5;
  border-radius: 10px;
}

/* Handle on hover */
.thin-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
