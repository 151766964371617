.redAlert {
  width: 100%;
  border-radius: 5px;
  background-color: rgba(191, 23, 54, 0.0588235294);
  border-right: 1px solid white !important;
  border-top: 1px solid white !important;
  border-bottom: 1px solid white !important;
  border-left: 8px solid #bf1736 !important;
  padding: 10px 14px;
}
.alertHeading {
  color: #bf1736 !important;
  font-size: 12px;
  font-weight: bold;
}
.alertDescription {
  margin-top: 5px;
  padding-left: 22px;
  font-size: 12px;
  padding-right: 10px;
  color: #bf1736 !important;
}
