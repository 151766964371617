.topheading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0 20px;
}
.h3heading {
  letter-spacing: 0px;
  color: #2f2f2f;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.cardDivider {
  margin-top: 11px;
  color: #dedede;
  margin-bottom: 8px;
}
