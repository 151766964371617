.detailTable {
  font-size: 10px;
  font-weight: 500;
  color: #a3a3a3;

  // padding: 0px 30px 0px 30px;
}
.thFieldName {
  width: 33%;
  color: #2f2f2f;
  font-size: 10px;
  font-weight: 500;
}
.thUploadBy {
  width: 18%;
  color: #2f2f2f;
  font-size: 10px;
  font-weight: 500;
}
.thUploadOn {
  width: 18%;
  color: #2f2f2f;
  font-size: 10px;
  font-weight: 500;
}
.documentListContainer {
  padding: 0px 0px 84px 0px;
  margin-top: 44px;
  // cursor: pointer;
}
.tableFileName {
  cursor: pointer;
}
.documentName {
  display: flex;
  justify-content: start;
  align-items: center;
}
.pdfIcon {
  width: 100%;
  max-width: 22px;
  text-align: left;
}

.tData {
  color: #2f2f2f;
  font-size: 10px;
  font-weight: normal;
}
.noAssignmentTableRow {
  display: flex;
  margin-left: 26px;
  font-size: 10px;
  font-weight: bold;
  color: #a3a3a3 !important ;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: end;
}
.thAction {
  width: 10%;
}
.tableLinkConatiner {
  justify-content: space-between;
  display: flex;

  img {
    margin-left: 3px;
    height: 13px;
  }
}

.tableContainer {
  overflow-x: scroll;
  max-height: 267px;
}
