@import "styles/variables.scss";

.container {
  width: 34% !important;
}

.confirmationInput {
  border-radius: 5px;
  width: 100%;
  height: 46px;
  margin: 8px 0px 15px 0px;
  color: #2f2f2f;
  font-size: 12px;
  font-weight: normal;
  background-color: #f4f4f4;
  padding: 10px;
  border: none;
  font-family: $fontFamily, sans-serif !important;

  &:focus {
    border: none !important;
    outline: none;
  }
}

.selectContainer {
  height: 30vh;
  overflow: scroll;
}

.selectValue {
  line-height: 13px;
}
.titleCss {
  margin-top: 10px;
}
