.tabcontent {
  min-height: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 100%;
  // padding: 20px;
  // padding-bottom: 15%;
}
.topheading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 20px 20px 0 20px;
}
.h3heading {
  letter-spacing: 0px;
  color: #2f2f2f;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.topCardDivider {
  margin-top: 11px;
  color: #dedede;
  margin-bottom: 8px;
}
.middleCard {
  padding: 20px 20px 20px 20px;
  // display: flex;
  justify-content: end;
}
.bottomDivider {
  margin-top: 158px;
  color: #dedede;
  margin-bottom: 19px;
}
.comPageStyle {
  margin-top: 115px !important;
}
