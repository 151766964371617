.noDataFoundContainer {
  text-align: center;
}
.newdetailscontent {
  width: 100%;
  padding: 20px 20px 0px 20px;
  .detailsheading {
    a {
      color: blue;
    }
    h2 {
      color: #0c2659;
      font-size: 15px;
      font-weight: bold;
      font-weight: 1000;
      margin: 0;
    }

    h3 {
      color: #464444;
      font-size: 13px;
      margin: 0;
      font-weight: 800;
    }

    h4 {
      color: #969292;
      font-size: 11px;
      margin: 0;
      font-weight: 900;
    }
    h5 {
      color: #5a5858;
      font-size: 11px;
      margin: 0;
      font-weight: 600;
    }
  }
}

.flexContainer {
  float: right;
}

.flexInnerContainer {
  display: flex;
  align-items: center;
}
.certification_details_icons {
  display: flex;
  flex-direction: column;
  align-items: center;

  h5 {
    color: #969292;
    font-size: 9px;
    margin: 0;
    font-weight: 900;
  }
}
.capitalText {
  text-transform: capitalize;
}
.dataNotFound {
  text-align: center;
  font-size: 12px;
  font-weight: 500 !important;
  padding: 20px;
  color: #a3a3a3;
}
.socialIcon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.roundIconContainer {
  display: flex;
}

.dashedLine {
  border-top: 1px dashed #707070;
  letter-spacing: 4px;
  background-color: white;
  margin-top: 20px;
  margin-bottom: 0px;
}
