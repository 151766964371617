@import "../../../../../../styles/mixins.scss";
@import "../../../../../../styles/variables.scss";

.topSelect {
  border: none;
  border-radius: 5px;
  color: #2f2f2f;
  font-size: 10px;
  height: 23px;
  background-color: #f4f4f4;
  // width: 132px;
  width: 100%;
  margin-bottom: 4px;

  font-weight: 500;
  font-family: unset !important;
  font-size: 12px !important;
  font-weight: 500 !important;

  &::before {
    border-bottom: none !important;
    padding: 0px !important;
  }

  &::after {
    border-bottom: none !important;
  }
}
.greenSelect {
  background-color: $green;
}

.trasnStyles {
  padding: 5px 10px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  font-family: unset !important;
  background-color: transparent !important;
}
.selectItem {
  padding: 5px 10px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  font-family: unset !important;
}
