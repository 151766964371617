.noDataFoundContainer {
  text-align: center;
}

.newdetailscontent {
  width: 100%;
  padding: 20px 20px 0px 20px;
  .detailsheading {
    a {
      color: blue;
    }
    h2 {
      color: #0c2659;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    h3 {
      color: #464444;
      font-size: 13px;
      margin: 0;
      font-weight: 800;
      margin-bottom: 3px;
    }

    h4 {
      color: #969292;
      font-size: 11px;
      margin-bottom: 3px;
      font-weight: 500;
    }
    h5 {
      color: #5a5858;
      font-size: 11px;
      margin: 0;
      font-weight: 600;
    }
  }
}

.flexContainer {
  float: right;
}
.capitalText {
  text-transform: capitalize;
}

.dataNotFound {
  text-align: center;
  font-size: 12px;
  font-weight: 500 !important;
  padding: 20px;
  color: #a3a3a3;
}

.newdetailborder {
  border-bottom: 1px dashed #dedede;
  padding-bottom: 17px;
}

.roundIconContainer {
  display: flex;
}
