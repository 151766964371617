.tableContainer {
  padding: 0px 119px 84px 57px;
}
.summaryHead {
  color: #0c2659;
  font-size: 14px;
  font-weight: bold;
}
.assignmentTable {
  margin-top: 11px;
}
.thPosition {
  width: 40%;
  color: #2f2f2f;
  font-size: 10px;
  font-weight: 500;
}

.thAgeing {
  width: 30%;
  color: #2f2f2f;
  font-size: 10px;
  font-weight: 500;
}
.tData {
  color: #2f2f2f;
  font-size: 12px;
  font-weight: 500;
  vertical-align: middle;
}
.imageIcon {
  margin-left: 5px;
  /* margin-top: 4px; */
  margin-bottom: 4px;
  height: 12px;
  width: 12px;
  cursor: pointer;
}
