.summaryHead {
  color: #0c2659;
  font-size: 14px;
  font-weight: bold;
}
.assignmenttable {
  margin-top: 11px;
}
.thPosition {
  width: 40%;
  color: #2f2f2f;
  font-size: 10px;
  font-weight: 500;
}
.thAgeing {
  //   width: 30%;
  color: #2f2f2f;
  font-size: 10px;
  font-weight: 500;
}
.tData {
  color: #2f2f2f;
  font-size: 11px;
  font-weight: 500;
  text-transform: capitalize;
}
.imageIcon {
  margin-left: 5px;
  /* margin-top: 4px; */
  margin-bottom: 3px;
  height: 12px;
  width: 12px;
  cursor: pointer;
}

.contentStyle {
  font-size: 11px !important;
}
.smallBtn {
  height: 25px !important;
  font-size: 11px !important;
  padding: 0px;
}
