.tableHeading {
  width: 100%;
  display: flex;
  padding: 20px 20px 0px 20px;
}
.tableHeadingFont {
  font-size: 12px;
  font-weight: 500;
}
.tableHeadingDataName {
  cursor: pointer;
  display: flex;
  width: 25%;
}
.arrowStyle {
  height: 16px !important;
}

.tableHeadingSpace {
  width: 5%;
}
.tableHeadingDataSlug {
  width: 20%;
}
.tableHeadingDataCreated {
  width: 30%;
}
.tableHeadingDataStatus {
  width: 20%;
}
.tableHeadingDataActions {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 10%;
}
.tableDataOuterContainer {
  padding-left: 20px;
  padding-right: 20px;
}
.tableDataContainer {
  display: flex;
  // padding:0px 20px;
}
.tableDataNameSize {
  width: 25%;
}
.tableDataSpace {
  width: 5%;
}

.tableDataCreatedSize {
  width: 30%;
}
.tableDataStatusSize {
  display: flex;
  align-items: center;
  text-align: center;
  width: 20%;
}
.tableDataActionsSize {
  position: relative;
  width: 10% !important;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 33px;
  width: 33px;
}
.greenDot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #b1ffba;
  // margin: 5px 0px 0px 10px;
  margin-right: 10px;
}
.blackDot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: black;
  // margin: 5px 0px 0px 10px;
  margin-right: 10px;
}
.actionDots {
  transform: rotate(270deg);
  height: 23px;
  cursor: pointer;
}
.menuItemStyle {
  padding: 14px 14px !important;
  font-size: 13px !important;
}
.iconstyle {
  height: 17px;
  width: 17px;
  margin-right: 10px;
}

.cardDivider {
  margin-top: 11px;
  color: #dedede;
  margin-bottom: 8px;
}
.tableDivider {
  margin-top: 11px;
  color: #dedede;
  margin-bottom: 11px;
}
.TableDataFontStyle {
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 5px 0px 5px 0px;
}
.tableDataDesc {
  width: 23%;
}
.tableHeadingDesc {
  width: 23%;
}

.disbaleText {
  color: gray !important;
}
