@import "styles/variables.scss";

.MainContainer {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  // background-color: red;
  background-image: url("../../../public/images/login/bg.jpg");
  color: #f4f4f4 !important;
  font-family: $fontFamily, sans-serif !important;
}
.topContainer {
  display: flex;
  justify-content: end;
  flex-direction: column;
  height: 30%;
  // background-color: blue;
  text-align: center;
}
.middleContainer {
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 50%;
  // background-color: green;
}
.bottomContainer {
  text-align: center;
  height: 20%;
  // background-color: yellow;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logoContainer {
  padding-left: 50px;
  padding-right: 50px;
}

.textData {
  padding-top: 18px;
  font-weight: normal;
}
.mobileViewBottomContent {
  width: 100%;
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  line-height: 0px;
  justify-content: space-between;
}

.boldText {
  font-weight: 500;
}

.headingStyles {
  font-weight: 500;
}
