@import "../../../styles/variables.scss";

.red {
  border-right: 1px solid white !important;
  border-top: 1px solid white !important;
  border-bottom: 1px solid white !important;
  border-left: 4px solid #bf1736 !important;
  h2 {
    color: #bf1736 !important;
  }
  &:hover {
    border-right: 1px solid #bf1736 !important;
    border-top: 1px solid #bf1736 !important;
    border-bottom: 1px solid #bf1736 !important;
    border-left: 4px solid #bf1736 !important;
  }
}

.usersimple {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 14px 12px 14px 0px;
  border: 1px solid white;
  position: relative;
  cursor: pointer;

  h2 {
    color: $primaryColor;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    width: 14em;
  }

  p {
    letter-spacing: 0px;
    color: $secondaryColor;
    font-size: 12px;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    width: 14em;
  }

  h3 {
    color: $secondaryColor;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }

  button {
    background: #ebf6e9 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #2f4e29;
    width: 100%;
    font-size: 12px;
    padding: 3px 10px;
    font-weight: 500;
  }
}

.diffclass {
  .username {
    h2 {
      color: #bf1736;
    }
    svg {
      border-right: 3px solid #bf1736;
      border-radius: 5px 0px 0px 5px !important;
    }
  }
}

.usersimple:hover {
  box-shadow: 0px 0px 20px #0000001a;
  border: 1px solid $primaryColor;
}

button:focus {
  box-shadow: none !important;
}

.socialIcon {
  // height: 16px;
  width: 16px;
  cursor: pointer;
}

.blankImg {
  display: inline-flex;
  height: 16px;
  width: 16px;
}

.filterIcon {
  width: 21px;
  height: 21px;
}
.listProfileImage {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  object-fit: cover;
}

.tooltip {
  cursor: pointer;

  display: flex;
  align-items: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  .tooltiptext {
    font-size: 12px;
    justify-content: space-around;
    display: inline-flex;
    visibility: hidden;

    max-width: 300px !important;
    background-color: rgb(73, 72, 72);
    color: #fff;
    text-align: center;
    padding: 10px !important;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 35px;
    left: 10px;
    .tooltipEmailImages {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
    .tooltipMobileImages {
      width: 18px;
      height: 18px;
      margin-right: 2px;
      margin-left: 7px;
    }
  }

  .usertoolbtn {
    background-color: transparent;
    border: none;
    padding: 0;
    width: 31px;
    border-radius: 5px;

    height: 50px;
  }
  svg {
    fill: #a3a3a3;
    color: #fff;
    padding: 5px;
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.bgRed {
  background-color: #bf17360f;
  border-radius: 5px;
  &:hover {
    // background-color: #a59e9e0f;

    svg {
      fill: #bf1736;
    }
    .usersimple {
      box-shadow: 0px 0px 20px #0000001a;
      border: 1px solid $primaryColor;
    }
  }
}

.bgGray {
  background-color: #a59e9e0f;
  border-radius: 5px;
  &:hover {
    // background-color: #a59e9e0f;

    svg {
      fill: $primaryColor;
    }
    .usersimple {
      box-shadow: 0px 0px 20px #0000001a;
      border: 1px solid $primaryColor;
    }
  }
}
.userstatus {
  text-align: center;
  width: 10%;
}
.usersocial {
  display: flex;
  & .twitterIcon {
    width: 12px;
  }
}

.statusConvertedButton {
  background-color: #b1ffba !important;
  color: $secondaryColor !important;
}

.statusButton {
  white-space: nowrap;
  background-color: #dedede;
  border-radius: 5px;
  color: $secondaryColor;
  width: 100%;
  font-size: 12px;
  padding: 3px 10px;
  font-weight: 500;
}

.userstatus {
  width: 95px;
}

@media screen and (min-width: 1150px) and (max-width: 1400px) {
  .usercity {
    display: none;
  }
  .userName {
    width: 20%;
  }
  .userexp {
    width: 10%;
  }
}
.phoneStyles {
  margin-left: 2px !important;
}
@media screen and (min-width: 1401px) {
  .usercity {
    width: 13%;
    display: block;
  }
  .userName {
    width: 17%;
  }

  .userexp {
    width: 8%;
  }

  .usercompany {
    width: 13%;
  }

  .usercompanyold {
    width: 14.2%;
  }
}
.socialIconStyles {
  margin-bottom: 2px;
  margin-right: 3px;
}
