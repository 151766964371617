.socialIconStyles {
  background-color: #f4f4f4;
  border-radius: 9999px;
  margin-right: 6px;
  border: 1px solid #dfe3e7;
  cursor: pointer;
  width: 32px;
  height: 32px;
  line-height: 1;
  //padding: 1px 5.2px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 11px;
    height: 11px;
  }
  &:hover {
    background: #dfe3e7;
  }
}

.send-email {
  img {
    width: 16px;
    height: 16px;
  }
}
