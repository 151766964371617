@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.talentdetails {
  @include middleContainer;
}

.ristricContainer {
  @include ristricMiddleContainer;
}
.innerContainer {
  padding: 32px 30px 20px 28px;
}

.middleCard {
  width: 100%;
  padding: 12px 110px 0px 54px;
  h3 {
    color: #0c2659;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 10px;
  }

  .horizontalLine {
    border-top: 1px dashed #707070;
    letter-spacing: 4px;
    background-color: white;
    margin: 0px;
  }
  h4 {
    color: $secondaryColor;
    font-size: 12px;
    font-weight: 700;
    padding: 0;
    margin-bottom: 15px;
  }

  p {
    color: $secondaryColor;
    font-size: 12px;
    margin: 0;
    padding: 0;
    line-height: 1.625;
    font-weight: 400;
  }
}
