.container {
  background-color: #f9f9f9;
  padding: 20px 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.unread {
  background-color: #e9eff6;
}

.content {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  height: 18px;
}

.time {
  font-size: 10px;
  color: #a3a3a3;
}

.text {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.17;
  color: #2f2f2f;
}

.adminThumImage {
  border-radius: 50%;
  width: 34px;
  height: 32px;
  object-fit: cover;
}
.adminThumImageCalender {
  border-radius: 50%;
  width: 25px;
  height: 20px;
}
.iconBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 34px;
  height: 32px;
  background-color: #dedede;
  font-size: bold;
  font-family: unset;
}
.iconNameBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 34px;
  height: 32px;
  background-color: #0c2659;
  font-weight: bold;
  font-family: unset;
  color: white;
  font-size: 14px;
}
.rowDivider {
  margin: 0 25px;
}
.divider {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  color: #dedede !important;
}
.mentionText {
  font-weight: bold;
}
